<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{template.name}}</h3>

      <div class="row">
        <label class="col-12 col-md-2 col-form-label">Language:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{template.language}}</span></div>
      </div>
      <div class="row">
        <div class="col-3 form-goup form-check form-check-aligned">
          <input class="form-check-input" type="checkbox" disabled [(ngModel)]="template.root">
          <label class="form-check-label">Root</label>
        </div>
        <div class="col-3 form-goup form-check form-check-aligned">
          <input class="form-check-input" type="checkbox" disabled [(ngModel)]="template.properties.newPage">
          <label class="form-check-label">New Page</label>
        </div>
        <div class="col-3 form-goup form-check form-check-aligned">
          <input class="form-check-input" type="checkbox" disabled [(ngModel)]="template.properties.autoFreeze">
          <label class="form-check-label">AutoFreeze</label>
        </div>
      </div>
      <div class="row" *ngIf="template.label">
        <label class="col-12 col-md-2 col-form-label">Label:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{template.label}}</span></div>
      </div>
      <div class="row" *ngIf="template.description">
        <label class="col-12 col-md-2 col-form-label">Description:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{template.description}}</span></div>
      </div>
      <div class="row" *ngIf="template.documentKey">
        <label class="col-12 col-md-2 col-form-label">Document:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">
          <a class="btn btn-link" [routerLink]="['/docxs', template.documentKey ]">{{template.documentKey}}</a>
        </span></div>
      </div>
      <div class="row" *ngIf="template.inciName">
        <label class="col-12 col-md-2 col-form-label">INCI Specific:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">
          <a *ngFor="let i of data.incis.related(template)" [routerLink]="['/incis', i.key]" class="nav-link">{{i.name}}</a>
          <span *ngIf="data.incis.related(template).length == 0"
              ngbTooltip="This INCI was not added yet." tooltipClass="dt-tooltip" container="body">{{template.inciName}}</span>
        </span></div>
      </div>
      <div class="row" *ngIf="template.customerKey">
        <label class="col-12 col-md-2 col-form-label">Customer Specific:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">
          <a *ngIf="template.customer" [routerLink]="['/customers', template.customer.key]" class="nav-link">{{template.customer.name}}</a>
          <span *ngIf="template.customerKey && !template.customer">Not found: {{template.customerKey}}</span>
        </span></div>
      </div>
      <div class="row" *ngIf="template.ingredientKey">
        <label class="col-12 col-md-2 col-form-label">Raw-Material Specific:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">
          <a *ngIf="template.ingredient" [routerLink]="['/ingredients', template.ingredient.key]" class="nav-link">{{template.ingredient.getLabel()}}</a>
          <span *ngIf="template.ingredientKey && !template.ingredient">Not found: {{template.ingredientKey}}</span>
        </span></div>
      </div>
      <div class="row" *ngIf="template.root">
        <label class="col-12 col-md-2 col-form-label">Properties</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{propsAsString()}}</span></div>
      </div>
      <div class="row">
        <label class="col-md-12 col-form-label">Content:</label>
        <div class="col-md-12">
          <ace id="dataText" name="dataText" #editor
            [config]="editorOptions"
            [mode]="'html'"
            [theme]="'textmate'"
            [value]="template.dataText"
            class="dt-form-control"></ace>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-12 col-form-label">Preview:</label>
        <div class="col-md-12"><div class="dt-form-control">
          <ckeditor name="ckeditor" [editor]="previewFactory" #preview
            [config]="previewConfig"
            [(ngModel)]="template.dataText"
            [(data)]="template.dataText"
            [disabled]="true"></ckeditor>
        </div></div>
      </div>
      <!-- Related Items: -->
      <div class="row">
        <div class="col-12 col-md-4 form-group" *ngIf="data.templates.related(template, 'includedBy').length">
          <label>Included By:</label>
          <div>
            <a *ngFor="let t of data.templates.related(template, 'includedBy')"
                class="me-2 nav-link" [routerLink]="['/templates', t.key]">{{t.name}}</a>
          </div>
        </div>
        <div class="col-12 col-md-4 form-group" *ngIf="data.templates.related(template).length">
          <label>Similar Templates:</label>
          <div>
            <a *ngFor="let t of data.templates.related(template)"
                class="me-2 nav-link" [routerLink]="['/templates', t.key]">{{t.name}}<sub>{{t.relationIndicator(template.language)}}</sub></a>
          </div>
        </div>
        <div class="col-12 col-md-4 form-group" *ngIf="data.templates.related(template, 'includes').length">
          <label>Includes (no variants):</label>
          <div>
            <span *ngFor="let t of data.templates.related(template, 'includes')">
              <a class="me-2 nav-link" [routerLink]="['/templates', t.key]">{{t.name}},</a>&#8203;
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-1">
      <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
        <a [routerLink]="['/templates', template.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
      </div>
      <div class="form-group" ngbTooltip="Duplicate" tooltipClass="dt-tooltip">
        <button (click)="duplicate()" class="form-control btn"><span class="float-end oi oi-layers"></span></button>
      </div>
      <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
        <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
      </div>
      <div class="form-group" ngbTooltip="History" tooltipClass="dt-tooltip">
        <a [routerLink]="['/templates', template.key, 'history']" class="form-control btn"><span class="float-end oi oi-trash"></span></a>
      </div>
    </div>
  </div>
</div>
