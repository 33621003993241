import { HttpClient } from '@angular/common/http';
import { DataList } from '../data.service';
import { BaseEntity } from '../model/base_entity';

export class EntityStore<T extends BaseEntity> {

    constructor(
        protected http: HttpClient,
        protected data: DataList<T>,
        protected uriPrefix: string,
        private entityType:  new (e?: T) => T,
        private keepCachedData: boolean = true) {}

    _postRead?: (e: T) => T;
    _loadDependencies?: () => Promise<void>;
    _prepareTypeahedNames?: () => void;

    _preSave(e: T): T {
        e.createdBy = localStorage.getItem('currentUser');
        return e;
    }

    loadAll(searchUri?: string): Promise<void> {
        const uri = searchUri ? searchUri : this.uriPrefix;
        if (!this.data.loading[uri]) {
            const p = this.http.get<T[]>(uri)
                .toPromise()
                .then(entities => {
                    return entities.map(e => new this.entityType(e));
                })
                .then(entities => {
                    if (this._loadDependencies) {
                        return this._loadDependencies()
                            .then(() => {
                                return entities;
                            });
                    }
                    return entities;
                })
                .then(entities => {
                    if (this._postRead) {
                        entities.forEach(i => {
                            this._postRead(i);
                            i.dirty = false;
                        });
                    }
                    this.data.list = entities;
                })
                .then(() => {
                    if (this._prepareTypeahedNames) {
                        this._prepareTypeahedNames();
                    }
                })
                .finally(() => {
                    delete this.data.loading[uri];
                });
            this.data.loading[uri] = p;
        }
        return this.data.loading[uri] as Promise<void>;
    }

    _loadItem(uri: string): Promise<T> {
        if (!this.data.loading[uri]) {
            const p = this.http.get<T>(uri)
                .toPromise()
                .then(e => new this.entityType(e))
                .then(e => this._postRead ? this._postRead(e) : e)
                .finally(() => {
                    delete this.data.loading[uri];
                });
            this.data.loading[uri] = p;
        }
        return this.data.loading[uri] as Promise<T>;
    }

    _loadItems(uri: string): Promise<Array<T>> {
        if (!this.data.loading[uri]) {
            const p = this.http.get<Array<T>>(uri)
                .toPromise()
                .then(l => l.map(e => new this.entityType(e)))
                .then(l => l.map(e => this._postRead ? this._postRead(e) : e))
                .finally(() => {
                    delete this.data.loading[uri];
                });
                this.data.loading[uri] = p;
        }
        return this.data.loading[uri] as Promise<Array<T>>;
    }

    loadById(id: number): Promise<T> {
        return this._loadItem(this.uriPrefix + '/id/' + id);
    }

    loadByKey(key: number): Promise<T> {
        return this._loadItem(this.uriPrefix + '/key/' + key);
    }

    loadHistory(key: number): Promise<Array<T>> {
        return this._loadItems(this.uriPrefix + '/history/' + key);
    }

    assertLoaded(searchUri?: string): Promise<void> {
        const uri = searchUri ? searchUri : this.uriPrefix;
        const p = this.data.loading[uri] as Promise<void>;
        if (p) {
            return p;
        }
        if (!this.data.list) {
            return this.loadAll(uri);
        }
        return Promise.resolve();
    }

    // ---------------------

    getById(id: number): Promise<T> {
        return this.assertLoaded()
            .then(() => {
                return this.data.list.find(e => e.id === id);
            })
            .then(item => {
                if (!item) {
                    return this.loadById(id);
                }
                return item;
            });
    }

    getByKey(key: number): Promise<T> {
        return this.assertLoaded()
            .then(() => {
                return this.data.list.find(e => e.key === key);
            })
            .then(item => {
                if (!item) {
                    return this.loadByKey(key);
                }
                return item;
            });
    }

    getHistory(key: number): Promise<Array<T>> {
        return this.assertLoaded()
            // Not yet cached locally, maybe sometime later
            .then(() => {
                return this.loadHistory(key);
            });
    }

    reset() {
        this.data.list = null;
    }

    // ---------------------

    create(entity: T): Promise<T> {
        return this.http.post<T>(this.uriPrefix + '/new', this._preSave ? this._preSave(entity) : entity)
            .toPromise()
            .then(respEntity => {
                return new this.entityType(respEntity);
            })
            .then(respEntity => {
                if (this._postRead) {
                    this._postRead(respEntity);
                }
                Object.assign(entity, respEntity);
                return respEntity;
            })
            .then(respEntity => {
                if (this.keepCachedData) {
                    this.assertLoaded()
                        .then(() => {
                            this.data.list.push(respEntity);
                        })
                        .then(() => {
                            if (this._prepareTypeahedNames) {
                                this._prepareTypeahedNames();
                            }
                        });
                }
                return respEntity;
            });
    }

    update(entity: T): Promise<T> {
        const uri = this.uriPrefix + (entity.immutable ? '/key/' + entity.key : '/id/' + entity.id);
        return this.http.put<T>(uri, this._preSave ? this._preSave(entity) : entity)
            .toPromise()
            .then(respEntity => {
                return new this.entityType(respEntity);
            })
            .then(respEntity => {
                if (this._postRead) {
                    this._postRead(respEntity);
                }
                Object.assign(entity, respEntity);
                if (this.keepCachedData) {
                    this.assertLoaded()
                        .then(() => {
                            if (this._prepareTypeahedNames) {
                                this._prepareTypeahedNames();
                            }
                        });
                }
                return entity;
            });
    }

    delete(entity: T): Promise<void> {
        const uri = this.uriPrefix + (entity.immutable ? '/key/' + entity.key : '/id/' + entity.id);
        return this.http.delete<void>(uri)
            .toPromise()
            .then(() => this.assertLoaded())
            .then(() => {
                if (this.keepCachedData) {
                    this.data.list = this.data.list.filter(e => e !== entity && (!entity.key || e.key !== entity.key));
                }
            })
            .then(() => {
                if (this._prepareTypeahedNames) {
                    this._prepareTypeahedNames();
                }
            });
    }

}
