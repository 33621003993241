import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ComponentDeactivate } from '../common';
import { User } from '../common/model';
import { UserEditComponent } from './user-edit.component';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html'
})
export class UserNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(UserEditComponent, { static: true }) userEdit: UserEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService) {
    super(spinner, notify, router, data);
    console.log('UserNewComponent.create()');
  }

  ngOnInit() {
    console.log('UserNewComponent.init()');
    this.data.users.selected = new User();
    this.data.users.selected.admin = false;
    this.data.users.selected.active = true;
  }

  canDeactivate() {
    return this.userEdit.canDeactivate();
  }
}
