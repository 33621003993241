import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ConfirmComponent } from '../../common';
import { Function } from '../../common/model';
import { FunctionService } from '../../common/services';

@Component({
  selector: 'app-function-show',
  templateUrl: './function-show.component.html'
})
export class FunctionShowComponent extends BaseMainpage implements OnInit {

  @Input() fn: Function;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private functionService: FunctionService) {
    super(spinner, notify, router, data);
    console.log('FunctionShowComponent.create()');
  }

  ngOnInit() {
    console.log('FunctionShowComponent.init()');
  }

  delete() {
    this.confirm.open('Function', 'Delete function: ' + this.fn.name + ' ?', 'Delete')
      .then(() => {
        this.startSpinner();
        this.functionService.delete(this.fn)
          .then(() => {
            this.router.navigate(['/functions']);
          })
          .finally(() => {
            this.stopSpinner();
          });
      })
      .catch(() => { /* do nothing */ });
  }
}
