<div class="container-fluid">
  <div class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6"></app-sidenav>
    <main class="col-lg-9 col-md-8 col-sm-7 col-5" role="main">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-9">
          <span class="table-size float-end">{{data.incis.search().length}}</span>
          <h2>INCI Substances</h2>
        </div>
        <div class="col-12 col-md-4 col-lg-3 search-box" stickyThing>
          <input id="search" name="search" type="search" placeholder="Search" [(ngModel)]="data.incis.searchTerm" class="form-control">
        </div>
      </div>
      <app-alert></app-alert>
  
      <div class="container-fluid"> 
        <table class="table table-striped"><tbody>
          <tr>
            <th>Name</th>
            <th>CAS</th>
            <th>EC/EINECS/ELINCS/NLP</th>
            <th class="text-center">EUKV</th>
            <th class="text-center" ngbTooltip="'Maintained PoD routes'" tooltipClass="dt-tooltip">PoD</th>
          </tr>
          <tr *ngFor="let inci of data.incis.search()" class="entity" [ngClass]="{'table-active': data.incis.selected && inci.key==data.incis.selected.key}">
            <td [ngClass]="{'dirty': inci.dirty}"><span [ngbTooltip]="inci.dirty ? 'INCI has unsaved changes' : ''" tooltipClass="dt-tooltip" container="body">
              <a class="btn btn-link text-start" [routerLink]="['/incis', inci.key, inci.dirty ? 'edit' : '' ]">{{inci.name}}</a>
            </span></td>
            <td>{{inci.casNo}}</td>
            <td>{{inci.einecsNo}}</td>
            <td class="text-center">
              <span *ngFor="let a of inci.eukv.list; index as i">
                <span *ngIf="i>0">, </span>
                <span *ngIf="a.appendix">{{a.appendix | roman}}<sub>{{a.number}}</sub></span>
              </span>
            </td>
            <td class="text-center">
              <span *ngFor="let r of inci.getRouteInfo(); index as i">
                <span *ngIf="i>0">, </span>
                <span *ngIf="r" [ngbTooltip]="r.value + ': ' + inci.getRoute(r.value).value + ' (' + inci.getRoute(r.value).type + ')'" tooltipClass="dt-tooltip">{{r.short}}</span>
                <span *ngIf="!r" [ngbTooltip]="'Unknown route: ' + inci.getRoute().value" tooltipClass="dt-tooltip">?</span>
              </span>
            </td>
          </tr>
        </tbody></table>
      </div>

      <div *ngIf="data.incis.selected && !edit" >
        <app-inci-show [inci]="data.incis.selected"></app-inci-show>
      </div>
      <div *ngIf="data.incis.selected && edit">
        <app-inci-edit [inci]="data.incis.selected"></app-inci-edit>
      </div>
  
    </main>
  </div>
</div>
