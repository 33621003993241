import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppRoutingModule } from '../app-routing.module';
import { AppCommonsModule } from '../common/app-common.module';
import { NumberPrecisionComponent } from './test';

@NgModule({
  declarations: [
    NumberPrecisionComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CKEditorModule,
    AppRoutingModule,
    AppCommonsModule
  ],
})
export class AdminModule { }
