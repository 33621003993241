import { HtmlUtil } from '../html.util';

export class BaseIngredient {
    percentMin: number;
    percentMax: number;
    aggregated = false;

    constructor(source?: BaseIngredient) {
        if (source) {
            Object.assign(this, source);
        }
    }

    percentMinTrim(): number {
        return this.percentMin || 0;
    }

    percentMaxTrim(): number {
        return this.percentMax || this.percentMin || 0;
    }

    formatPercent(language: string = 'en'): string {
        const percMin = HtmlUtil.formatLocaleNumber(this.aggregated ? HtmlUtil.num2txt(this.percentMin, 4, 6) : '' + this.percentMin, language);
        const percMax = HtmlUtil.formatLocaleNumber(this.aggregated ? HtmlUtil.num2txt(this.percentMax, 4, 6) : '' + this.percentMax, language);
        if (this.percentMin == null && this.percentMax != null) {
            return '< ' + percMax;
        }
        if (this.percentMax == null && this.percentMin != null) {
            return percMin;
        }
        if (this.percentMin === this.percentMax && this.percentMin != null) {
            return percMin;
        }
        if (this.percentMin == null && this.percentMax == null) {
            return '--';
        }
        return percMin + ' - ' + percMax;
    }

}
