import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { HtmlUtil } from '../html.util';

@Directive({
  selector: '[appNormalize]'
})
export class NormalizeDirective {

  readonly DECIMAL = 'decimal';

  @Input('appNormalize') mode = this.DECIMAL;

  constructor(private el: ElementRef) { }

  @HostListener('change') onChange() {
    this.normalize();
  }

  private normalize() {
    const text = this.el.nativeElement.value;
    if (text) {
      if (this.mode === this.DECIMAL) {
        this.el.nativeElement.value = HtmlUtil.formatLocaleNumber(text, 'en');
      }
    }
  }
}
