import { BaseEntity } from './base_entity';
import { Address } from './address.model';

export class Customer extends BaseEntity {

  constructor(source?: Customer) {
    super(source);
    this.address = this.address || new Address();
    this.addresses = this.addresses || [];
  }

  name: string;
  address: Address;
  addresses: {
    role: string
    address: Address
  }[];
}
