import { Component, Input, ElementRef, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: 'tag.component.html'
})
export class TagComponent {

  @Input() label: any;
  @Input() tooltip: any;
  @Input() readonly: boolean;

  @Output() cancel: EventEmitter<any> = new EventEmitter();

  constructor(private el: ElementRef) { }

  remove() {
    console.log('remove()', 'Remove tag: ', this);
    if (this.cancel) {
      this.cancel.emit(null);
    }
  }
}
