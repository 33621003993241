import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from '../app-routing.module';
import { LibModule } from '../lib/lib.module';
import { AppCommonsModule } from '../common/app-common.module';

import { LoginComponent } from './login.component';
import { LandingComponent } from './landing.component';

@NgModule({
  declarations: [
    LoginComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgbTooltipModule,
    AppRoutingModule,
    LibModule,
    AppCommonsModule,
  ]
})
export class HomeModule { }
