import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, ComponentDeactivate, NotifyService } from '../../common';
import { IngredientService, InciService, SpecificationService } from '../../common/services';
import { IngredientEditComponent } from './ingredient-edit.component';
import { Ingredient, Specification } from 'src/app/common/model';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html'
})
export class IngredientsComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  public edit: boolean;

  @ViewChild(IngredientEditComponent) ingredientEdit: IngredientEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute,
      private ingredientService: IngredientService,
      private inciService: InciService,
      private specificationService: SpecificationService) {
    super(spinner, notify, router, data);
    console.log('IngredientsComponent.create()');
  }

  ngOnInit() {
    console.log('IngredientsComponent.init()');
    this.route.params.subscribe(params => {
      this._show(params['key']);
      this.edit = params['action'] === 'edit';
    });
    this._loadAll();
  }

  canDeactivate() {
    return !this.ingredientEdit || this.ingredientEdit.canDeactivate();
  }

  _show(key: string) {
    console.log('IngredientsComponent.show()', 'show ingredient: ', key);
    if (key) {
      this.ingredientService.getByKey(Number(key))
        .then(i => {
          this.data.ingredients.selected = i;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 0);
        });
    } else {
      this.data.ingredients.selected = null;
    }
  }

  private _loadAll() {
    console.log('IngredientsComponent._loadAll()');
    this.startSpinner();
    Promise.all([
      this.ingredientService.assertLoaded(),
      this.inciService.assertLoaded(),
      this.specificationService.assertLoaded()
    ])
      .then(() => {
        this.data.ingredients.sort((a: Ingredient, b: Ingredient) => a.getName().localeCompare(b.getName()));
        this.data.specifications.sort((a: Specification, b: Specification) => a.label.localeCompare(b.label));
      })
      .catch(err => {
          console.log('IngredientsComponent._loadAll()', 'Failed to load ingredient list: ', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
  }
}
