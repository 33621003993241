import { Inci } from './inci.model';
import { BaseIngredient } from './base_ingredient.model';

export class IngredientInci extends BaseIngredient {
    inciKey: number;
    ref: Inci;

    constructor(source?: IngredientInci) {
        super(source);
    }
}
