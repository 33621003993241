export enum Role {
  Author = 'AUTHOR',
  CoAuthor = 'CO_AUTHOR',
  Readonly = 'READONLY'
}

export class ReportUser {
  userId: number;
  role: Role;
  sortRank: number;
}
