import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbTypeaheadModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AceModule } from 'ngx-ace-wrapper';

import { AppRoutingModule } from '../app-routing.module';
import { LibModule } from '../lib/lib.module';
import { AppCommonsModule } from '../common/app-common.module';
import { SidenavComponent } from './sidenav.component';
import { CustomersComponent, CustomerShowComponent, CustomerEditComponent, CustomerNewComponent } from './customer';
import { IngredientsComponent, IngredientShowComponent, IngredientEditComponent, IngredientNewComponent } from './ingredient';
import { IncisComponent, InciShowComponent, InciEditComponent, InciNewComponent } from './inci';
import { FunctionsComponent, FunctionShowComponent, FunctionEditComponent, FunctionNewComponent } from './function';
import { TemplatesComponent, TemplateShowComponent, TemplateHistoryComponent, TemplateEditComponent, TemplateNewComponent, TemplateLangValidatorDirective } from './template';
import { DocxsComponent, DocxShowComponent } from './docx';
import { SpecificationsComponent, SpecificationShowComponent } from './specification';
import { SpecificationEditComponent, SpecificationNewComponent } from './specification';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CorrectionEditComponent, CorrectionNewComponent, CorrectionsComponent, CorrectionShowComponent } from './value/correction';

@NgModule({
  declarations: [
    CustomersComponent,
    CustomerShowComponent,
    CustomerEditComponent,
    CustomerNewComponent,
    CorrectionsComponent,
    CorrectionShowComponent,
    CorrectionEditComponent,
    CorrectionNewComponent,
    IngredientsComponent,
    IngredientShowComponent,
    IngredientEditComponent,
    IngredientNewComponent,
    IncisComponent,
    InciShowComponent,
    InciEditComponent,
    InciNewComponent,
    FunctionsComponent,
    FunctionShowComponent,
    FunctionEditComponent,
    FunctionNewComponent,
    TemplatesComponent,
    TemplateShowComponent,
    TemplateHistoryComponent,
    TemplateEditComponent,
    TemplateNewComponent,
    TemplateLangValidatorDirective,
    DocxsComponent,
    DocxShowComponent,
    SpecificationsComponent,
    SpecificationShowComponent,
    SpecificationEditComponent,
    SpecificationNewComponent,
    SidenavComponent
  ],
  exports: [
    TemplateLangValidatorDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbTooltipModule,
    CKEditorModule,
    AceModule,
    AppRoutingModule,
    LibModule,
    AppCommonsModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class MasterdataModule { }
