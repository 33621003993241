<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{customer.name}}</h3>

      <div class="row">
        <div class="col-md-12 form-group">
          <div>{{customer.address.address1}}</div>
          <div *ngIf="customer.address.address2">{{customer.address.address2}}</div>
          <div>{{customer.address.postcode}} {{customer.address.city}}</div>
          <div *ngIf="customer.address.region">{{customer.address.region}}</div>
          <div>{{customer.address.country}}</div>
        </div>
      </div>

      <!-- Related Items: -->
      <div class="row">
        <div class="col-12 col-md-6 form-group" *ngIf="data.templates.related(customer).length">
          <label>Related Templates:</label>
          <div>
            <a *ngFor="let t of data.templates.related(customer)"
                class="me-2 nav-link" [routerLink]="['/templates', t.key]">{{t.name}}<sub>({{t.language}})</sub></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-1">
      <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
        <a [routerLink]="['/customers', customer.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
      </div>
      <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
        <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
      </div>
    </div>
  </div>
</div>
