import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Ingredient, IngredientInci } from '../model';
import { DataService } from '../data.service';
import { InciService } from './inci.service';
import { EntityStore } from './entity_store';
import { SpecificationService } from './specification.service';

@Injectable()
export class IngredientService extends EntityStore<Ingredient> {

    constructor(
            http: HttpClient,
            dataService: DataService,
            inciService: InciService,
            specificationService: SpecificationService) {
        super(http, dataService.ingredients, '/api/ingredients', Ingredient);

        this._loadDependencies = () => {
            return Promise.all([
                inciService.assertLoaded(),
                specificationService.assertLoaded()
            ]).then(() => null);
        };

        this._preSave = (ingredient: Ingredient) => {
            ingredient = super._preSave(ingredient);
            if (ingredient.incis) {
                ingredient.incis.forEach(ii => {
                    ii.inciKey = ii.ref.key;
                });
            }
            // clone object before stripping redundant structure data
            const i = JSON.parse(JSON.stringify(ingredient));
            if (i.incis) {
                i.incis.forEach(ii => {
                    if (ii.ref.key) {
                        ii.ref = null;
                    }
                });
            }
            if (i.specifications && i.specifications.list) {
                i.specifications.list.forEach(inciSpec => {
                    if (inciSpec.ref) {
                        inciSpec.ref = null;
                    }
                });
            }
            return i;
        };

        this._postRead = (ingredient: Ingredient) => {
            if (ingredient.incis) {
                ingredient.incis = ingredient.incis.map(ii => {
                    const inci = new IngredientInci(ii);
                    inciService.getByKey(inci.inciKey).then(i => {
                        inci.ref = i;
                    });
                    return inci;
                });
                // sort incis by amount, descending
                ingredient.incis.sort((a, b) => {
                    return b.percentMaxTrim() - a.percentMaxTrim();
                });
            }
            if (ingredient.specifications && ingredient.specifications.list) {
                ingredient.specifications.list.forEach(inciSpec => {
                    specificationService.getByKey(inciSpec.specificationKey)
                        .then(s => {
                            inciSpec.ref = s;
                        });
                });
            }
            return ingredient;
        };

        this._prepareTypeahedNames = () => {
            this.data.names.all = this.data.list.map(item => item.getTypeaheadCaption());
        };
    }

    getByName(name: string, language?: string): Promise<Ingredient> {
        return this.assertLoaded()
            .then(() => {
                return this.data.list.find(i => {
                    return (i.name && i.name.toLocaleLowerCase() === name.toLocaleLowerCase() || i.tradename && i.tradename.toLocaleLowerCase() === name.toLocaleLowerCase())
                        && (!language || i.language === language);
                });
            });
    }

    getByCaption(caption: string, language?: string): Promise<Ingredient> {
        return this.assertLoaded()
            .then(() => {
                return this.data.list.find(i => {
                    return i.isIdentifiedBy(caption) && (!language || i.language === language);
                });
            });
    }

}
