import { BaseEntity } from './base_entity';

export class Docx extends BaseEntity {

  constructor(source?: Docx) {
    super(source);
  }

  name: string;
  description: string;
}
