import { BaseEntity } from './base_entity';

export class Value extends BaseEntity {

  constructor(source?: Value) {
    super(source);

    this.properties = this.properties || {}
  }

  context: string;
  type: string;
  label: string;
  default: boolean;

  properties: any;

  stripEntityProps(): ValueSkeleton {
    return new ValueSkeleton(this);
  }

}

export class ValueSkeleton {

  constructor(source?: object) {
    if (source) {
      ["context", "type", "label", "properties"].forEach(attr => {
        this[attr] = source[attr] != undefined ? JSON.parse(JSON.stringify(source[attr])) : null;
      });
    }
    this.properties = this.properties || {}
  }

  context: string;
  type: string;
  label: string;

  properties: any;

  stripEntityProps(): ValueSkeleton {
    return this;
  }

}

export class InciRouteCorrection extends Value {

  constructor(source?: Value) {
    super(source);

    // mko, 2024-05-27:
    // legacy support: properties 'text' and 'factor' where located immediately on object level
    ['text', 'factor'].forEach(attr => {
      if (this[attr]) {
        this.properties[attr] = this[attr];
        delete this[attr];
      }
    });

    this.properties.factor = this.properties.factor || 1.0;
  }

  properties: { text: string, factor: number};

}
