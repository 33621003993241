import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './auth.service';
import { Router } from '@angular/router';
import { AlertService } from '../alert.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService, private router: Router, private alert: AlertService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.authService.logout();
          if (this.router.url.indexOf('/login') ===  -1) {
            this.router.navigate(['/login']);
          }
        } else if (error.status >= 500) {
          console.error('AuthInterceptor.intercept()', 'Caught HTTP server error:', error);
          this.alert.error('Server reported a technical error');
        } else if (error.status >= 400) {
          console.error('AuthInterceptor.intercept()', 'Caught HTTP client error:', error);
          this.alert.error('An Unexpected error occured: ' + error.message);
        } else {
          console.warn('AuthInterceptor.intercept()', 'Caught HTTP error:', error);
        }
        return throwError(error);
      })
    );
  }
}
