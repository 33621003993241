import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, AuthenticationService, ComponentDeactivate, NotifyService } from '../../common';
import { Ingredient } from '../../common/model';
import { IngredientEditComponent } from './ingredient-edit.component';

@Component({
  selector: 'app-ingredient-new',
  templateUrl: './ingredient-new.component.html'
})
export class IngredientNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(IngredientEditComponent, { static: true }) ingredientEdit: IngredientEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private authService: AuthenticationService) {
    super(spinner, notify, router, data);
    console.log('IngredientNewComponent.create()');
  }

  ngOnInit() {
    console.log('IngredientNewComponent.init()');
    this.data.ingredients.selected = new Ingredient();
  }

  canDeactivate() {
    return this.ingredientEdit.canDeactivate();
  }
}
