<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <form #f="ngForm" (submit)="save()">
    <br>
    <div class="row">
      <div class="col-11">
        <h3>{{val.label ? val.label : 'New INCI Route Correction'}}</h3>
      </div>
      <div *ngIf="val.id" class="col-1">
        <div class="form-group" ngbTooltip="Close Edit" tooltipClass="dt-tooltip">
          <a [routerLink]="['/values/correction', val.key]" class="form-control btn"><span class="float-end oi oi-caret-top"></span></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 form-group"><label for="label">Label*</label>
        <input id="label" name="label" type="text" [(ngModel)]="val.label" #label="ngModel" required appTrim class="form-control">
        <div [hidden]="label.valid || label.pristine" class="alert alert-danger">Label is required</div> 
      </div>
    </div>
    <div class="row">
      <div class="col-12  col-lg-8 form-group"><label for="text">Description</label>
        <input id="description" name="description" type="text" [(ngModel)]="val.properties.text" #description="ngModel" appTrim class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-3 col-lg-2 form-group"><label for="factor">Factor</label>
        <input id="factor" name="factor" type="number" [(ngModel)]="val.properties.factor" #factor="ngModel" required appTrim class="form-control">
        <div [hidden]="factor.valid || factor.pristine" class="alert alert-danger">Factor is required</div>
      </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-8 form-group">
            <button class="btn btn-primary form-control">{{val.id ? 'Update' : 'Create'}}</button>
        </div>
    </div>

  </form>
</div>
