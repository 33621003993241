import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, HtmlUtil, ComponentDeactivate, ConfirmComponent } from '../../common';
import { SpecificationService } from '../../common/services';
import { Specification } from 'src/app/common/model';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { SpecificationEditComponent } from './specification-edit.component';

@Component({
  selector: 'app-specifications',
  templateUrl: './specifications.component.html'
})
export class SpecificationsComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  public edit: boolean;
  public sortOrderDirty = false;

  @ViewChild(SpecificationEditComponent) specificationEdit: SpecificationEditComponent;
  @ViewChild('confirm_list', { static: true }) confirm: ConfirmComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private sanitizer: DomSanitizer,
      private route: ActivatedRoute,
      private specificationService: SpecificationService) {
    super(spinner, notify, router, data);
    console.log('SpecificationsComponent.create()');
  }

  ngOnInit() {
    console.log('SpecificationsComponent.init()');
    this.route.params.subscribe(params => {
      this._show(params['key']);
      this.edit = params['action'] === 'edit';
    });
    this._loadAll();
  }

  canDeactivate() {
    if (this.sortOrderIsDirty()) {
      this.confirm.open('Specifications', 'Save modified Sort Order?', 'Save')
        .then(() => {
          this.save();
        })
        .catch(() => { /* do nothing */ });
      return true;
    } else {
      return !this.specificationEdit || this.specificationEdit.canDeactivate();
    }
  }

  sortOrderIsDirty(): boolean {
    return this.sortOrderDirty;
  }

  up(spec: Specification) {
    console.log('SpecificationsComponent.up()', spec.label);
    spec.sortRank -= 1;
    this.data.specifications.list[spec.sortRank].sortRank += 1;
    this.data.specifications.sort((a: Specification, b: Specification) => a.sortRank - b.sortRank);
    this.sortOrderDirty = true;
  }

  down(spec: Specification) {
    console.log('SpecificationsComponent.down()', spec.label);
    spec.sortRank += 1;
    this.data.specifications.list[spec.sortRank].sortRank -= 1;
    this.data.specifications.sort((a: Specification, b: Specification) => a.sortRank - b.sortRank);
    this.sortOrderDirty = true;
  }

  save() {
    if (this.sortOrderIsDirty()) {
      this.startSpinner();
      console.log('SpecificationsComponent._save()', 'update existing specification');

      return Promise.all(
          this.data.specifications.list.map(spec => this.specificationService.update(spec))
        )
        .then(() => {
          this.sortOrderDirty = false;
        })
        .then(() => this.notify_ok())
        .catch(e => this.notify_error('SpecificationsComponent.save()', e))
        .finally(() => this.stopSpinner());
    }
  }

  _show(key: string) {
    console.log('SpecificationComponent._show()', 'show specification: ', key);
    if (key) {
      this.specificationService.getByKey(Number(key))
        .then(s => {
          this.data.specifications.selected = s;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 0);
        });
    } else {
      this.data.specifications.selected = null;
    }
  }

  private _loadAll() {
    console.log('SpecificationsComponent._loadAll()');
    this.startSpinner();
    this.specificationService.assertLoaded()
        .then(() => {
          this.data.specifications.sort((a: Specification, b: Specification) => a.sortRank - b.sortRank);
          this.data.specifications.list.forEach((spec: Specification, index: number) => {
            this.sortOrderDirty = this.sortOrderDirty || (spec.sortRank !== index);
            spec.sortRank = index;
          });
          this.data.specifications.sort((a: Specification, b: Specification) => a.sortRank - b.sortRank);
        })
        .catch(err => {
            console.log('SpecificationsComponent._loadAll()', 'Failed to load specification list: ', err);
        })
        .finally(() => {
          this.stopSpinner();
        });
  }

  text2html(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(HtmlUtil.text2html(text));
  }

}
