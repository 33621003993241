import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Template } from '../model';
import { DataService } from '../data.service';
import { EntityStore } from './entity_store';
import { CustomerService } from './customer.service';
import { IngredientService } from './ingredient.service';

@Injectable()
export class TemplateService extends EntityStore<Template> {

    constructor(
        http: HttpClient,
        dataService: DataService,
        customerService: CustomerService,
        ingredientService: IngredientService) {
        super(http, dataService.templates, '/api/templates', Template);

        this._loadDependencies = () => {
            return Promise.all([
                customerService.assertLoaded()
            ]).then(() => null);
        };

        this._preSave = (template: Template): Template => {
            template = super._preSave(template);
            if (!template.templateKey) {
                template.templateKey = template.rootTemplate ? template.rootTemplate.key : null;
            }
            
            template.customerKey = (template.customer && template.customer.key) ? template.customer.key : null;
            template.ingredientKey = (template.ingredient && template.ingredient.key) ? template.ingredient.key : null;

            // clone object before stripping redundant structure data
            template = JSON.parse(JSON.stringify(template));
            template.rootTemplate = null;
            template.customer = null;
            template.ingredient = null;
            template.properties_s = null;
            return template;
        };

        this._postRead = (template: Template): Template => {
            if (template.templateKey) {
                this.getByKey(template.templateKey).then(t => {
                    template.rootTemplate = t;
                });
            }
            if (template.customerKey) {
                customerService.getByKey(template.customerKey).then(c => {
                    template.customer = c;
                });
            }
            if (template.ingredientKey) {
                ingredientService.getByKey(template.ingredientKey).then(i => {
                    template.ingredient = i;
                });
            }
            return template;
        };
    }

    loadByName(
            templateKey: number, lang: string,
            name: string, customerKey: number, ingredientKey: number, inciName: string
    ): Promise<Template[]> {
        return this.assertLoaded()
            .then(() => {
                return this.data.list
                    .filter(t => t.name === name && t.language === lang);
            })
            .then(list => {
                // Search only for templates that have at most one of the filter parameters set.
                if (customerKey != null && customerKey > 0) {
                    return list.filter(t => t.customerKey === customerKey)
                        .filter(t => t.ingredientKey == null && (t.inciName == null || t.inciName == ''));
                }
                list = list.filter(t => t.customerKey == null);
                if (ingredientKey != null && ingredientKey > 0) {
                    return list.filter(t => t.ingredientKey === ingredientKey)
                        .filter(t => t.inciName == null || t.inciName == ''); // customerKey already filtered
                }
                list = list.filter(t => t.ingredientKey == null);
                if (inciName != null && inciName != '') {
                    return list.filter(t => t.inciName && (t.inciName === inciName || t.inciName.replace(/[^\w]/g, '_') === inciName));
                        // customerKey and ingredientsKey already filtered.
                }
                list = list.filter(t => t.inciName == null || t.inciName === '');
                return list;
            })
            .then(list => {
                // if there are multiple results, then use root template reference as additional filter
                if (list.length > 1) {
                    return list
                        .filter(t => t.templateKey === templateKey);
                } else {
                    return list;
                }
            });
    }

    loadBySection(templateKey: number, section: string, lang: string): Promise<Template[]> {
        return this.assertLoaded()
            .then(() => {
                return this.data.list.filter(t => t.templateKey === templateKey && t.section === section && t.language === lang);
            });
    }

}
