import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ConfirmComponent, Constants } from '../../common';
import { Inci } from '../../common/model';
import { InciService } from '../../common/services';

@Component({
  selector: 'app-inci-show',
  templateUrl: './inci-show.component.html'
})
export class InciShowComponent extends BaseMainpage implements OnInit {

  @Input() inci: Inci;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  public eukvLabels = Constants.EUKV_APPENDIX;
  public POD = Constants.POD;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private inciService: InciService) {
    super(spinner, notify, router, data);
    console.log('InciShowComponent.create()');
  }

  ngOnInit() {
    console.log('InciShowComponent.init()');
  }

  delete() {
    this.confirm.open('INCI', 'Delete INCI: ' + this.inci.name + ' ?', 'Delete')
      .then(() => {
        this.startSpinner();
        this.inciService.delete(this.inci)
          .then(() => {
            this.router.navigate(['/incis']);
          })
          .finally(() => {
            this.stopSpinner();
          });
      })
      .catch(() => { /* do nothing */ });
  }

}
