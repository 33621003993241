import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Customer, Address } from '../model';
import { DataService } from '../data.service';
import { EntityStore } from './entity_store';
import { Constants } from '../constants';

@Injectable()
export class CustomerService extends EntityStore<Customer> {

    constructor(http: HttpClient, dataService: DataService) {
        super(http, dataService.customers, '/api/customers', Customer);

        this._preSave = (customer: Customer) => {
            customer = super._preSave(customer);
            const ca = customer.addresses.find(x => Constants.ADRESS_ROLE.DEFAULT === x.role);
            if (ca) {
                ca.address = customer.address;
            } else {
                customer.addresses.push({
                    role: Constants.ADRESS_ROLE.DEFAULT,
                    address: customer.address
                });
            }
            customer.addresses.forEach(x => {
                if (x.address) {
                    x.address.createdBy = localStorage.getItem('currentUser');
                }
            });
            // clone object before stripping redundant structure data
            const c = JSON.parse(JSON.stringify(customer));
            c.address = null;
            return c;
        };

        this._postRead = (customer: Customer) => {
            const ca =  customer.addresses.find(x => Constants.ADRESS_ROLE.DEFAULT === x.role);
            customer.address = ca ? ca.address : new Address();
            return customer;
        };
    }

}
