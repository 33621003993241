import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

    private _label: BehaviorSubject<string>;
    private _style: BehaviorSubject<string>;

    constructor() {
        this._label = new BehaviorSubject<string>(null);
        this._style = new BehaviorSubject<string>('msg');
    }

    ok(): void {
        console.log('NotifyService.ok');
        this._label.next('ok');
        this.stop(700);
    }

    oops(): void {
        console.log('NotifyService.oops');
        this._style.next('error');
        this._label.next('oops');
        this.stop(700);
    }

    stop(offset = 0): void {
        setTimeout(() => {
            console.log('NotifyService.stop');
            this._style.next('msg');
            this._label.next(null);
          }, offset);
    }

    label(): Observable<string> {
        return this._label.asObservable();
    }
    style(): Observable<string> {
        return this._style.asObservable();
    }
}
