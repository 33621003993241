import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, Constants, ConfirmComponent, BaseEditpage, ComponentDeactivate } from '../../common';
import { DataService, SpinnerService, NotifyService } from '../../common';
import { Specification, SpecificationLabel } from '../../common/model';
import { SpecificationService } from '../../common/services';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-specification-edit',
  templateUrl: './specification-edit.component.html'
})
export class SpecificationEditComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @Input() spec: Specification;
  @Input() language = 'de';

  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  public languages = Constants.LANG;
  public newLabel: string;
  public newSupplement: string;
  public newDescr: string;

  private editpage: BaseEditpage<Specification> = null;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private specificationService: SpecificationService) {
    super(spinner, notify, router, data);
    console.log('SpecificationEditComponent.create()');
  }

  ngOnInit() {
    console.log('SpecificationEditComponent.init()');
    this.editpage = new BaseEditpage(this, this.form, this.confirm, this.specificationService, this.spec);
    this.editpage._postCreate = (s: Specification) => {
      this.router.navigate(['/specifications', s.key]);
    };
    this.editpage.caption = () => {
      return this.spec.label;
    };
  }

  canDeactivate() {
    return this.editpage.canDeactivate();
  }

  save(): Promise<void> {
    return this.editpage.save();
  }

  addLabel() {
    if (this.newLabel) {
      const l = new SpecificationLabel();
      l.label = this.newLabel;
      l.supplement = this.newSupplement;
      l.descr = this.newDescr;
      l.language = this.language;
      this.spec.labels.push(l);
      this.newLabel = null;
      this.newSupplement = null;
      this.newDescr = null;
    }
  }

  removeLabel(label: SpecificationLabel, event: Event) {
    console.log('SpecificationEditComponent.removeLabel()', label);
    event.preventDefault();
    this.spec.labels = this.spec.labels.filter(l => l !== label);
    this.form.form.markAsDirty();
  }
}
