import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { DataService } from 'src/app/common';

import { Template } from 'src/app/common/model';

@Directive({
  selector: '[appTemplateLang]',
  providers: [{provide: NG_VALIDATORS, useExisting: TemplateLangValidatorDirective, multi: true}]
})
export class TemplateLangValidatorDirective implements Validator {

  @Input('appTemplateLang') template: Template;
  @Input('appDataService') data: DataService;

  validate(control: AbstractControl): {[key: string]: any} | null {
    const isRoot = this.template.root;
    const templateKey = isNaN(control.value) ? null : Number(control.value);
    if (!this.template) {
      console.warn('TemplateLangValidatorDirective.validate()', 'No template instance found!', control, this.template);
      return null;
    }
    if (!isRoot) {
      if (!templateKey) {
        return { 'required': true }
      }
      const rootTemplate = this.data.templates.findByKey(templateKey);
      if (!rootTemplate) {
        return { 'required': true }
      }
      if (this.template.language != rootTemplate.language) {
        console.log('TemplateLangValidatorDirective.validate()', 'Root template has wrong language!', this.template.language, '<->', rootTemplate.language);
        return { 'language': true };
      }
    }
    return null;
  }

}
