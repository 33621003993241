import { BaseEntity } from './base_entity';
import { Template } from './template.model';
import { DataContext } from './data-context';
import { DataField, Calculation, Property, Dynamic } from './textfields';

export class Textblock extends BaseEntity {

  static Type = {
    TEMPLATE: 'template',
    ALERT: 'alert',
    HTML: 'html',
    TAG: 'tag',
    ITERATION: 'iteration',
  };

  constructor(source?: any /* Textblock */) {
    super(source);
    this.uuid = source && source.uuid ? source.uuid : Math.random().toString(36).substring(2);
    this.properties = this.properties || {};
    this.childBlocks = this.childBlocks || [];
  }

  name: string;
  label: string;
  language: string;
  description: string;

  templateId: number;

  position: string;
  properties: {
    type?: string;
    typeProps?: any;
    frozen?: boolean;
    newPage?: boolean;
  };

  dataText: string;

  alert: boolean;

  // attributes not persisted
  uuid: string;
  templateRef: Template;
  parent?: Textblock;
  childBlocks: Textblock[];
  dataCtx?: any;
  debugLabel = '';

  static fromTemplate(t: Template): Textblock {
    const tb = new Textblock();
    tb.name = t.name;
    tb.label = t.label;
    tb.language = t.language;
    tb.description = t.description;
    tb.templateId = t.id;
    tb.templateRef = t;
    tb.properties = {
      type: Textblock.Type.TEMPLATE,
      frozen: false,
      newPage: t.properties.newPage ? true : false
    };
    return tb;
  }

  static fromHtml(t: Template, html: string): Textblock {
    const tb = new Textblock();
    tb.name = t.name;
    tb.label = t.label;
    tb.language = t.language;
    tb.templateId = t.id;
    tb.templateRef = t;
    tb.properties = {
      type: Textblock.Type.HTML,
      frozen: t.properties.autoFreeze ? true : false,
      newPage: t.properties.newPage ? true : false
    };
    tb.dataText = html;
    return tb;
  }

  static fromTag(t: Template, tag: string, attr: any): Textblock {
    const tb = new Textblock();
    tb.name = t.name;
    tb.language = t.language;
    tb.templateId = t.id;
    tb.templateRef = t;
    tb.properties = {
      type: Textblock.Type.TAG,
      typeProps: { name: tag, attributes: attr },
      frozen: false
    };
    return tb;
  }

  static fromIteration(t: Template): Textblock {
    const tb = new Textblock();
    tb.name = t.name;
    tb.language = t.language;
    tb.templateId = t.id;
    tb.templateRef = t;
    tb.properties = {
      type: Textblock.Type.ITERATION,
      frozen: false
    };
    return tb;
  }

  static fromAlert(t: Template, alert: any): Textblock {
    const tb = new Textblock(alert);
    tb.language = t.language;
    tb.properties = {
      type: Textblock.Type.ALERT,
      frozen: false
    };
    tb.alert = true;
    return tb;
  }

  static buildTree(list: Textblock[]): Textblock {
    const root = list.find(tb => tb.position === '') || null;
    if (root) {
      // attach each block as child to its parent
      list = list.filter(tb => tb.position.length > 0);
      let stillWorking = true;
      while (stillWorking) {
        stillWorking = false;
        const blocksDone = [];
        root.iteratePreorder(node => {
          list.forEach(tb => {
            if (tb.position.indexOf(node.position) === 0
                && tb.position.length > node.position.length
                && tb.position.slice(node.position.length + 1).split('.').length === 1) {
              node.childBlocks.push(tb);
              tb.parent = node;
              blocksDone.push(tb);
              stillWorking = true;
            }
          });
          list = list.filter(tb => blocksDone.indexOf(tb) === -1);
        });
      }
      // sort each child block list within content tree
      root.iteratePreorder(tb => {
        if (tb.childBlocks && tb.childBlocks.length > 1) {
          tb.childBlocks.sort((a, b) => {
            const posA = a.position.split('.').map(s => Number(s)).slice(-1)[0];
            const posB = b.position.split('.').map(s => Number(s)).slice(-1)[0];
            return posA - posB;
          });
          // console.log('Textblock.buildTree()', 'sorted child blocks: ', tb.childBlocks.map(b => b.position));
        }
      });
    }
    if (list.length > 0) {
      console.warn('Textblock.buildTree()', 'Failed to insert some blocks into content tree:', list);
    }
    return root;
  }

  findChild(uuid: string): Textblock {
    let block = null;
    this.iteratePreorder(tb => {
      if (!block && tb.uuid === uuid) {
        block = tb;
      }
    });
    return block;
  }

  replaceChild(uuid: string, block: Textblock): Textblock {
    let replaced = null;
    this.iteratePreorder(tb => {
      if (!replaced) {
        const t = tb.childBlocks.find(b => b.uuid === uuid);
        if (t != null) {
          replaced = t;
          tb.childBlocks[tb.childBlocks.indexOf(t)] = block;
        }
      }
    });
    return replaced;
  }

  signature(): string {
    let typeParams = '';
    if (this.properties.type === Textblock.Type.HTML || this.properties.type === Textblock.Type.ALERT) {
      typeParams = this.dataText && this.dataText.length > 25 ? this.dataText.substring(0, 25) + '...' : this.dataText;
    } else if (this.properties.type === Textblock.Type.TAG) {
      typeParams = this.properties.typeProps.name + ' ' + JSON.stringify(this.properties.typeProps.attributes);
    } else if (this.properties.type === Textblock.Type.TEMPLATE) {
      typeParams = this.name;
    }
    return this.properties.type + ': ' + typeParams;
  }

  isEditable(): boolean {
    return this.properties.type === Textblock.Type.HTML || this.properties.type === Textblock.Type.ALERT;
  }

  needsIncludeSelection(): boolean {
    return this.properties.type === Textblock.Type.TAG
      && this.properties.typeProps.name === 'dt-include-any'
      && this.properties.typeProps.confirmed === false;
  }

  containsProperty(): boolean {
    if (this.isEditable()) {
      let REGEX = Property.regexHtml();
      const found = REGEX.test(this.dataText);
      if (!found) {
        let match: RegExpExecArray;
        REGEX = DataField.regexHtml();
        while ((match = REGEX.exec(this.dataText)) != null) {
          const df = DataField.fromTemplateMatch(match);
          if (df.alias) {
            return true;
          }
        }
      }
      return found;
    }
    return this.childBlocks.reduce((summary, t) => summary || t.containsProperty(), false);
  }

  containsCalculation(): boolean {
    if (this.isEditable()) {
      const REGEX = Calculation.regexHtml();
      return REGEX.test(this.dataText);
    }
    return this.childBlocks.reduce((summary, t) => summary || t.containsCalculation(), false);
  }

  containsDynamicBlock(): boolean {
    if (this.isEditable()) {
      const REGEX = Dynamic.regexHtml();
      return REGEX.test(this.dataText);
    }
    return this.childBlocks.reduce((summary, t) => summary || t.containsDynamicBlock(), false);
  }

  append(tb: Textblock): void {
    this.childBlocks.push(tb);
    tb.parent = this;
  }

  iteratePreorder(collector: (t: Textblock, position?: string) => void, position = ''): void {
    collector(this, position);
    if (this.childBlocks) {
      this.childBlocks.forEach((t, i) => t.iteratePreorder(collector, position + '.' + i));
    }
  }

  iteratePostorder(collector: (t: Textblock, position?: string) => void, position = ''): void {
    if (this.childBlocks) {
      this.childBlocks.forEach((t, i) => t.iteratePostorder(collector, position + '.' + i));
    }
    collector(this, position);
  }

  asFlatList(): Textblock[] {
    const result = [];
    this.iteratePreorder(t => {
      if (t.needsIncludeSelection()) {
        result.push(t);
      }
      if (t.isEditable()) {
        result.push(t);
      }
    });
    return result;
  }

  /**
   * Returns a list of textblocks for content presentation.
   * It's parent-child hierary is only 2 layers deep, it consists of chapter blocks
   * and their editable content blocks.
   * The method is only called on the root block of the generated content tree.
   */
  asUiCondensedList(): Textblock[] {
    return this.childBlocks.map(b => b.asUiCondensedBlock());
  }
  asUiCondensedBlock(): Textblock {
    const clone = new Textblock(this);
    clone.childBlocks = this.isEditable() ? [] : this.asFlatList();
    return clone;
  }

  collectedDataCtx(): DataContext {
    return this.parent ? this.parent.collectedDataCtx().wrap(this.dataCtx) : new DataContext(this.dataCtx);
  }

  rootDataCtx(): any {
    return this.parent ? this.parent.rootDataCtx() : this.dataCtx;
  }

  /**
   * This method is called when converting templates into textblocks.
   * It scans for data references (i.e. '{{foo.bar}}') within the attributes of calculation fields
   * and replaces those by resolved values.
   */
  resolveDataReference(text: string): string {
    const dataCtx = this.collectedDataCtx();
    if (text) {
      let match: RegExpExecArray;
      while ((match = DataField.regexTemplate().exec(text)) != null) {
        const df = DataField.fromTemplateMatch(match);
        df.value = dataCtx.get(df.source);
        text = text.replace(match[0], ('' + df.value).replace(/[^\w]/g, '_'));
      }
    }
    return text;
  }

  /**
   * This method is called when converting templates into textblocks.
   */
  resolveDatafields(): void {
    this.iteratePostorder(tb => {
      const dataCtx = tb.collectedDataCtx();
      if (tb.dataText) {
        let resultText = tb.dataText;
        let match: RegExpExecArray;
        const REGEX = DataField.regexHtml();
        while ((match = REGEX.exec(tb.dataText)) != null) {
          const df = DataField.fromHtmlMatch(match);
          if (!df.final) {
            df.source = tb.resolveDataReference(df.source);
            df.value = dataCtx.get(df.source);
            if (dataCtx.isLocal(df.source)) {
              df.final = true;
              df.alias = tb.resolveDataReference(df.alias);
            }
            console.log('Textblock.resolveDatafields()', 'Found datafield', match[0], ' -> ', df.value);
            resultText = resultText.replace(match[0], df.toHtmlTag(this.language));
          }
          if (df.alias) {
            dataCtx.add(df.alias, df.value);
          }
        }
        tb.dataText = resultText;
      }
    });
  }

  /**
   * This method is called when converting templates into textblocks.
   */
  resolveProperties(): void {
    this.iteratePostorder(tb => {
      const dataCtx = tb.collectedDataCtx();
      if (tb.isEditable() && tb.containsProperty()) {
        let resultText = tb.dataText;
        const REGEX = Property.regexHtml();
        let match: RegExpExecArray;
        while ((match = REGEX.exec(tb.dataText)) != null) {
          const p = Property.fromHtmlMatch(match);
          p.alias = tb.resolveDataReference(p.alias);
          if (p.alias && dataCtx.get(p.alias)) {
            // user already entered a value for this property previously
            p.value = dataCtx.get(p.alias);
          }
          console.log('Textblock.resolveProperties()', 'Found property:', match[0], ' -> ', p.alias);
          resultText = resultText.replace(match[0], p.toHtmlTag());
        }
        tb.dataText = resultText;
      }
    });
  }

  /**
   * This method is called when converting templates into textblocks.
   */
  resolveCalculations(): void {
    this.iteratePostorder(tb => {
      const dataCtx = tb.collectedDataCtx();
      if (tb.isEditable() && tb.containsCalculation()) {
        let resultText = tb.dataText;
        const REGEX = Calculation.regexHtml();
        let match: RegExpExecArray;
        while ((match = REGEX.exec(tb.dataText)) != null) {
          const c = Calculation.fromHtmlMatch(match);
          c.formula = tb.resolveDataReference(c.formula);
          c.alias = tb.resolveDataReference(c.alias);
          c.parseFormula();
          console.log('Textblock.resolveCalculations()', 'Found calculation:', match[0], ' -> ', c.formula);
          resultText = resultText.replace(match[0], c.toHtmlTag(this.language));
        }
        tb.dataText = resultText;
      }
    });
  }

  /**
   * This method is called when converting templates into textblocks.
   */
  resolveDynamicBlocks(): void {
    this.iteratePostorder(tb => {
      const dataCtx = tb.collectedDataCtx();
      if (tb.isEditable() && tb.containsDynamicBlock()) {
        let resultText = tb.dataText;
        const REGEX = Dynamic.regexHtml();
        let match: RegExpExecArray;
        while ((match = REGEX.exec(tb.dataText)) != null) {
          const d = Dynamic.fromHtmlMatch(match);
          d.prop = tb.resolveDataReference(d.prop);
          console.log('Textblock.resolveDynamicBlocks()', 'Found dynamic block:', match[0], 'resolved to: ', d.toHtmlTag());
          resultText = resultText.replace(match[0], d.toHtmlTag());
        }
        tb.dataText = resultText;
      }
    });
  }

  /**
   * This method is called when refreshing calculations.
   */
  collectProperties(): void {
    this.iteratePostorder(tb => {
      const dataCtx = tb.collectedDataCtx();
      if (tb.isEditable() && tb.containsProperty()) {
        let resultText = tb.dataText;
        const REGEX = Property.regexHtml();
        let match: RegExpExecArray;
        while ((match = REGEX.exec(tb.dataText)) != null) {
          const p = Property.fromHtmlMatch(match);
          p.formatValue(this.language);
          dataCtx.add(p.alias, p.value);
          console.log('Textblock.collectProperties()', 'store property:', p.alias, ' -> ', p.value);
          resultText = resultText.replace(match[0], p.toHtmlTag());
        }
        tb.dataText = resultText;
      }
    });
  }

  /**
   * This method is called when refreshing calculations.
   */
  refreshCalculations(): void {
    this.iteratePostorder(tb => {
      const dataCtx = tb.collectedDataCtx();
      if (tb.isEditable() && tb.containsCalculation()) {
        console.log('Textblock.refreshCalculations()', tb);
        let resultText = tb.dataText;
        const REGEX = Calculation.regexHtml();
        let match: RegExpExecArray;
        while ((match = REGEX.exec(tb.dataText)) != null) {
          const c = Calculation.fromHtmlMatch(match);
          c.parseFormula();
          c.refresh(dataCtx);
          if (c.alias) {
            dataCtx.add(c.alias, c.value);
            console.log('Textblock.refreshCalculations()', c.alias, ':', c.formula, ' = ', c.evalFormula, ' -> ', c.value);
          }
          console.log('Textblock.refreshCalculations()', 'Found calculation:', match[0], ' -> ', c.value);
          resultText = resultText.replace(match[0], c.toHtmlTag(this.language));
        }
        tb.dataText = resultText;
      }
    });
  }

    /**
   * This method is called when refreshing calculations.
   */
  refreshDynamicBlocks(): void {
    this.iteratePostorder(tb => {
      const dataCtx = tb.collectedDataCtx();
      if (tb.isEditable() && tb.containsDynamicBlock()) {
        console.log('Textblock.refreshDynamicBlocks()', tb);
        let resultText = tb.dataText;
        const REGEX = Dynamic.regexHtml();
        let match: RegExpExecArray;
        while ((match = REGEX.exec(tb.dataText)) != null) {
          const d = Dynamic.fromHtmlMatch(match);
          d.refresh(dataCtx);
          console.log('Textblock.refreshDynamicBlocks()', 'Found dynamic block:', match[0]);
          resultText = resultText.replace(match[0], d.toHtmlTag());
        }
        tb.dataText = resultText;
      }
    });
  }

}
