import {Component, Input} from '@angular/core';

import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&nbsp;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="float-end">
        <button type="button" class="btn btn-primary" ngbAutofocus (click)="activeModal.close(true)">{{buttonLabel}}</button>
      </p>
      <p>{{msg}}</p>
    </div>
  `
})
// tslint:disable-next-line: component-class-suffix
export class ConfirmContent {
  @Input() title: string;
  @Input() msg: string;
  @Input() buttonLabel: string;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-confirm',
  template: ''
})
export class ConfirmComponent {
  constructor(private modalService: NgbModal) {}

  open(title: string, msg: string, buttonLabel = 'OK'): Promise<void> {
    const modalRef = this.modalService.open(ConfirmContent, {
      centered: true
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.msg = msg;
    modalRef.componentInstance.buttonLabel = buttonLabel;

    const p = new Promise<void>((resolve, reject) => {
      modalRef.result
        .then(() => { resolve(); }, () => { reject(); })
        .catch(() => {
          reject();
        })
        .finally(() => {
          modalRef.dismiss();
        });
    });

    return p;
  }
}
