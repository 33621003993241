import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ConfirmComponent, HtmlUtil } from '../../common';
import { Specification } from '../../common/model';
import { SpecificationService } from '../../common/services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-specification-show',
  templateUrl: './specification-show.component.html'
})
export class SpecificationShowComponent extends BaseMainpage implements OnInit {

  @Input() spec: Specification;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private sanitizer: DomSanitizer,
      private specificationService: SpecificationService) {
    super(spinner, notify, router, data);
    console.log('SpecificationShowComponent.create()');
  }

  ngOnInit() {
    console.log('SpecificationShowComponent.init()');
  }

  delete() {
    this.confirm.open('Specification', 'Delete specification: ' + this.spec.label + ' ?', 'Delete')
      .then(() => {
        this.startSpinner();
        this.specificationService.delete(this.spec)
          .then(() => {
            this.router.navigate(['/specifications']);
          })
          .finally(() => {
            this.stopSpinner();
          });
      })
      .catch(() => { /* do nothing */ });
  }

  text2html(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(HtmlUtil.text2html(text));
  }
}
