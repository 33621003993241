<div class="container-fluid">
  <div class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6"></app-sidenav>
    <main class="col-lg-9 col-md-8 col-sm-7 col-5" role="main">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-9">
          <span class="table-size float-end">{{data.ingredients.search().length}}</span>
          <h2>Raw Materials</h2>
        </div>
        <div class="col-12 col-md-4 col-lg-3 search-box" stickyThing>
          <input id="search" name="search" type="search" placeholder="Search" [(ngModel)]="data.ingredients.searchTerm" class="form-control">
        </div>
      </div>
      <app-alert></app-alert>

      <div class="container-fluid">
        <table class="table table-striped"><tbody>
          <tr>
            <th>Name</th>
            <th class="text-center">Perfume</th>
            <th>INCIs</th>
            <th class="text-center">Language</th>
          </tr>
          <tr *ngFor="let ingredient of data.ingredients.search()" class="entity" [ngClass]="{'table-active': data.ingredients.selected && ingredient.key==data.ingredients.selected.key}">
            <td [ngClass]="{'dirty': ingredient.dirty}">
              <span [ngbTooltip]="ingredient.dirty ? 'Raw material has unsaved changes' : ''" tooltipClass="dt-tooltip" container="body">
                <a class="btn btn-link text-start" [routerLink]="['/ingredients', ingredient.key, ingredient.dirty ? 'edit' : '' ]">{{ingredient.getName()}}</a>
                <span *ngIf="ingredient.name && (ingredient.name != ingredient.tradename)">&nbsp;{{ingredient.tradename}}</span>
                <span *ngIf="ingredient.manufacturer">&nbsp;({{ingredient.manufacturer}})</span>
              </span>
            </td>
            <td class="text-center"><input name="prefume" type="checkbox" disabled [(ngModel)]="ingredient.perfume"></td>
            <td><span *ngFor="let inci of ingredient.incis; index as i">{{i ? ', ' : ''}}{{inci.ref ? inci.ref.name : ''}}</span></td>
            <td class="text-center">{{ingredient.language}}</td>
          </tr>
        </tbody></table>
      </div>

      <div  *ngIf="data.ingredients.selected && !edit">
        <app-ingredient-show [ingredient]="data.ingredients.selected"></app-ingredient-show>
      </div>
      <div  *ngIf="data.ingredients.selected && edit">
        <app-ingredient-edit [ingredient]="data.ingredients.selected"></app-ingredient-edit>
      </div>
  
    </main>
  </div>
</div>
