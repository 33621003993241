import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, ComponentDeactivate, NotifyService } from '../../common';
import { InciService, FunctionService, ValueService } from '../../common/services';
import { InciEditComponent } from './inci-edit.component';
import { Inci } from 'src/app/common/model';

@Component({
  selector: 'app-incis',
  templateUrl: './incis.component.html'
})
export class IncisComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  public edit: boolean;

  @ViewChild(InciEditComponent) inciEdit: InciEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute,
      private inciService: InciService,
      private functionService: FunctionService,
      private valueService: ValueService) {
    super(spinner, notify, router, data);
    console.log('IncisComponent.create()');
  }

  ngOnInit() {
    console.log('IncisComponent.init()');
    this.route.params.subscribe(params => {
      this._show(params['key']);
      this.edit = params['action'] === 'edit';
    });
    this._loadAll();
  }

  canDeactivate() {
    return !this.inciEdit || this.inciEdit.canDeactivate();
  }

  _show(key: string) {
    console.log('IncisComponent.show()', 'show inci: ' + key);
    if (key) {
      this.inciService.getByKey(Number(key))
        .then(i => {
          this.data.incis.selected = i;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 0);
        });
    } else {
      this.data.incis.selected = null;
    }
  }

  private _loadAll() {
    console.log('IncisComponent._loadAll()');
    this.startSpinner();
    Promise.all([
      this.inciService.assertLoaded(),
      this.functionService.assertLoaded(),
      this.valueService.assertLoaded()
    ])
      .then(() => {
        this.data.incis.sort((a: Inci, b: Inci) => a.name.localeCompare(b.name));
      })
      .catch(err => {
          console.log('IncisComponent._loadAll()', 'Failed to load inci list: ', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
  }
}
