import { Observable } from 'rxjs';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';

export interface ComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<ComponentDeactivate> {

  canDeactivate(component: ComponentDeactivate,
           route: ActivatedRouteSnapshot,
           state: RouterStateSnapshot) {
    console.log('DeactivateGuard.canDeactivate()', 'Leaving route: ', state.url, component);
    return component.canDeactivate ? component.canDeactivate() : true;
  }

}
