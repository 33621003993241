import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService } from '../../common';
import { Docx } from '../../common/model';
import { DocxService } from '../../common/services';

@Component({
  selector: 'app-docx',
  templateUrl: './docxs.component.html'
})
export class DocxsComponent extends BaseMainpage implements OnInit {

  public edit: boolean;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute,
      private docxService: DocxService) {
    super(spinner, notify, router, data);
    console.log('DocxsComponent.create()');
  }

  ngOnInit() {
    console.log('DocxsComponent.init()');
    this.route.params.subscribe(params => {
      this._show(params['key']);
      this.edit = params['action'] === 'edit';
    });
    this._loadAll();
  }

  _show(key: string) {
    console.log('DocxsComponent.show()', 'show docx file: ' + key);
    if (key) {
      this.docxService.getByKey(Number(key))
        .then(t => {
          this.data.docxs.selected = t;
        });
    } else {
      this.data.docxs.selected = null;
    }
  }

  private _loadAll() {
    console.log('DocxsComponent._loadAll()');
    this.startSpinner();
    this.docxService.assertLoaded()
        .catch(err => {
            console.log('DocxsComponent._loadAll()', 'Failed to load docx file list: ', err);
        })
        .finally(() => {
          this.stopSpinner();
        });
  }
}
