import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { SpinnerService } from './spinner.service';
import { NotifyService } from './notify.service';

@Component({
  selector: 'app-main-page',
  template: '<div>ERROR: this template should never be visible!</div>'
})
export class BaseMainpage implements OnInit {

  constructor(
    private spinner: SpinnerService,
    private notify: NotifyService,
    public router: Router,
    public data: DataService) {
      (window.dermatest || {}).main = this;
    }

  ngOnInit() { }

  navigate(target: string) {
    this.router.navigate([target]);
  }

  startSpinner() {
    this.spinner.start();
  }

  stopSpinner() {
    this.spinner.stop();
  }

  isSpinnerActive() {
    return this.spinner.state().getValue();
  }

  notify_ok() {
    this.notify.ok();
  }

  notify_error(ctx, err) {
    console.error(ctx, err);
    this.notify.oops();
  }

  async(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(), 5);
    });
  }
}
