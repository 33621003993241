import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ComponentDeactivate } from '../../common';
import { CustomerService } from '../../common/services';
import { Customer } from 'src/app/common/model';
import { CustomerEditComponent } from './customer-edit.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html'
})
export class CustomersComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  public edit: boolean;

  @ViewChild(CustomerEditComponent) customerEdit: CustomerEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute,
      private customerService: CustomerService) {
    super(spinner, notify, router, data);
    console.log('CustomersComponent.create()');
  }

  ngOnInit() {
    console.log('CustomersComponent.init()');
    this.route.params.subscribe(params => {
      this._show(params['key']);
      this.edit = params['action'] === 'edit';
    });
    this._loadAll();
  }

  canDeactivate() {
    return !this.customerEdit || this.customerEdit.canDeactivate();
  }

  _show(key: string) {
    console.log('CustomerComponent._show()', 'show customer: ', key);
    if (key) {
      this.customerService.getByKey(Number(key))
        .then(c => {
          this.data.customers.selected = c;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 0);
        });
    } else {
      this.data.customers.selected = null;
    }
  }

  private _loadAll() {
    console.log('CustomersComponent._loadAll()');
    this.startSpinner();
    this.customerService.assertLoaded()
      .then(() => {
        this.data.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));
      })
      .catch(err => {
          console.log('CustomersComponent._loadAll()', 'Failed to load customer list: ', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
  }
}
