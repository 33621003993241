import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ComponentDeactivate } from '../../common';
import { TemplateService, DocxService, CustomerService, IngredientService, InciService } from '../../common/services';
import { Template } from 'src/app/common/model';
import { TemplateEditComponent } from './template-edit.component';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html'
})
export class TemplatesComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  public edit: boolean;

  @ViewChild(TemplateEditComponent) templateEdit: TemplateEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute,
      private docxService: DocxService,
      private customerService: CustomerService,
      private ingredientService: IngredientService,
      private inciService: InciService,
      private templatesService: TemplateService) {
    super(spinner, notify, router, data);
    console.log('TemplatesComponent.create()');
  }

  ngOnInit() {
    console.log('TemplatesComponent.init()');
    this.route.params.subscribe(params => {
      this._show(params['key']);
      this.edit = params['action'] === 'edit';
    });
    this._loadAll();
  }

  canDeactivate() {
    return !this.templateEdit || this.templateEdit.canDeactivate();
  }

  _show(key: string) {
    console.log('TemplatesComponent.show()', 'show template: ' + key);
    if (key) {
      this.templatesService.getByKey(Number(key))
        .then(t => {
          this.data.templates.selected = t;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 0);
        });
    } else {
      this.data.templates.selected = null;
    }
  }

  private _loadAll() {
    console.log('TemplatesComponent._loadAll()');
    this.startSpinner();
    Promise.all([
      this.docxService.assertLoaded(),
      this.customerService.assertLoaded(),
      this.ingredientService.assertLoaded(),
      this.inciService.assertLoaded(),
      this.templatesService.assertLoaded()
    ])
      .then(() => {
        this.data.templates.sort((a: Template, b: Template) => {
          let result = 0;
          result = a.name.localeCompare(b.name);
          if (result === 0 && a.label) {
            result = a.label.localeCompare(b.label);
          }
          if (result === 0 && a.inciName) {
            result = a.inciName.localeCompare(b.inciName);
          }
          if (result === 0 && a.language) {
            result = a.language.localeCompare(b.language);
          }
          return result;
        });
      })
      .catch(err => {
          console.log('TemplatesComponent._loadAll()', 'Failed to load template list: ', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
  }
}
