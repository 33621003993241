import { Constants } from './constants';

export class HtmlUtil {

  static NUM_DEFAULT_PRECISION = 3;
  static NUM_DEFAULT_SCIENTIFIC_THRSHOLD = 3;

  static parseAttributes(attrList: string): { [name: string]: string} {
    // Note: This RegEx recognizes HTML5 data-xzy attributes directly by their suffix name:
    // data-alias="foo" data-bar-baz="bar" -> { alias: 'foo', baz: 'bar' }
    const REGEX_ATTR: RegExp = /(\w+)="([^"]*)"/g;
    let m: RegExpExecArray;
    const attributes = {};
    while ((m = REGEX_ATTR.exec(attrList)) != null) {
      attributes[m[1]] = m[2];
    }
    return attributes;
  }

  static parseLocaleNumber(raw: string): number {
    if (raw != null) {
      raw = raw.trim();
      Constants.LANG.forEach(l => {
        if (l.key !== 'en') {
          const RE_number = RegExp('^\\d+' + l.decimal_separator +  '\\d+$');
          if (RE_number.test(raw)) {
            raw = raw.replace(l.decimal_separator, '.');
          }
        }
      });
    }
    return raw != null ? Number(raw) : Number.NaN;
  }

  static formatLocaleNumber(raw: string, language: string): string {
    if (raw != null) {
      raw = ('' + raw).trim();
      const targetLang = Constants.language(language);
      Constants.LANG.forEach(l => {
        if (l.key !== targetLang.key) {
          const RE_number = RegExp('^\\d+\\' + l.decimal_separator +  '\\d+(e[\\+\\-]?\\d+)?$');
          if (RE_number.test(raw)) {
            raw = raw.replace(l.decimal_separator, targetLang.decimal_separator);
          }
        }
      });
    }
    return raw;
  }

  static nl2br(value: string): string {
    if (!value) { return ''; }
    return ('' + value).trim().replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  static text2html(text: string): string {
    if (text) {
      [
        { re: /</gm, repl: () => '&lt;'},
        { re: />/gm, repl: () => '&gt;'},
        { re: /(?:\r\n|\r|\n)/gm, repl: () => '<br>'},
        { re: /\^(\w|\((\w+?)\))/gm, repl: (match, p1, p2) => '<sup>' + (p2 ? p1 : p1) + '</sup>' },
        { re: /_(\w+?)_/gm, repl: (match, p1) => '<sub>' + p1 + '</sub>'}
      ].forEach(item => {
        text = text.replace(item.re, item.repl);
      });
    }
    return text;
  }

  static num2roman(value: number): string {
    if (isNaN(value)) {
      return '' + value;
    }
    const digits = ('' + Math.round(Math.abs(Number(value)))).split('');
    const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
               '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
               '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
    let roman = '';
    let i = 3;
    while (i--) {
        roman = (key[+digits.pop() + (i * 10)] || '') + roman;
    }
    return Array(+digits.join('') + 1).join('M') + roman;
  }

  static num2txt(value: number, prec?: number, sciSmall?: number, sciLarge?: number): string {
    if (isNaN(value)) {
      return '' + value;
    }
    value = Number(value);
    if (Math.abs(value) === 0) {
      return '0';
    }

    const precision = Math.max(1, Math.round(Number(prec))) || HtmlUtil.NUM_DEFAULT_PRECISION;
    const sciSmallDecimals = Number(sciSmall) || (sciSmall == 0 ? 0 : HtmlUtil.NUM_DEFAULT_SCIENTIFIC_THRSHOLD);
    const sciLargeDecimals = Number(sciLarge) || (sciLarge == 0 ? 0 : sciSmallDecimals);

    if (10**(-sciSmallDecimals) <= Math.abs(value) && Math.abs(value) < 10**sciLargeDecimals) {
      // format to given precision, avoid scientific notation
      // trim trailing decimal zeros
      return HtmlUtil.num2decimal(value, precision);
    } else {
      return HtmlUtil.num2sci(value);
    }
  }

  static num2sci(value: number): string {
    if (isNaN(value)) {
      return '' + value;
    }
    return value.toExponential(HtmlUtil.NUM_DEFAULT_PRECISION-1);
  }

  static num2decimal(value: number, prec?: number): string {
    // This will still fall back to scientific notation.
    // However, it only does so for values smaller than 10^-6 or larger than 10^20.
    // That's OK for us, we would rather not use this format helper for values in these ranges. Or if so, than the scientific notation will be acceptable.
    const precision = Number(prec) || HtmlUtil.NUM_DEFAULT_PRECISION;
    return '' + HtmlUtil.num2prec(value, precision);
  }

  static num2prec(value: number, prec?: number): number {
    if (isNaN(value)) {
      return value;
    }
    const precision = Math.max(1, Math.round(Number(prec))) || HtmlUtil.NUM_DEFAULT_PRECISION;
    const decLimit = 10**(precision-1);

    // round values greater than 10^(precision)
    let factor = 1;
    if (value >= 10 * decLimit) {
      while (value >= 10 * decLimit) {
        value = value / 10;
        factor = factor * 10;
      }
      value = Math.round(value);
      return value * factor;
    }    

    // round values less than 10^(precision-1)
    factor = 1;
    if (value < decLimit) {
      while (value < decLimit) {
        value = value * 10;
        factor = factor * 10;
      }
    }
    value = Math.round(value);
    return value / factor;
  }

}
