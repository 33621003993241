<div class="container-fluid">
  <div class="row">
    <div class="col-md-2">
      <h2>Login</h2>
    </div>
    <main class="col-md-10" role="main"> <br />
      <div class="row">
        <div class="col-md-4 offset-md-2">
          <app-alert></app-alert>
  
          <form name="form" (ngSubmit)="f.form.valid && login()"
            #f="ngForm" novalidate>
            <div class="form-group"
              [ngClass]="{ 'has-error': f.submitted && !username.valid }">
              <label for="username">Username</label> <input type="text"
                class="form-control" name="username"
                [(ngModel)]="model.username" #username="ngModel" required />
              <div *ngIf="f.submitted && !username.valid"
                class="help-block">Username is required</div>
            </div>
            <div class="form-group"
              [ngClass]="{ 'has-error': f.submitted && !password.valid }">
              <label for="password">Password</label> <input
                type="password" class="form-control" name="password"
                [(ngModel)]="model.password" #password="ngModel" required />
              <div *ngIf="f.submitted && !password.valid"
                class="help-block">Password is required</div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary">Login</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</div>
