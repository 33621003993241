import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, ComponentDeactivate } from '../../common';
import { DataService, SpinnerService, NotifyService } from '../../common';
import { Customer } from '../../common/model';
import { CustomerEditComponent } from './customer-edit.component';

@Component({
  selector: 'app-customer-new',
  templateUrl: './customer-new.component.html'
})
export class CustomerNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(CustomerEditComponent, { static: true }) customerEdit: CustomerEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService) {
    super(spinner, notify, router, data);
    console.log('CustomerNewComponent.create()');
  }

  ngOnInit() {
    console.log('CustomerNewComponent.init()');
    this.data.customers.selected = new Customer();
    this.data.customers.selected.address.country = 'Deutschland';
  }

  canDeactivate() {
    return this.customerEdit.canDeactivate();
  }

}
