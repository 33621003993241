<div class="container-fluid">
  <div class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6"></app-sidenav>
    <main class="col-lg-9 col-md-8 col-sm-7 col-5" role="main">
      <div class="row">
        <div class="col-12">
          <h2>Template History: {{selected_template.name}}</h2>
        </div>
      </div>
      <app-alert></app-alert>

      <div class="container-fluid"> 
        <table class="table table-striped"><tbody>
          <tr>
            <th>Created</th>
            <th>User</th>
            <th class="text-center">New Page</th>
            <th>Label</th>
            <th>INCI</th>
            <th>Customer</th>
          </tr>
          <tr *ngFor="let template of history"
              class="entity" [ngClass]="{'table-active': selected_template.id==template.id}"
              (click)="select_template(template)">
            <td>{{template.createdAt | date: 'yyyy-MM-dd'}}</td>
            <td>{{template.createdBy}}</td>
            <td class="text-center"><input name="root" type="checkbox" disabled [(ngModel)]="template.properties.newPage"></td>
            <td>{{template.label}}</td>
            <td><span *ngFor="let i of data.incis.related(template)">{{i.name}}</span></td>
            <td><span *ngIf="template.customer">{{template.customer.name}}</span></td>
          </tr>
        </tbody></table>
      </div>

      <div class="container-fluid"> 
        <div class="row">
          <label class="col-md-12 col-form-label">Content:</label>
          <div class="col-md-12">
            <ace id="dataText" name="dataText" #editor
              [config]="editorOptions"
              [mode]="'html'"
              [theme]="'textmate'"
              [value]="selected_template.dataText"
              class="dt-form-control"></ace>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-md-12 col-form-label">Preview:</label>
          <div class="col-md-12"><div class="dt-form-control">
            <ckeditor name="ckeditor" [editor]="previewFactory" #preview
              [config]="previewConfig"
              [(ngModel)]="selected_template.dataText"
              [(data)]="selected_template.dataText"
              [disabled]="true"></ckeditor>
          </div></div>
        </div>
        <!-- Related Items: -->
        <div class="row">
          <div class="col-12 col-md-6 form-group" *ngIf="data.templates.related(selected_template, 'includedBy').length">
            <label>Included By:</label>
            <div>
              <a *ngFor="let t of data.templates.related(selected_template, 'includedBy')"
                  class="me-2 nav-link" [routerLink]="['/templates', t.key, 'history']">{{t.name}}<sub>{{t.relationIndicator(selected_template.language)}}</sub></a>
            </div>
          </div>
          <div class="col-12 col-md-6 form-group" *ngIf="data.templates.related(selected_template).length">
            <label>Similar Templates:</label>
            <div>
              <a *ngFor="let t of data.templates.related(selected_template)"
                  class="me-2 nav-link" [routerLink]="['/templates', t.key, 'history']">{{t.name}}<sub>{{t.relationIndicator(selected_template.language)}}</sub></a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
