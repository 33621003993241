import { Pipe, PipeTransform } from '@angular/core';
import { HtmlUtil } from '../html.util';

@Pipe({ name: 'text2html' })
export class HtmlPipe implements PipeTransform {
  transform(text: string): string {
    if (!text) { return ''; }
      return HtmlUtil.text2html(text);
  }
}
