import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ComponentDeactivate } from '../../common';
import { FunctionService } from '../../common/services';
import { Function } from 'src/app/common/model';
import { FunctionEditComponent } from './function-edit.component';

@Component({
  selector: 'app-functions',
  templateUrl: './functions.component.html'
})
export class FunctionsComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  public edit: boolean;

  @ViewChild(FunctionEditComponent) functionEdit: FunctionEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute,
      private functionService: FunctionService) {
    super(spinner, notify, router, data);
    console.log('FunctionsComponent.create()');
  }

  ngOnInit() {
    console.log('FunctionsComponent.init()');
    this.route.params.subscribe(params => {
      this._show(params['key']);
      this.edit = params['action'] === 'edit';
    });
    this._loadAll();
  }

  canDeactivate() {
    return !this.functionEdit || this.functionEdit.canDeactivate();
  }

  _show(key: string) {
    console.log('FunctionComponent._show()', 'show function: ', key);
    if (key) {
      this.functionService.getByKey(Number(key))
        .then(f => {
          this.data.functions.selected = f;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 0);
        });
    } else {
      this.data.functions.selected = null;
    }
  }

  private _loadAll() {
    console.log('FunctionsComponent._loadAll()');
    this.startSpinner();
    this.functionService.assertLoaded()
        .then(() => {
          this.data.functions.sort((a: Function, b: Function) => a.name.localeCompare(b.name));
        })
        .catch(err => {
            console.log('FunctionsComponent._loadAll()', 'Failed to load function list: ', err);
        })
        .finally(() => {
          this.stopSpinner();
        });
  }
}
