import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ConfirmComponent } from '../../common';
import { Customer } from '../../common/model';
import { CustomerService } from '../../common/services';

@Component({
  selector: 'app-customer-show',
  templateUrl: './customer-show.component.html'
})
export class CustomerShowComponent extends BaseMainpage implements OnInit {

  @Input() customer: Customer;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private customerService: CustomerService) {
    super(spinner, notify, router, data);
    console.log('CustomerShowComponent.create()');
  }

  ngOnInit() {
    console.log('CustomerShowComponent.init()');
  }

  delete() {
    this.confirm.open('Customer', 'Delete customer: ' + this.customer.name + ' ?', 'Delete')
      .then(() => {
        this.startSpinner();
        this.customerService.delete(this.customer)
          .then(() => {
            this.router.navigate(['/customers']);
          })
          .finally(() => {
            this.stopSpinner();
          });
      })
      .catch(() => { /* do nothing */ });
  }
}
