import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { AlertService } from '../alert.service';
import { User } from '../model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private alert: AlertService) { }

  login(username: string, password: string): Promise<User> {
    console.log('AuthenticationService.login():', 'Send login request for user: ' + username);
    return this.http
      .post<any>('/login', {
        login: username,
        pwd: password
      }, {
        observe: 'response',
        responseType: 'json'
      })
      .toPromise()
      .then(resp => {
        console.log('AuthenticationService.login():', 'Login request returned:', resp);
        // login successful if there's a jwt token in the response
        if (resp && resp.headers && resp.headers.get('Authorization')) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const jwt = resp.headers.get('Authorization');
            const user: User = resp.body;
            user.token = jwt;
            localStorage.setItem('currentUser', JSON.stringify(user));
            console.log('AuthenticationService.login():', 'Login successful:' + user.login);
            this.alert.success('Login successful', true);
            return user;
        } else {
            console.log('AuthenticationService.login():', 'Login failed. Authentication header field is missing');
            this.alert.error('Login Failed', true);
        }

        return null;
      })
      .catch(error => {
          console.log('AuthenticationService.login():', 'Login failed:', error);
          this.alert.error('Login Failed', true);
          return null;
      });
  }

  logout() {
    // remove user from local storage to log user out
    console.log('AuthenticationService.logout():', 'Force Logout');
    localStorage.removeItem('currentUser');
  }

  isLoggedIn() {
    return localStorage.getItem('currentUser') != null;
  }

  currentUser(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

}
