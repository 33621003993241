import { NgModule } from '@angular/core';
import {StickyThingDirective} from './sticky-thing.directive';


@NgModule({
  declarations: [
    StickyThingDirective,
  ],
  imports: [],
  exports: [
    StickyThingDirective,
  ]
})
export class LibModule { }
