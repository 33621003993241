import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: 'spinner.component.html'
})
export class SpinnerComponent implements OnInit {

  busy = false;
  label: string;

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.spinnerService.state().subscribe(flag => {
        this.busy = flag;
    });
  }

}
