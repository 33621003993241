<div class="w-100 d-inline-block dt-editable-label">
  <span *ngIf="!editMode" class="label">
    <span class="oi oi-pencil dt-edit-marker"></span>
    <span *ngIf="obj[field]" [innerHTML]="obj[field] | text2html" [ngbTooltip]="placeholder || ''" tooltipClass="dt-tooltip"></span>
    <span *ngIf="!obj[field]" class="dt-placeholder">{{placeholder || ''}}</span>
  </span>
  <span *ngIf="editMode" class="input">
    <input *ngIf="!multiline"
        #input name="{{name}}" type="text" [placeholder]="placeholder || ''"
        [(ngModel)]="obj[field]" appTrim (input)="changed()" (focusout)="finished()" 
        class="form-control">
    <textarea *ngIf="multiline" rows="4"
        #input name="{{name}}" type="text" [placeholder]="placeholder || ''"
        [(ngModel)]="obj[field]" appTrim (input)="changed()" (focusout)="finished()" 
        class="form-control"></textarea>
  </span>
</div>
