<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-1">
    </div>
    <div class="col-12 col-sm-11">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-9" >
          <span class="table-size float-end">{{data.reportsLight.list ? data.reportsLight.list.length : 0}}</span>
          <h2 *ngIf="!data.reportsLight.searchTerm"
              ngbTooltip="All reports created within last 30 days or not yet published" tooltipClass="dt-tooltip" placement="bottom-left">Recent Reports</h2>
          <h2 *ngIf="data.reportsLight.searchTerm"
              ngbTooltip="All Reports matching the search term" tooltipClass="dt-tooltip" placement="bottom-left">Selected Reports</h2>
        </div>
        <div class="col-12 col-md-4 col-lg-3 search-box" stickyThing>
          <input id="search" name="search" type="search" placeholder="Search" [(ngModel)]="data.reportsLight.searchTerm" (change)="search()" class="form-control"
          ngbTooltip="Matches title, customer, users, product or recipe" tooltipClass="dt-tooltip" placement="bottom">
        </div>
      </div>
      <app-alert></app-alert>

      <div class="row">
        <div class="col-12">
          <table class="table table-striped"><tbody>
            <tr>
              <th>Product</th>
              <th>Recipe</th>
              <th>Customer</th>
              <th>Study Number</th>
              <th>Language</th>
              <th>Author</th>
              <th>Change Date</th>
            </tr>
            <tr *ngFor="let report of data.reportsLight.list" class="entity" [ngClass]="{'dirty': report.dirty }"
                ngbTooltip="This report has unsaved local changes!" tooltipClass="dt-tooltip" container="body" [disableTooltip]="!report.dirty">
              <td><a class="btn btn-link text-start" [ngClass]="{'valid' : !!report.published }" [routerLink]="['/reports', report.key ]">{{report.productName}}</a></td>
              <td>{{report.recipeNumber ? report.recipeNumber : '--'}}</td>
              <td>{{report.customer ? report.customer.name : 'unknown'}}</td>
              <td>{{report.studyNumber ? report.studyNumber : '--'}}</td>
              <td>{{consts.language(report.language).label}}</td>
              <td>{{report.createdBy ? report.createdBy : 'unknown'}}</td>
              <td>{{report.createdAt | date: 'yyyy-MM-dd HH:mm' }}</td>
            </tr>
          </tbody></table>
        </div>
      </div>
    </div>
  </div>
</div>

