<div class="container-fluid">
  <div class="row">
    <div class="col-2"></div>
    <main class="col-8" role="main">
      <div class="row"><h2>Number Formatting Test</h2></div>
      <app-alert></app-alert>

      <div class="container-fluid"> 
        <div class="row">
          <p>
            Die Tabelle erlaubt, die Formatierung verschiedener numerischer Werte zu testen, abhängig von den Formatierungsvorgaben in den Tags der verschiedenen Templates.<br/>
            Außerhalb der Tabelle steht ein Feld ohne Formatierungsangabe. Dies stellt den Default für Datenfelder in den Templates dar.<br/>
            An einzelnen Stellen lassen sich dann spezifische Formatierungsvorgaben setzen, falls nötig. Dazu kann man zwei Parameter unabhängig voneinander wählen:
          </p>
          <ul>
            <li>
              <em>Präzision</em> oder <em>Anzahl signifikanter Stellen</em>: Sie gibt an, wie viele Stellen eines numerishcen Wertes tatsächlich aussagekräftig sind.
              Beispielsweise bei per Formel berechneten Werten mit periodischen Nachkommnstellen muss man an irgendeiner Stelle runden, weil alle weiteren Nachkommastellen
              komplett innerhalb des Intervalls der Messunsicherheiten liegen. Aber auch große Wert müssen ggf. auf z.B. Tausender gerundet werden, um nicht eine nicht 
              vorhandene Genauigkeit vorzugaukeln.<br/>
              Dieser Parameter greift nicht bei der Exponentialschreibweise. Dort werden grundsätzlich immer drei Stellen angezeigt: Eine vor und zwei hinter dem Komma.
            </li>
            <li>
              <em>Anzahl Dezmalstellen</em>: Gibt eine maximale Anzahl Dezimalstellen vor oder nach dem Komma an, die noch in normaler Dezimaldarstellung angezeigt werden.
              Alle Werte, die größer oder keiner sind, erscheinen in wissenschaftlicher Schreibweise mit Dezimal-Exponent.
            </li>
          </ul>
          <p>
            Beispiele für interessante Eingaben sind:
          </p>
          <ul>
            <li>Kleine Werte: 0,15555 / 0,0155555 / 0,001555 / 0,0001555 / 0,20001 / 0,00040006</li>
            <li>Große Werte: 12 / 123 / 1234 / 12345 / 1234567 </li>
            <li>Anzahl siginifikante Stellen: 12,3456789 / 123,456789 / 12345,6789 / 1234567,89 </li>
          </ul>
        </div>
        <div class="row form-group">
          <label class="col-md-12 col-form-label">Test Content:</label>
          <div class="col-md-12"><div class="dt-form-control">
            <ckeditor name="ckeditor" [editor]="previewFactory" #editor
              [config]="editorConfig"
              [(ngModel)]="testContent.dataText"
              [(data)]="testContent.dataText"
              [disabled]="false"></ckeditor>
          </div></div>
        </div>
        <div class="row">
          <!-- Actions: Freeze, Calculate, Re-Generate -->
          <div class="col-12 text-end">
            <button class="btn btn-light ms-1" (click)="recalculate()">
              <span class="oi oi-calculator"></span>
            </button>
          </div>
        </div>
        <div class="row">
          <h3>Beispiele für Tags mit Formatangaben:</h3>
        </div>
        <div class="row">
          <div>
<pre>&lt;x-field class="datafield" data-source="data.current_year" format="roman"&gt;&lt;/x-field&gt;
&lt;x-field class="caluclation" data-formula="24 * 60 * 60" format="num2txt:1"&gt;&lt;/x-field&gt;
&lt;x-field class="caluclation" data-formula="1 / 113" format="num2txt:4:6"&gt;&lt;/x-field&gt;
</pre>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
