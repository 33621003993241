import { Component, Input, ElementRef, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editable-label',
  templateUrl: 'editable-label.component.html'
})
export class EditableLabelComponent {

  @Input() obj: any;
  @Input() field: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() multiline = false;

  @Output() input: EventEmitter<any> = new EventEmitter();

  @ViewChild('input') inputEl: ElementRef;

  editMode = false;

  constructor(private el: ElementRef) { }

  @HostListener('click') onClick() {
    this.editMode = true;
    setTimeout(() => {
      this.inputEl.nativeElement.focus();
    }, 5);
  }

  changed() {
    this.input.emit(null);
  }

  finished() {
    this.editMode = false;
    const text = this.obj[this.field];
    if (text) {
      this.obj[this.field] = text.trim();
    }
  }

}
