import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive({
  selector: 'input[appFormIgnore]'
})
export class FormIgnoreDirective {

  @Input() form: NgForm;

  private dirtyState: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('focus') onFocus() {
    // console.log('FormIgnoreDirective.onFocus()', this.form.form.dirty);
    this.dirtyState = this.form.form.dirty;
  }

  @HostListener('input') onInput() {
    // console.log('FormIgnoreDirective.onInput()', this.dirtyState);
    this.dirtyState ? this.form.form.markAsDirty() : this.form.form.markAsPristine();
  }

  @HostListener('change') onChange() {
    // console.log('FormIgnoreDirective.onChange()', this.dirtyState);
    this.dirtyState ? this.form.form.markAsDirty() : this.form.form.markAsPristine();
  }

}
