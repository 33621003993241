<div class="w-100 d-inline-block dt-editable-label dt-selectable-label">
  <span *ngIf="!editMode" class="label">
    <span class="oi oi-pencil dt-edit-marker"></span>
    <span *ngIf="obj[field]" [innerHTML]="obj[field] | text2html" [ngbTooltip]="placeholder || ''" tooltipClass="dt-tooltip"></span>
    <span *ngIf="!obj[field]" class="dt-placeholder">{{placeholder || ''}}</span>
  </span>
  <span *ngIf="editMode" class="input">
    <select *ngIf="!multiline"
        #select name="{{name}}" [placeholder]="placeholder || ''"
        [(ngModel)]="obj[field]" (change)="changed()" (focusout)="finished()" 
        class="form-select">
        <ng-content></ng-content>
    </select>
  </span>
</div>
