import { Pipe, PipeTransform } from '@angular/core';
import { HtmlUtil } from '../html.util';

@Pipe({ name: 'num2txt' })
export class NumFormatPipe implements PipeTransform {
  transform(value: number, prec: number, decSmall: number, decLarge: number): string {
    if (isNaN(value)) { return '' + value; }
    if (value == 0) { return '0'; }
    return HtmlUtil.num2txt(value, prec, decSmall, decLarge);
  }
}
