import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbPopoverModule, NgbTypeaheadModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppRoutingModule } from '../app-routing.module';
import { LibModule } from '../lib/lib.module';
import { AppCommonsModule } from '../common/app-common.module';

import { SidenavComponent } from './sidenav.component';
import { ReportEditComponent } from './report-edit.component';

@NgModule({
  declarations: [
    ReportEditComponent,
    SidenavComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgbAccordionModule,
    NgbPopoverModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbTooltipModule,
    CKEditorModule,
    AppRoutingModule,
    LibModule,
    AppCommonsModule
  ]
})
export class ReportsModule { }
