import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DataService } from '../data.service';
import { FunctionService } from './function.service';
import { EntityStore } from './entity_store';
import { Inci, InciRoute, ValueSkeleton } from '../model';
import { Constants } from '../constants';

@Injectable()
export class InciService extends EntityStore<Inci> {

    constructor(
            http: HttpClient,
            dataService: DataService,
            functionService: FunctionService) {
        super(http, dataService.incis, '/api/incis', Inci);

        this._loadDependencies = () => {
            return functionService.assertLoaded();
        };

        this._preSave = (inci: Inci) => {
            inci = super._preSave(inci);
            inci.eukv.list = inci.eukv.list
                .map(n => {
                    n.appendix = n.appendix ? Number(n.appendix) : null;
                    return n;
                })
                .filter(n => n.appendix);

            return inci;
        };

        this._postRead = (inci: Inci) => {
            if (inci.functions) {
                inci.functions.forEach(inciFunction => {
                    functionService.getByKey(inciFunction.functionKey)
                        .then(f => {
                            inciFunction.ref = f;
                        });
                });
            }
            if (inci.routes) {
                inci.routes = inci.routes
                    .map(r => new InciRoute(r))
                    .sort((a, b) => {
                        const r_a = Constants.POD.ROUTES.resolve(a.route) || { sort: 100};
                        const r_b = Constants.POD.ROUTES.resolve(b.route) || { sort: 100};
                        return r_a.sort - r_b.sort;
                    });
                inci.routes.forEach(r => {
                    r.corrections.list = r.corrections.list
                        .map(c => new ValueSkeleton(c))
                });
            }
            return inci;
        };

        this._prepareTypeahedNames = () => {
            this.data.names.all = this.data.list.map(i => i.name);
        };
    }

    getByName(name: string): Promise<Inci> {
        return this.assertLoaded()
            .then(() => {
                return this.data.list.find(i => {
                    return name && (
                        i.name.toLocaleLowerCase() === name.toLocaleLowerCase() );
                });
            });
    }

}
