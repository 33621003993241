import { BaseEntity } from './base_entity';

export class UserJobTitle extends BaseEntity {

  constructor(source?: User) {
    super(source);
  }

  title: string;
  language: string;
  descr: string;
}

export class User extends BaseEntity {

  constructor(source?: User) {
    super(source);
    this.jobTitles = this.jobTitles || [];
  }

  login: string;
  title: string;
  givenName: string;
  surname: string;
  mail: string;
  jobTitle: string;

  admin: boolean;
  active: boolean;
  author: boolean;
  pwd: string;
  token: string;

  jobTitles: UserJobTitle[];

  getJobTitle(language: string): string {
    const title = this.jobTitles.find(l => l.language === language);
    return title ? title.title : this.jobTitle;
  }

  getJobTitleDescr(language: string): string {
    const lbl = this.jobTitles.find(l => l.language === language);
    return lbl ? lbl.descr : "";
  }
}
