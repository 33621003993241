import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, ComponentDeactivate, NotifyService } from '../../common';
import { Inci } from '../../common/model';
import { InciEditComponent } from './inci-edit.component';

@Component({
  selector: 'app-inci-new',
  templateUrl: './inci-new.component.html'
})
export class InciNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(InciEditComponent, { static: true }) inciEdit: InciEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService) {
    super(spinner, notify, router, data);
    console.log('InciNewComponent.create()');
  }

  ngOnInit() {
    console.log('InciNewComponent.init()');
    this.data.incis.selected = new Inci();
  }

  canDeactivate() {
    return this.inciEdit.canDeactivate();
  }
}
