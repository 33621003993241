import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ConfirmComponent } from '../common';
import { User } from '../common/model';
import { UserService } from '../common/services';

@Component({
  selector: 'app-user-show',
  templateUrl: './user-show.component.html'
})
export class UserShowComponent extends BaseMainpage implements OnInit {

  @Input() user: User;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private userService: UserService) {
    super(spinner, notify, router, data);
    console.log('UserShowComponent.create()');
  }

  ngOnInit() {
    console.log('UserShowComponent.init()');
  }

//   delete() {
//     this.confirm.open('User', 'Delete user: ' + this.user.login + ' ?', 'Delete')
//       .then(() => {
//         this.startSpinner();
//         this.userService.delete(this.user)
//           .then(() => {
//             this.router.navigate(['/users']);
//           })
//           .finally(() => {
//             this.stopSpinner();
//           });
//       })
//       .catch(() => { /* do nothing */ });
//   }
}
