import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AceComponent } from 'ngx-ace-wrapper';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

import { BaseMainpage, Constants, ConfirmComponent, ComponentDeactivate, BaseEditpage } from '../../common';
import { DataService, SpinnerService, AlertService, NotifyService  } from '../../common';
import { Template } from '../../common/model';
import { TemplateService } from '../../common/services';

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html'
})
export class TemplateEditComponent extends BaseMainpage implements OnInit, AfterViewInit, ComponentDeactivate {

  @Input() template: Template;

  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;
  @ViewChild('editor', { static: true }) editor: AceComponent;
  @ViewChild('preview', { static: true }) preview: CKEditorComponent;

  public languages = Constants.LANG;
  public previewFactory = InlineEditor;

  private editpage: BaseEditpage<Template> = null;

  public editorOptions = {
    minLines: 5,
    maxLines: 50,
    printMargin: false,
  };

  public previewConfig = {
    heading: {
      options: [
        { model: 'heading1', view: 'h1', title: 'Überschrift 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Überschrift 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Überschrift 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Überschrift 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Überschrift 5', class: 'ck-heading_heading5' },
        { model: 'heading6', view: 'h6', title: 'Überschrift 6', class: 'ck-heading_heading6' },
        { model: 'toc_heading', view: { name: 'div', classes: 'Überschrift_Inhalt_SIB' },
            title: 'Überschrift Inhalt', class: 'ck-heading_heading1 text-underlined' },
        { model: 'toc', view: { name: 'div', classes: 'TOC' }, title: 'Inhaltsverzeichnis', class: 'dt-toc' },
        { model: 'annotation', view: { name: 'div', classes: 'Annotation_SIB' }, title: 'Annotation', class: 'dt-annotation' },
        { model: 'sig_head', view: { name: 'div', classes: 'Signatur_Header_SIB' }, title: 'Signatur Name', class: 'dt-sig-head' },
        { model: 'sig_text', view: { name: 'div', classes: 'Signatur_SIB' }, title: 'Signatur Text', class: 'dt-sig-text' },
        { model: 'biblio', view: { name: 'div', classes: 'Literatur_SIB' }, title: 'Literatur', class: 'dt-biblio' },
        { model: 'quotation', view: { name: 'div', classes: 'dt-quotation' }, title: 'Quotation', class: 'ck-heading_paragraph' },
        { model: 'paragraph', title: 'Standard', class: 'ck-heading_paragraph' }
      ]
    }
  };

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private alertService: AlertService,
      private templateService: TemplateService) {
    super(spinner, notify, router, data);
    console.log('TemplateEditComponent.create()');
  }

  ngOnInit() {
    console.log('TemplateEditComponent.init()');
    if (this.data.templates.duplicatedEntity) {
      this.template = this.data.templates.duplicatedEntity;
      this.data.templates.duplicatedEntity = null;
    }
    this.editpage = new BaseEditpage(this, this.form, this.confirm, this.templateService, this.template);
    this.editpage._validate = () => this._validate();
    this.editpage._postCreate = (t: Template) => {
      this.router.navigate(['/templates', t.key]);
    };
    if (this.template.properties) {
      this.template.properties_s = JSON.stringify(this.template.properties);
    }
  }

  ngAfterViewInit() {
    if (this.editor) {
      this.editor.directiveRef.ace().getSession().setUseWrapMode(true);
      this.onEditorContentChange = () => {
        this.form.form.markAsDirty();
      }
    }
  }

  canDeactivate() {
    return this.editpage.canDeactivate();
  }

  onEditorContentChange() {
    /* will be replaced only after view init */
  }

  onPropsChange(ctrlId: string) {
    console.log('TemplateEditComponent.onPropsChange()', ctrlId);
    const propsEl = this.form.controls[ctrlId];
    if (propsEl) {
      try {
        this.template.properties = JSON.parse(propsEl.value);
        propsEl.setErrors(null);
        console.log('TemplateEditComponent.onPropsChange()', 'JSON parsed OK');
      } catch (err) {
        propsEl.setErrors({incorrect: true});
        console.error('TemplateEditComponent.onPropsChange()', 'JSON parsing failed', err);
      }
    } else {
      console.error('TemplateEditComponent.onPropsChange()', 'Control element not found: ', ctrlId);
    }
  }

  ingredientChanged() {
    this.template.ingredientKey = (this.template.ingredient && this.template.ingredient.key) ? this.template.ingredient.key : null;
  }

  customerChanged() {
    this.template.customerKey = (this.template.customer && this.template.customer.key) ? this.template.customer.key : null;
  }

  validateTemplate() {
    if (this.form.controls['template']) {
      this.form.controls['template'].updateValueAndValidity();
    }
    if (this.form.controls['document']) {
      this.form.controls['document'].updateValueAndValidity();
    }
  }

  save(): Promise<void> {
    return this.editpage.save();
  }

  searchInci: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const names = () => this.data.incis.names.all || [];
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : names()
          .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort((a, b) => a.length - b.length)
          .slice(0, 10))
    );
  }

  _validate(): boolean {
    this.alertService.clear();
    if (this.template.root && !this.template.documentKey) {
      this.alertService.warning('Root templates must reference a docx template document.');
      return false;
    }
    if (!this.template.root && !this.template.templateKey) {
      this.alertService.warning('Non-root templates must reference a root template.');
      return false;
    }
    return true;
  }
}
