<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{val.label}}</h3>

      <div class="row">
        <label class="col-12 col-md-2 col-form-label">Description:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{val.properties.text}}</span></div>
      </div>
      <div class="row">
        <label class="col-12 col-md-2 col-form-label">Factor:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{val.properties.factor | num2txt:2 }}</span></div>
      </div>
    </div>
    <div class="col-1">
      <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
        <a [routerLink]="['/values/correction', val.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
      </div>
      <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
        <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
      </div>
    </div>
  </div>
</div>
