import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Report, Role } from '../model';
import { DataService } from '../data.service';
import { CustomerService } from './customer.service';
import { UserService } from './user.service';
import { TemplateService } from './template.service';
import { EntityStore } from './entity_store';

@Injectable()
export class ReportLightService extends EntityStore<Report> {

    constructor(
        http: HttpClient,
        dataService: DataService,
        customerService: CustomerService,
        userService: UserService,
        templateService: TemplateService) {
            super(http, dataService.reportsLight, '/api/reportslight', Report);

            this._loadDependencies = () => {
                return Promise.all([
                    customerService.assertLoaded(),
                    templateService.assertLoaded(),
                    userService.assertLoaded()
                ]).then(() => null);
            };

            this._preSave = (report: Report) => {
                report = super._preSave(report);
                console.error('ReportLightService._preSave()', 'Modifying operations not allowed on light report stub instances.');
                throw new Error('Modifying operations not allowed on light report stub instances.');
            };

            this._postRead = (report: Report) => {
                report.data.ctx = report.data.ctx || {};
                if (report.customerId) {
                    customerService.getById(report.customerId)
                        .then(c => {
                            report.customer = c;
                        });
                }
                if (report.manufacturerId) {
                    customerService.getById(report.manufacturerId)
                        .then(m => {
                            report.manufacturer = m;
                        });
                }
                if (report.bottlerId) {
                    customerService.getById(report.bottlerId)
                        .then(b => {
                            report.bottler = b;
                        });
                }
                if (report.distributorId) {
                    customerService.getById(report.distributorId)
                        .then(d => {
                            report.distributor = d;
                        });
                }
                if (report.users) {
                    report.users.forEach((ru, idx) => {     // legacy support: sortRank might still be null
                        if (!ru.sortRank) {
                            ru.sortRank = idx+1;
                        }
                    })
                    report.users.sort((a, b) => a.sortRank - b.sortRank);
                    const ruAuthor = report.users
                        .find(ru => ru.role === Role.Author)
                    if (ruAuthor) {
                        userService.getById(ruAuthor.userId)
                            .then(u => userService.getByKey(u.key))
                            .then(u => {
                                report.author = u;
                            });
                    }
                    report.coAuthors = [];
                    report.users
                        .filter(ru => ru.role === Role.CoAuthor)
                        .forEach(ru => {
                            userService.getById(ru.userId)
                                .then(u => userService.getByKey(u.key))
                                .then(u => {
                                    report.coAuthors.push(u);
                                });
                            });
                }
                if (report.templateId) {
                    templateService.getById(report.templateId)
                        .then(t => {
                            report.template = t;
                        });
                }

                // well OK, but what if we have already loaded the full report object?
                const cachedReport = dataService.reports.findByKey(report.key);
                if (cachedReport) {
                    return cachedReport;
                }

                return report;
            };

        }

    search(query: string): Promise<void> {
        return this.loadAll(this.uriPrefix + '/search?q=' + query);
    }
}
