import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, ComponentDeactivate } from '../../../common';
import { DataService, SpinnerService, NotifyService } from '../../../common';
import { Value } from '../../../common/model';
import { CorrectionEditComponent } from './correction-edit.component';

@Component({
  selector: 'app-correction-new',
  templateUrl: './correction-new.component.html'
})
export class CorrectionNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(CorrectionEditComponent, { static: true }) valueEdit: CorrectionEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService) {
    super(spinner, notify, router, data);
    console.log('CorrectionNewComponent.create()');
  }

  ngOnInit() {
    console.log('CorrectionNewComponent.init()');
    this.data.values.selected = new Value();
    this.data.values.selected.context = 'inci';
    this.data.values.selected.type = 'route-correction';
  }

  canDeactivate() {
    return this.valueEdit.canDeactivate();
  }
}
