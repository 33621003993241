import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appMinValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true}]
})
export class MinValueValidatorDirective implements Validator {

  @Input('appMinValue') constraint: string;

  validate(control: AbstractControl): {[key: string]: any} | null {
    if (control) {
      if (this.constraint != null && this.constraint.length > 0) {
        const minVal = Number(this.constraint);
        if (!isNaN(minVal)) {
          const actVal = Number(control.value);
          return !isNaN(actVal) && minVal <= actVal ? null : { invalid: true };
        }
      } else {
        return control.value != null && '' + control.value !== '' ? null : { required: true };
      }
    }
    return null;
  }
}
