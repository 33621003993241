import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ComponentDeactivate } from '../../common';
import { Specification } from '../../common/model';
import { SpecificationEditComponent } from './specification-edit.component';

@Component({
  selector: 'app-specification-new',
  templateUrl: './specification-new.component.html'
})
export class SpecificationNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(SpecificationEditComponent, { static: true }) specificationEdit: SpecificationEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService) {
    super(spinner, notify, router, data);
    console.log('SpecificationNewComponent.create()');
  }

  ngOnInit() {
    console.log('SpecificationNewComponent.init()');
    this.data.specifications.selected = new Specification();
    this.data.specifications.selected.language = 'en';
    this.data.specifications.selected.sortRank = 1;
  }

  canDeactivate() {
    return this.specificationEdit.canDeactivate();
  }

}
