import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

    private busy: BehaviorSubject<boolean>;

    constructor() {
        this.busy = new BehaviorSubject<boolean>(false);
    }

    start(): void {
        console.log('SpinnerService.start');
        this.busy.next(true);
    }

    stop(offset = 0): void {
        setTimeout(() => {
            console.log('SpinnerService.stop');
            this.busy.next(false);
          }, offset);
    }

    state(): BehaviorSubject<boolean> {
        return this.busy;
    }
}
