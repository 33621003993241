import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-validation',
  templateUrl: 'validation.component.html'
})
export class ValidationHintComponent implements OnInit {

  @Input() message: string;

  constructor() { }

  ngOnInit() {}

}
