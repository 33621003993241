import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-notify',
  templateUrl: 'notify.component.html'
})
export class NotifyComponent implements OnInit {

  label: string;
  style: string;

  constructor(private notifyService: NotifyService) { }

  ngOnInit() {
    this.notifyService.label().subscribe(txt => {
      this.label = txt;
    });
    this.notifyService.style().subscribe(txt => {
      this.style = txt;
    });
  }

}
