import { BaseEntity } from './base_entity';
import { Customer } from './customer.model';
import { Ingredient } from './ingredient.model';

export class Template extends BaseEntity {

  constructor(source?: Template) {
    super(source);
    this.properties = this.properties || {};
  }

  root: boolean;

  name: string;
  label: string;
  section: string;
  language: string;
  description: string;

  documentKey: number;
  templateKey: number;
  customerKey: number;
  ingredientKey?: number;
  inciName?: string;

  properties: {
    docx?: {
      name: string;
      type: string;
      label: string;
      default?: any;
      min?: number;
    }[];
    autoFreeze?: boolean;
    newPage?: boolean;
  };

  dataText: string;

  // attributes not persisted
  rootTemplate: Template;
  customer: Customer;
  ingredient: Ingredient;
  properties_s: string;

  static fromTextblock(tb: any): Template {
    const t = new Template();
    t.root = false;
    t.name = tb.name;
    t.label = tb.label;
    t.language = tb.language;
    t.description = tb.description;
    t.dataText = tb.dataText;
    t.properties = tb.templateRef ? tb.templateRef.properties : {};
    t.rootTemplate = tb.templateRef ? tb.templateRef.rootTemplate : null;
    return t;
  }

  public relationIndicator(lang?: string): string {
    let s = [];
    s.push(!lang || lang !== this.language ? this.language : null);
    s = s.filter(x => x != null);
    return s.length ? '(' + s.join(', ') + ')' : null;
  }

  public parseTextblocks(): Array<any> {
    console.log('Template.parseTextblocks()');
    const result = [];
    if (this.dataText) {
      const re: RegExp = /^<dt-include name="(.+)"\/>$/gm;
      let match: RegExpExecArray = null;
      while (match = re.exec(this.dataText)) {
        result.push({
          name: match[1]
        });
      }
    }
    return result;
  }
}
