<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <form #f="ngForm" (submit)="save()">
    <br>
    <div class="row">
      <div class="col-11">
        <h3>{{ingredient.tradename ? ingredient.tradename : (ingredient.name ? ingredient.name : 'New Raw Material')}}</h3>
      </div>
      <div *ngIf="ingredient.id" class="col-1">
        <div class="form-group" ngbTooltip="Close Edit" tooltipClass="dt-tooltip">
          <a [routerLink]="['/ingredients', ingredient.key]" class="form-control btn"><span class="float-end oi oi-caret-top"></span></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 form-group"><label for="tradename">Trade Name*</label>
        <input id="tradename" name="tradename" type="text" [(ngModel)]="ingredient.tradename" #tradename="ngModel" required appTrim class="form-control">
        <div [hidden]="tradename.valid || tradename.pristine" class="alert alert-danger">Trade Name is required</div>
      </div>
      <div class="col-12 col-lg-6 form-group"><label for="name">Name / Customer's Internal Denomination</label>
        <input id="name" name="name" type="text" [(ngModel)]="ingredient.name" #name="ngModel" appTrim class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 form-group"><label for="manufacturer">Manufacturer</label>
        <input id="manufacturer" name="manufacturer" type="text" [(ngModel)]="ingredient.manufacturer" #manufacturer="ngModel" appTrim class="form-control">
      </div>
      <div class="col-12 col-lg-4 form-group"><label for="language">Language*:</label>
        <select id="lang" name="lang" [(ngModel)]="ingredient.language" #language="ngModel" required class="form-select" >
          <option *ngFor="let l of languages" value="{{l.key}}">{{l.label}}</option>
        </select>
      </div>
    </div>

    <div class="row row-center">
      <div class="col-12  col-lg-6 form-group form-check form-check-aligned">
        <input class="form-check-input" id="perfume" name="perfume" type="checkbox" [(ngModel)]="ingredient.perfume" #perfume="ngModel">
        <label class="form-check-label" for="perfume">Perfume</label>
      </div>
      <div class="col-12 col-lg-6 form-group" *ngIf="ingredient.perfume">
        <label for="codeNo">Code of Composition:</label>
        <input id="codeNo" name="codeNo" type="text" [(ngModel)]="ingredient.codeNo" #codeNo="ngModel" appTrim class="form-control">
      </div>
    </div>
    <div class="row row-center" *ngIf="ingredient.perfume">
      <div class="col-6 col-lg-3 form-group form-check form-check-aligned">
        <input class="form-check-input" id="allergenList" name="allergenList" type="checkbox" [(ngModel)]="ingredient.allergenList" #allergenList="ngModel">
        <label class="form-check-label" for="allergenList">Allergene List</label>
      </div>
      <div class="col-6 col-lg-3 form-group form-check form-check-aligned">
        <input class="form-check-input" id="perfumeSecurityReport" name="perfumeSecurityReport" type="checkbox" [(ngModel)]="ingredient.perfumeSecurityReport" #perfumeSecurityReport="ngModel">
        <label class="form-check-label" for="perfumeSecurityReport">Perfume Security Report</label>
      </div>
      <div class="col-6 col-lg-3 form-group form-check form-check-aligned">
        <input class="form-check-input" id="ifraCert" name="ifraCert" type="checkbox" [(ngModel)]="ingredient.ifraCert" #ifraCert="ngModel">
        <label class="form-check-label" for="ifraCert">IFRA Certificate</label>
      </div>
      <div class="col-6 col-lg-3 form-group"><label for="ifraCertRevision">IFRA-Test Revision:</label>
        <select id="ifraCertRevision" name="ifraCertRevision" [(ngModel)]="ingredient.ifraCertRevision" #ifraCertRevision="ngModel" class="form-select" >
          <option value="46">46</option>
          <option value="47">47</option>
          <option value="48">48</option>
          <option value="49">49</option>
          <option value="50">50</option>
          <option value="51">51</option>
        </select>
      </div>
    </div>

    <ng-template #tooltipProportions>only Min value: "4 %"<br/>Min, Max value: "4 - 5 %"<br/>only Max value: "< 4 %"</ng-template>
    <div class="row">
      <div class="col-12">INCI components*</div>
      <div class="col-12">
        <table class="table table-striped"><tbody>
          <tr>
            <th>Name</th>
            <th>Proportion (%)</th>
            <th></th>
          </tr>
          <tr *ngIf="ingredient.incis.length == 0">
            <td colspan="3" class="p-0">
              <div class="w-25 ng-invalid p-1 d-inline-block">&nbsp;</div>
              <div class="p-1 d-inline-block">-- List is empty --</div>
            </td>
          </tr>
          <tr *ngFor="let inci of ingredient.incis; index as i">
            <td>{{inci.ref ? inci.ref.name : ''}}</td>
            <td>
              <div class="form-inline" [ngbTooltip]="tooltipProportions" tooltipClass="dt-tooltip">
                <span class="mx-2">
                  <input id="{{inci.ref.name}}-min" name="{{inci.ref.name}}-min" type="number" step="any" max="100" #percentMin="ngModel" [(ngModel)]="inci.percentMin" appMultiRequire="{{inci.ref.name}}-max" class="form-control multiRequired" style="width:6rem">
                </span>
                -
                <span class="mx-2">
                  <input id="{{inci.ref.name}}-max" name="{{inci.ref.name}}-max" type="number" step="any" max="100" #percentMax="ngModel" [(ngModel)]="inci.percentMax" appMultiRequire="{{inci.ref.name}}-min" class="form-control multiRequired" style="width:6rem">
                </span>
                %
              </div>
              <div [hidden]="(percentMin.untouched || percentMin.valid) && (percentMax.untouched || percentMax.valid)" class="alert alert-danger">At least one proportion value is required.</div>
            </td>
            <td>
              <button type="button" (click)="removeInci(inci, $event)" class="btn"
                ngbTooltip="Remove INCI" tooltipClass="dt-tooltip">
              <span class="oi oi-minus"></span></button>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-inline"><div class="form-group w-100">
                <p class="input-group w-100">
                  <input name="inciName" type="text" [(ngModel)]="inciName" [ngbTypeahead]="searchInci" appToUpperCase appTrim class="form-control">
                  <span class="input-group-append" ngbTooltip="Add INCI" tooltipClass="dt-tooltip">
                    <button type="button" (click)="addInci($event)" class="btn"><span class="oi oi-plus"></span></button>
                  </span>
                </p>
              </div></div>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody></table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">Phys./Chem. Specifications</div>
      <div class="col-12">
        <table class="table table-striped"><tbody>
          <tr>
            <th>Name</th>
            <th class="text-center">Unit</th>
            <th>Value</th>
          </tr>
          <tr *ngFor="let spec of specs.list; index as i">
            <td>{{spec.ref.label}} <span *ngIf="spec.ref.supplement">(<span [innerHTML]="text2html(spec.ref.supplement)"></span>)</span></td>
            <td class="text-center" [innerHtml]="text2html(spec.ref.unit)"></td>
            <td><input id="value-{{i}}" name="value-{{i}}" type="text" [(ngModel)]="spec.value" appTrim class="form-control"></td>
          </tr>
        </tbody></table>
      </div>
    </div>

    <div class="row">
        <div class="col-lg-8 col-md-12 col-sm-12 form-group">
            <button class="btn btn-primary form-control">{{ingredient.id ? 'Update' : 'Create'}}</button>
        </div>
    </div>
  </form>
</div>
