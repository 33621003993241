import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, ComponentDeactivate } from '../../common';
import { DataService, SpinnerService, NotifyService } from '../../common';
import { Function } from '../../common/model';
import { FunctionEditComponent } from './function-edit.component';

@Component({
  selector: 'app-function-new',
  templateUrl: './function-new.component.html'
})
export class FunctionNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(FunctionEditComponent, { static: true }) functionEdit: FunctionEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService) {
    super(spinner, notify, router, data);
    console.log('FunctionNewComponent.create()');
  }

  ngOnInit() {
    console.log('FunctionNewComponent.init()');
    this.data.functions.selected = new Function();
    this.data.functions.selected.language = 'en';
  }

  canDeactivate() {
    return this.functionEdit.canDeactivate();
  }
}
