<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{fn.name}}</h3>

      <div class="row">
        <label class="col-12 col-md-2 col-form-label">Description:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{fn.descr}}</span></div>
      </div>
      <div class="row">
        <label class="col-12 col-md-2 col-form-label">Language:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{fn.language}}</span></div>
      </div>
    </div>
    <div class="col-1">
      <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
        <a [routerLink]="['/functions', fn.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
      </div>
      <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
        <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-2">Labels</div>
    <div class="col-12 col-md-10 col-lg-8">
      <table class="table table-striped w-100"><tbody>
        <tr>
          <th>Label</th>
          <th>Description</th>
          <th>Language</th>
        </tr>
        <tr *ngFor="let label of fn.labels; index as i">
          <td>{{label.label}}</td>
          <td>{{label.descr}}</td>
          <td>{{label.language}}</td>
        </tr>
      </tbody></table>
    </div>
  </div>
</div>
