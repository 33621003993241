import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, ComponentDeactivate, ConfirmComponent } from '../../common';
import { DataService, SpinnerService, NotifyService } from '../../common';
import { Template } from '../../common/model';
import { DocxService, CustomerService, IngredientService, InciService, TemplateService } from 'src/app/common/services';
import { TemplateEditComponent } from './template-edit.component';

@Component({
  selector: 'app-template-new',
  templateUrl: './template-new.component.html'
})
export class TemplateNewComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @ViewChild(TemplateEditComponent, { static: true }) templateEdit: TemplateEditComponent;
  @ViewChild('confirm') confirm: ConfirmComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private docxService: DocxService,
      private customerService: CustomerService,
      private ingredientService: IngredientService,
      private inciService: InciService,
      private templatesService: TemplateService) {
    super(spinner, notify, router, data);
    console.log('TemplateNewComponent.create()');
  }

  ngOnInit() {
    console.log('TemplateNewComponent.init()');
    this.data.templates.selected = new Template();
    this.data.templates.selected.language = 'de';
    this._loadAll();
  }

  canDeactivate() {
    return this.templateEdit.canDeactivate();
  }

  private _loadAll() {
    console.log('TemplateNewComponent._loadAll()');
    this.startSpinner();
    Promise.all([
      this.docxService.assertLoaded(),
      this.customerService.assertLoaded(),
      this.ingredientService.assertLoaded(),
      this.inciService.assertLoaded(),
      this.templatesService.assertLoaded()
    ])
      .catch(err => {
          console.log('TemplateNewComponent._loadAll()', 'Failed to load dependencies: ', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
  }

}
