import { Component } from '@angular/core';
import { AuthenticationService } from './common';
import { DataService } from './common';
import { Router } from '@angular/router';

declare global {
  interface Window { dermatest: any; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    public authService: AuthenticationService,
    public router: Router,
    public data: DataService) {
      window.dermatest = window.dermatest || {};
      window.dermatest.data = data;
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  navigate(target: string) {
    this.router.navigate([target]);
  }

  logout() {
    console.log('AppComponent.logout():', 'Request Logout');
    this.router.navigate(['/login'])
      .then(() => {
        this.authService.logout();
      });
  }

  isStaging() {
    return window.location.hostname.indexOf('staging') === 0;
  }
}
