<div class="container-fluid">
  <div class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6"></app-sidenav>
    <main class="col-lg-9 col-md-8 col-sm-7 col-5" role="main">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-9">
          <span class="table-size float-end">{{data.functions.search().length}}</span>
          <h2>Functions</h2>
        </div>
        <div class="col-12 col-md-4 col-lg-3 search-box" stickyThing>
          <input id="search" name="search" type="search" placeholder="Search" [(ngModel)]="data.functions.searchTerm" class="form-control">
        </div>
      </div>
      <app-alert></app-alert>

      <div class="container-fluid"> 
        <table class="table table-striped"><tbody>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Language</th>
          </tr>
          <tr *ngFor="let fn of data.functions.search()" class="entity" [ngClass]="{'table-active': data.functions.selected && fn.key==data.functions.selected.key}">
            <td [ngClass]="{'dirty': fn.dirty}"><span [ngbTooltip]="fn.dirty ? 'Function has unsaved changes' : ''" tooltipClass="dt-tooltip" container="body">
              <a class="btn btn-link text-start" [routerLink]="['/functions', fn.key, fn.dirty ? 'edit' : '' ]">{{fn.name}}</a>
            </span></td>
            <td>{{fn.descr}}</td>
            <td>{{fn.language}}</td>
          </tr>
        </tbody></table>
      </div>

      <div  *ngIf="data.functions.selected && !edit">
        <app-function-show [fn]="data.functions.selected"></app-function-show>
      </div>
      <div  *ngIf="data.functions.selected && edit">
        <app-function-edit [fn]="data.functions.selected"></app-function-edit>
      </div>
  
    </main>
  </div>
</div>
