<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <form #f="ngForm" (submit)="save()">
    <br>
    <div class="row">
      <div class="col-11">
        <h3>{{fn.name ? fn.name : 'New Function'}}</h3>
      </div>
      <div *ngIf="fn.id" class="col-1">
        <div class="form-group" ngbTooltip="Close Edit" tooltipClass="dt-tooltip">
          <a [routerLink]="['/functions', fn.key]" class="form-control btn"><span class="float-end oi oi-caret-top"></span></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 form-group"><label for="name">Name*</label>
        <input id="name" name="name" type="text" [(ngModel)]="fn.name" #name="ngModel" required appTrim class="form-control">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">Name is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12  col-lg-8 form-group">
          <label for="description">Description</label>
        <input id="description" name="description" type="text" [(ngModel)]="fn.descr" appTrim class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-3 col-lg-2"><label for="language">Language</label></div>
      <div class="col-9 col-lg-6 form-group">{{fn.language}}</div>
    </div>

    <div class="row">
      <div class="col-12">Labels</div>
      <div class="col-12">
        <table class="table table-striped"><tbody>
          <tr>
            <th>Label</th>
            <th>Description</th>
            <th>Language</th>
            <th></th>
          </tr>
          <tr *ngIf="fn.labels.length == 0">
            <td colspan="4" class="p-0">
              <div class="w-25 p-1 d-inline-block">&nbsp;</div>
              <div class="p-1 d-inline-block">-- List is empty --</div>
            </td>
          </tr>
          <tr *ngFor="let label of fn.labels; index as i">
            <td><app-editable-label name="label" [obj]="label" field="label" placeholder="Label" (input)="form.form.markAsDirty()"></app-editable-label></td>
            <td><app-editable-label name="label" [obj]="label" field="descr" placeholder="Description" (input)="form.form.markAsDirty()"></app-editable-label></td>
            <td>{{label.language}}</td>
            <td>
              <button type="button" (click)="removeLabel(label, $event)" class="btn" ngbTooltip="Remove Label" tooltipClass="dt-tooltip">
                <span class="oi oi-minus"></span>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <input name="newLabel" type="text" [(ngModel)]="newLabel" appTrim class="form-control">
                </p>
              </div>
            </td>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <input name="newDescr" type="text" [(ngModel)]="newDescr" appTrim class="form-control">
                </p>
              </div>
            </td>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <select id="lang" name="lang" [(ngModel)]="language" required class="form-select" >
                    <option *ngFor="let l of languages" value="{{l.key}}">{{l.label}}</option>
                  </select>
                </p>
              </div>
            </td>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <span class="input-group-append">
                    <button type="button" (click)="addLabel()" class="btn"><span class="oi oi-plus"></span></button>
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </tbody></table>
      </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-8 form-group">
            <button class="btn btn-primary form-control">{{fn.id ? 'Update' : 'Create'}}</button>
        </div>
    </div>

  </form>
</div>
