import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Textblock } from '../model';
import { DataService } from '../data.service';
import { EntityStore } from './entity_store';
import { TemplateService } from './template.service';

@Injectable()
export class TextblockService extends EntityStore<Textblock> {

    constructor(
        http: HttpClient,
        dataService: DataService,
        templateService: TemplateService) {
        super(http, dataService.textblocks, '/api/textblocks', Textblock);

        this._postRead = (textblock: Textblock): Textblock => {
            if (textblock.templateId) {
                templateService.getById(textblock.templateId).then(t => {
                    textblock.templateRef = t;
                });
            }
            return textblock;
        };
    }

}
