import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Specification } from '../model';
import { DataService } from '../data.service';
import { EntityStore } from './entity_store';

@Injectable()
export class SpecificationService extends EntityStore<Specification> {

    constructor(http: HttpClient, dataService: DataService) {
        super(http, dataService.specifications, '/api/specifications', Specification);

        this._prepareTypeahedNames = () => {
            this.data.names.all = this.data.list.map(s => s.label);
        };
    }

}
