import { BaseEntity } from './base_entity';

export class SpecificationLabel extends BaseEntity {

  constructor(source?: Specification) {
    super(source);
  }

  label: string;
  supplement: string;
  language: string;
  descr: string;
}

export class Specification extends BaseEntity {

  constructor(source?: Specification) {
    super(source);
    this.labels = this.labels || [];
  }

  label: string;
  supplement: string;
  unit: string;
  language: string;
  descr: string;
  sortRank: number;

  labels: SpecificationLabel[];

  getLabel(language: string): string {
    const lbl = this.labels.find(l => l.language === language);
    return lbl ? lbl.label : this.label;
  }

  getSupplement(language: string): string {
    const lbl = this.labels.find(l => l.language === language);
    return lbl ? lbl.supplement : this.supplement;
  }

  getDescr(language: string): string {
    const lbl = this.labels.find(l => l.language === language);
    return lbl ? lbl.descr : this.descr;
  }

}
