import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from '../app-routing.module';
import { LibModule } from '../lib/lib.module';
import { AppCommonsModule } from '../common/app-common.module';

import { SidenavComponent } from './sidenav.component';
import { UsersComponent } from './users.component';
import { UserShowComponent } from './user-show.component';
import { UserEditComponent } from './user-edit.component';
import { UserNewComponent } from './user-new.component';

@NgModule({
  declarations: [
    UsersComponent,
    UserEditComponent,
    UserShowComponent,
    UserNewComponent,
    SidenavComponent
  ],
  imports: [
      BrowserModule,
      FormsModule,
      AppRoutingModule,
      LibModule,
      AppCommonsModule,
    ]
})
export class UsersModule { }
