import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Docx } from '../model';
import { DataService } from '../data.service';
import { EntityStore } from './entity_store';

@Injectable()
export class DocxService extends EntityStore<Docx> {

    constructor(http: HttpClient, dataService: DataService) {
        super(http, dataService.docxs, '/api/documents', Docx);
    }

    download(docx: Docx): Promise<Blob> {
        const config = {
            headers: {
                'Accept': 'application/octet-stream,*/*;q=0.9'
            },
            responseType: 'blob' as 'blob'
        };
        return this.http.get('/api/documents/id/' + docx.id + '/data', config)
            .toPromise()
            .then(data => {
                return new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            });
    }

    upload(key: number, formData: FormData): Promise<Docx> {
        return this.http.put<Docx>('/api/documents/key/' + key + '/data', formData)
            .toPromise()
            .then(data => {
                return new Docx(data);
            });
    }
}
