import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrim]'
})
export class TrimDirective {

  constructor(private el: ElementRef) { }

  @HostListener('change') onChange() {
    this.trim();
  }

  private trim() {
    const text = this.el.nativeElement.value;
    if (text) {
      this.el.nativeElement.value = text.trim();
    }
  }
}
