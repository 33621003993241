import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { AceComponent } from 'ngx-ace-wrapper';

import { BaseMainpage, DataService, SpinnerService, NotifyService } from '../../common';
import { Template } from '../../common/model';
import { TemplateService } from '../../common/services';

@Component({
  selector: 'app-template-history',
  templateUrl: './template-history.component.html'
})
export class TemplateHistoryComponent extends BaseMainpage implements OnInit, AfterViewInit {

  @ViewChild('editor', { static: true }) editor: AceComponent;

  public selected_template: Template = new Template();
  public history: Array<Template> = [];

  public previewFactory = InlineEditor;

  public editorOptions = {
    minLines: 5,
    maxLines: 50,
    printMargin: false,
    readOnly: true,
  };

  public previewConfig = {
    heading: {
      options: [
        { model: 'heading1', view: 'h1', title: 'Überschrift 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Überschrift 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Überschrift 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Überschrift 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Überschrift 5', class: 'ck-heading_heading5' },
        { model: 'heading6', view: 'h6', title: 'Überschrift 6', class: 'ck-heading_heading6' },
        { model: 'toc_heading', view: { name: 'div', classes: 'Überschrift_Inhalt_SIB' },
            title: 'Überschrift Inhalt', class: 'ck-heading_heading1 text-underlined' },
        { model: 'toc', view: { name: 'div', classes: 'TOC' }, title: 'Inhaltsverzeichnis', class: 'dt-toc' },
        { model: 'annotation', view: { name: 'div', classes: 'Annotation_SIB' }, title: 'Annotation', class: 'dt-annotation' },
        { model: 'sig_head', view: { name: 'div', classes: 'Signatur_Header_SIB' }, title: 'Signatur Name', class: 'dt-sig-head' },
        { model: 'sig_text', view: { name: 'div', classes: 'Signatur_SIB' }, title: 'Signatur Text', class: 'dt-sig-text' },
        { model: 'biblio', view: { name: 'div', classes: 'Literatur_SIB' }, title: 'Literatur', class: 'dt-biblio' },
        { model: 'quotation', view: { name: 'div', classes: 'dt-quotation' }, title: 'Quotation', class: 'ck-heading_paragraph' },
        { model: 'paragraph', title: 'Standard', class: 'ck-heading_paragraph' }
      ]
    }
  };

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute,
      private templateService: TemplateService) {
    super(spinner, notify, router, data);
    console.log('TemplateHistoryComponent.create()');
  }

  ngOnInit() {
    console.log('TemplateHistoryComponent.init()');
    this.route.params.subscribe(params => {
      this._initTemplate(params['key'])
    });
  }

  private _initTemplate(key: string): Promise<void> {
    console.log('TemplateHistoryComponent._initTemplate()', key);
    return this.templateService.getHistory(+key)
        .then(l => {
          if (l && l.length) { return l; }
          return this.templateService.getByKey(+key).then(t => [t]);
        })
        .then(l => {
          this.history = l;
          this.selected_template = l[0];
        });
  }

  ngAfterViewInit() {
    if (this.editor) {
      this.editor.directiveRef.ace().getSession().setUseWrapMode(true);
    }
  }

  select_template(template: Template): void {
    this.selected_template = template;
  }
}
