<div class="container-fluid">
  <div class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6"></app-sidenav>
    <main class="col-lg-9 col-md-8 col-sm-7 col-5" role="main">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-9">
          <span class="table-size float-end">{{data.customers.search().length}}</span>
          <h2>Customers</h2>
        </div>
        <div class="col-12 col-md-4 col-lg-3 search-box" stickyThing>
          <input id="search" name="search" type="search" placeholder="Search" [(ngModel)]="data.customers.searchTerm" class="form-control">
        </div>
      </div>
      <app-alert></app-alert>

      <div class="container-fluid"> 
        <table class="table table-striped"><tbody>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>Country</th>
          </tr>
          <tr *ngFor="let customer of data.customers.search()" class="entity" [ngClass]="{'table-active': data.customers.selected && customer.key==data.customers.selected.key}">
            <td [ngClass]="{'dirty': customer.dirty}"><span [ngbTooltip]="customer.dirty ? 'Customer has unsaved changes' : ''" tooltipClass="dt-tooltip" container="body">
              <a class="btn btn-link text-start" [routerLink]="['/customers', customer.key, customer.dirty ? 'edit' : '' ]">{{customer.name}}</a>
            </span></td>
            <td>{{customer.address.city}}</td>
            <td>{{customer.address.country}}</td>
          </tr>
        </tbody></table>
      </div>

      <div  *ngIf="data.customers.selected && !edit">
        <app-customer-show [customer]="data.customers.selected"></app-customer-show>
      </div>
      <div  *ngIf="data.customers.selected && edit">
        <app-customer-edit [customer]="data.customers.selected"></app-customer-edit>
      </div>
  
    </main>
  </div>
</div>
