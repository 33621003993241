export class DataContext {

  data: any;
  rootData: any;

  constructor(private d: any) {
    this.data = d || {};
    this.rootData = this.data;
  }

  wrap(layer: any): DataContext {
    if (!layer) {
      return this;
    }
    const c = new DataContext(Object.create(this.data, {}));
    Object.assign(c.data, layer);
    c.rootData = this.rootData;
    return c;
  }

  unwrap(): any {
    return this.data.prototype;
  }

  /**
   * Resolves a property value within the data object. The path may contain dots to indicate properties of child object.
   * @param path [string] attribute path
   */
  get(path: string, ref?: any, silent?: boolean): any {
    if (!path || !path.trim()) {
      return null;
    }

    // split path into property names
    const props = path.split('.');
    if (ref == null || ref === undefined) {
      ref  = this.data;
    }
    for (let i = 0; i < props.length; i++) {
      let p = props[i];

      // propery names may contain dots, so check escapes: foo\.bar
      while (p.lastIndexOf('\\') === p.length - 1) {
        p = p.slice(0, p.length - 1) + '.' + props[++i];
      }

      // process path token
      let match: string[];
      if ((match = p.match(/^([\w_]+)\[(\d+)\]$/)) != null) {
        // check for array member references: foo[2]
        const r = this._get(match[1], ref, path);
        if (r !== undefined) {
          if (r[Number(match[2])] !== undefined) {
            ref = r[Number(match[2])];
            continue;
          }
        }
      } else if ((match = p.match(/^([\w_]+)\((.*)\)$/)) != null) {
        // check for method invocations: foo()
        // a optional method argument may be given by attribute path, dots must be replaced by '__': foo(path__to_attribute)
        const method = this._get(match[1], ref, path);
        const sArgs = match[2];
        if (method !== undefined) {
          let args = sArgs ? sArgs.split(',') : null;
          if (args) {
            args = args.map(a => {
              if ((match = a.trim().match(/(true|false)|([\d\.\+\-]+)|'(.*)'|(\w.*)/)) != null) {
                const a_bool = match[1];
                const a_num = match[2];
                const a_str = match[3];
                const a_path = match[4];
                if (a_bool) {
                  return a_bool === 'true';
                } else if (a_num) {
                  return Number(a_num);
                } else if (a_str) {
                  return a_str;
                } else if (a_path) {
                  return this.get(a_path.trim().replace('__', '.'));
                }
              }
              return a.trim();
            });
          }
          ref = method.apply(ref, args);
          continue;
        }
      } else {
        // plain property: foo
        ref = this._get(p, ref, path);
        if (ref !== undefined) {
          continue;
        }
      }

      // not found
      if (!silent) {
        console.warn('DataContext.get()', 'Unknown property in path: ', path, p); // , this.data
      }
      return null;
    }
    // console.debug('DataContext.get()', 'Resolve data field: ', path, ' -> ', ref);
    return ref;
  }

  _get(name: string, ref: any, path: string) {
    if (!ref) {
      console.warn('_get()', 'Reference object is null or undefined, can\'t get property "' + name + '". Path:', path);
      return undefined;
    }
    let v = ref[name];
    if (v === undefined && ref.data && ref.data.ctx) {
      v = ref.data.ctx[name];
    }
    return v;
  }

  isLocal(path: string): boolean {
    return this.get(path, null, true) != null && this.get(path, this.rootData, true) == null;
  }

  add(name: string, value: string | number): void {
    if (!this.rootData.data || !this.rootData.data.ctx) {
      console.error('DataContext.add()', 'rootData object does not contain member "data.ctx"', this.rootData);
    } else {
      this.rootData.data.ctx[name] = value;
    }
  }

  remove(name: string): void {
    if (!this.rootData.data || !this.rootData.data.ctx) {
      console.error('DataContext.add()', 'rootData object does not contain member "data.ctx"', this.rootData);
    } else {
      if (this.rootData.data.ctx[name] !== undefined) {
        delete this.rootData.data.ctx[name];
      }
    }
  }

  reset() {
    if (!this.rootData.data || !this.rootData.data.ctx) {
      console.error('DataContext.add()', 'rootData object does not contain member "data.ctx"', this.rootData);
    } else {
      this.rootData.data.ctx = {};
    }
  }

  /**
   * Evaluates if condition tests as used in tags <dt-if></dt-if> and <x-field class="dynamic"></x-field>
   * 
   * @param attr An object containing at least the properties 'prop' and 'test'
   */
  evalIfTest(attr: any): boolean {
    const prop = this.get(attr.prop);
    if (attr.test === undefined) {
      // simple truthy/falsy check
      return !!prop;
    } else {
      // evaluate test expression
      let match: string[];
      if ((match = attr.test.match( /^(not )?(eq|gt|ge|lt|le|like) ({{(.+)}}|'(.+)'|(\d+\.?\d*)|(true|false|null))?$/)) != null) {
        const negated = match[1] === 'not ';
        const op = match[2];
        let refProp = match[4];
        const refText = match[5];
        const refNum = match[6];
        const refToken = match[7];
        if (refProp) {
          refProp = this.get(refProp);
        }
        let ref: any = refProp;
        if (ref === null || ref === undefined) {
          ref = refText != null ? refText : null;
        }
        if (ref === null || ref === undefined) {
          ref = refNum != null ? Number(refNum) : null;
        }
        if (ref === null || ref === undefined) {
          if (refToken === 'true') {
            ref = true;
          } else if (refToken === 'false') {
            ref = false;
          } else if (refToken === 'null') {
            ref = null;
          }
        }
        if (ref === undefined) {
          ref = null;
        }
        // do comparision check
        let dropContent = true;
        switch (op) {
          case 'eq':
            if (prop === ref
              || (prop === undefined && ref === null)
              || (typeof ref === 'boolean' && !!prop === ref)
              || (typeof ref === 'number' && Number(prop) === ref)) {
              dropContent = false;
            }
            break;
          case 'gt':
            if (prop > ref) { dropContent = false; }
            break;
          case 'ge':
            if (prop >= ref) { dropContent = false; }
            break;
          case 'lt':
            if (prop < ref) { dropContent = false; }
            break;
          case 'le':
            if (prop <= ref) { dropContent = false; }
            break;
          case 'like':
            if (('' + prop).toLowerCase().indexOf(('' + ref).toLowerCase()) > -1) {
              dropContent = false;
            }
            break;
        }
        dropContent = negated ? !dropContent : dropContent;
        // drop tag content
        return !dropContent;
      } else {
        console.warn('DataContext.evalIfTest()',
          'Error in Template Processing: Attribute "test" is invalid.', attr);
        return null;
      }
    }
  }
}

