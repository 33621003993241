<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <form #f="ngForm" (submit)="save()">
    <br>
    <div class="row">
      <div class="col-11">
        <h3>{{spec.label ? spec.label : 'New Specification Parameter'}}</h3>
      </div>
      <div *ngIf="spec.id" class="col-1">
        <div class="form-group" ngbTooltip="Close Edit" tooltipClass="dt-tooltip">
          <a [routerLink]="['/specifications', spec.key]" class="form-control btn"><span class="float-end oi oi-caret-top"></span></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 form-group"><label for="name">Label*</label>
        <input id="label" name="label" type="text" [(ngModel)]="spec.label" #label="ngModel" required appTrim class="form-control">
        <div [hidden]="label.valid || label.pristine" class="alert alert-danger">Label is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-9  col-lg-6 form-group">
          <label for="supplement">Supplement</label>
        <input id="supplement" name="supplement" type="text" [(ngModel)]="spec.supplement" appTrim class="form-control">
        <small id="passwordHelpBlock" class="form-text text-muted">
          Formatting hint: log_n_ becomes log<sub>n</sub> / cm^3 becomes cm<sup>3</sup>
        </small>
      </div>
      <div class="col-3  col-lg-2 form-group">
          <label for="unit">Unit</label>
        <input id="unit" name="unit" type="text" [(ngModel)]="spec.unit" appTrim class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-12  col-lg-8 form-group">
          <label for="description">Description</label>
        <input id="description" name="description" type="text" [(ngModel)]="spec.descr" appTrim class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-12">Labels</div>
      <div class="col-12">
        <table class="table table-striped"><tbody>
          <tr>
            <th>Label</th>
            <th>Supplement</th>
            <th>Description</th>
            <th>Language</th>
            <th></th>
          </tr>
          <tr *ngIf="spec.labels.length == 0">
            <td colspan="5" class="p-0">
              <div class="w-25 p-1 d-inline-block">&nbsp;</div>
              <div class="p-1 d-inline-block">-- List is empty --</div>
            </td>
          </tr>
          <tr *ngFor="let label of spec.labels; index as i">
            <td><app-editable-label name="label" [obj]="label" field="label"  placeholder="label" (input)="form.form.markAsDirty()"></app-editable-label></td>
            <td><app-editable-label name="supplement" [obj]="label" field="supplement" placeholder="Supplement" (input)="form.form.markAsDirty()"></app-editable-label></td>
            <td><app-editable-label name="descr" [obj]="label" field="descr" placeholder="Label" (input)="form.form.markAsDirty()"></app-editable-label></td>
            <td>{{label.language}}</td>
            <td>
              <button type="button" (click)="removeLabel(label, $event)" class="btn" ngbTooltip="Remove Label" tooltipClass="dt-tooltip">
                <span class="oi oi-minus"></span>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <input name="newLabel" type="text" [(ngModel)]="newLabel" appTrim class="form-control">
                </p>
              </div>
            </td>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <input name="newSupplement" type="text" [(ngModel)]="newSupplement" appTrim class="form-control">
                </p>
              </div>
            </td>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <input name="newDescr" type="text" [(ngModel)]="newDescr" appTrim class="form-control">
                </p>
              </div>
            </td>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <select id="lang" name="lang" [(ngModel)]="language" required class="form-select" >
                    <option *ngFor="let l of languages" value="{{l.key}}">{{l.label}}</option>
                  </select>
                </p>
              </div>
            </td>
            <td>
              <div class="w-100 form-inline">
                <p class="w-100 input-group">
                  <span class="input-group-append">
                    <button type="button" (click)="addLabel()" class="btn"><span class="oi oi-plus"></span></button>
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </tbody></table>
      </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-8 form-group">
            <button class="btn btn-primary form-control">{{spec.id ? 'Update' : 'Create'}}</button>
        </div>
    </div>

  </form>
</div>
