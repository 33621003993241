import { Template, Textblock } from '../common/model';
import { TemplateService } from '../common/services';
import { TemplateTokenizer } from './template.tokenizer';

export class TemplateProcessor {

  rootBlock: Textblock;
  currentBlock: Textblock;
  mutedByIf = 0;
  loopBodyMode = 0;
  debugLabel = '';

  constructor(
    private templateService: TemplateService,
    private template: Template,
    private dataCtx?: any,
    private contextBlock?: Textblock,
    private doCollapse: boolean = false) {}

  startTemplate(): Promise<void> {
    this.rootBlock = Textblock.fromTemplate(this.template);
    if (this.loopBodyMode || this.mutedByIf) {
      // do nothing
    } else {
      // console.log('TemplateProcessor.startTemplate()', '-->', this.template.name,
      //  this.debugLabel, this.contextBlock ? this.contextBlock.debugLabel : '');
      this.rootBlock.dataCtx = this.dataCtx;
      this.currentBlock = this.rootBlock;
      if (this.contextBlock) {
        this.contextBlock.append(this.rootBlock);
      }
    }
    return null;
  }
  endTemplate(): Promise<void> {
    if (this.loopBodyMode || this.mutedByIf) {
      // do nothing
    } else {
      // console.log('TemplateProcessor.endTemplate()', '<--', this.template.name,
      //  this.debugLabel, this.contextBlock ? this.contextBlock.debugLabel : '');
      if (this.doCollapse) {
        this._collapseBlocks();
      }
    }
    return null;
  }

  doHtml(html: string): Promise<void> {
    if (this.loopBodyMode) {
      this._collectLoopBody(html);
    } else if (this.mutedByIf) {
      // do nothing
    } else {
      // console.log('TemplateProcessor.doHTML()', html);
      const tb = Textblock.fromHtml(this.template, html);
      this.currentBlock.append(tb);
      return null;
    }
  }

  startTag(name: string, attr: any): Promise<void> {
    if (this.mutedByIf) {
      if (name === 'dt-if') {
        this.mutedByIf++;
      }
      return null;
    }
    if (this.loopBodyMode) {
      // write start tag into dataText buffer and continue
      let sAttr = '';
      if (attr) {
        for (const p in attr) {
          if (attr.hasOwnProperty(p)) {
            sAttr += p + '="' + attr[p] + '" ';
          }
        }
      }
      this._collectLoopBody('<' + name + ' ' + sAttr + '>');
      if (name === 'dt-for') {
        this.loopBodyMode++;
      }
      return null;
    }

    // console.log('TextblockCollector.startTag()', name, attr);
    let tb: Textblock = null;
    switch (name) {

      // <dt-include name="foo">
      // <dt-include name="foo" collapse="true">
      // <dt-include name="foo" inci="{{inci.ref.name}}" fallback="bar">
      // <dt-include name="foo" customer="{{customer.key}}">
      // <dt-include name="foo" ingredient="{{ingredient.ref.key}}">
      case 'dt-include':
        if (attr.name) {
          tb = Textblock.fromTag(this.template, name, attr);
          tb.label = name;
          this.currentBlock.append(tb);
          return this._appendInclude(tb, attr);
        } else {
          console.warn('TemplateProcessor.startTag(' + name + ')',
            'Error in Template Processing: Attribute "name" missing on tag <dt-include> in template: (name:'
            + this.template.name + ' / id:' + this.template.id);
          console.warn('TemplateProcessor.startTag(' + name + ')', 'Fallback: Ignoring offending tag');
        }
        return null;

      // <dt-include-any id="unique_id" names="templ1,templ2,templ3" defaults="templ2">
      case 'dt-include-any':
        // check mandatory attributes
        ['id', 'names'].forEach(a => {
          if (!attr[a]) {
            console.warn('TemplateProcessor.startTag(' + name + ')',
              'Error in Template Processing: Attribute "' + a + '" missing on tag <dt-include-any> in template: (name:'
              + this.template.name + ' / id:' + this.template.id);
            console.warn('TemplateProcessor.startTag(' + name + ')', 'Fallback: Ignoring offending tag');
            return null;
          }
        });

        const selectionId = attr['id'];
        const templNames = (''+attr.names)
          .split(',')
          .map(n => n ? n.trim() : '')
          .filter(n => n.length);
        const templDefaults = !attr.defaults ? [] : (''+attr.defaults)
          .split(',')
          .map(n => n ? n.trim() : '')
          .filter(n => n.length);

          return Promise.all(templNames
            // find all available templates
            .map(n => {
              attr.name = n;
              return this._findInclude(attr);
            }))
          .then(templates => {
            return templates.filter(t => t != null);
          })
          .then(templates => {
            // create textblock: Will feed the selection UI, if neccessary
            tb = Textblock.fromTag(this.template, name, attr);
            tb.label = name;
            tb.properties.typeProps.id = selectionId;
            tb.properties.typeProps.names = templates.map(t => t.name);
            tb.properties.typeProps.labels = templates.map(t =>  t.label || t.name);
            tb.properties.typeProps.defaults = templDefaults;
            this.currentBlock.append(tb);
            return tb;
          })
          .then(tb => {
            // prepare global structure data: Will serve as ngModel for selection UI, if neccessary
            const rootCtx = tb.rootDataCtx();
            if (rootCtx == null) {
              console.warn('TemplateProcessor.startTag(' + name + ')', 'Root data context not found.');
              console.warn('TemplateProcessor.startTag(' + name + ')', 'Fallback: Ignoring offending tag');
              return null;
            }
            if (!rootCtx.data.structure[selectionId]) {
              rootCtx.data.structure[selectionId] = {};
              rootCtx.data.structure[selectionId]._confirmed_ = false;
              tb.properties.typeProps.names.forEach(n => {
                rootCtx.data.structure[selectionId][n] = tb.properties.typeProps.defaults.indexOf(n) > -1;
              });
            }

            if (rootCtx.data.structure[selectionId]._confirmed_) {
              // include selected templates
              tb.properties.typeProps.confirmed = true;
              return tb.properties.typeProps.names
                .filter(n => rootCtx.data.structure[selectionId][n])
                .reduce((p, n) => { // chain all _append calls together with .then() to force sequential execution
                  return p.then(() => {
                    attr.name = n;
                    return this._appendInclude(tb, attr);
                  })
                }, Promise.resolve())
                .then(() => {
                  rootCtx.data.structure[selectionId]._confirmed_ = false;
                });
            } else {
              // prepare for template selection UI: needs selection
              tb.properties.typeProps.confirmed = false;
            }
            return null;
          });

      // <dt-for source="some.array" name="item-name" index="index-name" sort="sort.attribute">
      case 'dt-for':
        tb = Textblock.fromTag(this.template, name, attr);
        tb.dataText = '';
        this.currentBlock.append(tb);
        // shift cursor one level down
        this.currentBlock = tb;
        // inc loop mode flag
        this.loopBodyMode++;
        return null;

      // <dt-collapse-blocks>
      case 'dt-collapse-blocks':
        // obsolete: do nothing
        return null;

      // <dt-if prop="foo">
      // <dt-if prop="foo" test="eq null">
      // <dt-if prop="foo" test="not eq 4">
      // <dt-if prop="foo" test="like 'token'">
      // <dt-if prop="foo" test="gt {{ref.to.some.number.ctx.property}}">
      // <dt-if list="report.ingredients" list_item="ingredient" aggr="exists" prop="ingredient.ref.name" test="like 'NANO'">
      case 'dt-if':
        tb = Textblock.fromTag(this.template, name, attr);
        this.currentBlock.append(tb);
        // shift cursor one level down
        this.currentBlock = tb;

        // validation on tag attributes
        if (attr.list) {
          if (!attr.list_item || !attr.aggr) {
            console.warn('TemplateProcessor.startTag(' + name + ')',
              'Error in Template Processing: Attribute "list_item" or "aggr" missing on tag <dt-if> in template: (name:'
              + this.template.name + ' / id:' + this.template.id);
            console.warn('TemplateProcessor.startTag(' + name + ')', 'Fallback: Ignoring offending tag');
            return null;
          }
          if (!['exists', 'all'].includes(attr.aggr)) {
            console.warn('TemplateProcessor.startTag(' + name + ')',
              'Error in Template Processing: Attribute "aggr" has invalid value', this.template.name, attr);
            console.warn('TemplateProcessor.startTag(' + name + ')', 'Fallback: Ignoring offending tag');
            return null;
          }
        }
        if (!attr.prop) {
          console.warn('TemplateProcessor.startTag(' + name + ')',
            'Error in Template Processing: Attribute "prop" missing on tag <dt-if> in template: (name:'
            + this.template.name + ' / id:' + this.template.id);
          console.warn('TemplateProcessor.startTag(' + name + ')', 'Fallback: Ignoring offending tag');
          return null;
        }

        if (attr.list) {
          // loop on items list and aggregate test evaluation results from any item
          let array = this.currentBlock.collectedDataCtx().get(attr.list);
          array = array.map((el) => {
            // prepare item in data context
            this.currentBlock.dataCtx = {};
            this.currentBlock.dataCtx[attr.list_item] = el;
            return this.currentBlock.collectedDataCtx().evalIfTest(attr);
          });
          if (array.indexOf(null) > -1) {
            console.warn('TemplateProcessor.startTag(' + name + ')', 'Aggregated if evaluation failed', this.template.name, attr);
            return null;
          }
          if (!array.reduce((sum, flag) => attr.aggr === 'all' ? (sum && flag) : (sum || flag), attr.aggr === 'all')) {
            this.mutedByIf++;
          }
        } else {
          // check simple if condition
          const doContent = this.currentBlock.collectedDataCtx().evalIfTest(attr);
          if (doContent != null) {
            if (!doContent) {
              this.mutedByIf++;
            }
          }
        }

        return null;

      // <dt-counter name="foo" init="0">
      // <dt-counter name="foo" inc="1">
      case 'dt-counter':
        tb = Textblock.fromTag(this.template, name, attr);
        this.currentBlock.append(tb);
        const ctx = tb.collectedDataCtx();
        if (attr.init !== undefined) {
          ctx.add(attr.name, Number(attr.init));
          this.currentBlock = tb;
        } else if (attr.inc !== undefined) {
          const incValue = Number(attr.inc);
          const prevValue = ctx.get(attr.name);
          if (prevValue != null) {
            ctx.add(attr.name, prevValue + incValue);
          } else {
            console.warn('TemplateProcessor.startTag(' + name + ')', 'Failed to increment counter: ', attr);
          }
        }
        return null;

      // <dt-ref soure="foo">
      case 'dt-ref':
        tb = Textblock.fromTag(this.template, name, attr);
        this.currentBlock.append(tb);
        // shift cursor one level down
        this.currentBlock = tb;
        return null;

    }

  }

  endTag(name: string): Promise<void> {
    if (this.mutedByIf && name === 'dt-if') {
      this.mutedByIf--;
    }
    if (this.mutedByIf) {
      return null;
    }
    if (this.loopBodyMode && name === 'dt-for') {
      this.loopBodyMode--;
    }
    if (this.loopBodyMode) {
      // write start tag into dataText buffer and continue
      this._collectLoopBody('</' + name + '>');
      return null;
    }

    // console.log('TemplateProcessor.endTag()', name);
    const attr = this.currentBlock.properties.typeProps ? (this.currentBlock.properties.typeProps.attributes || {}) : {};
    switch (name) {

      // </dt-for source="some.array" name="item-name">
      // </dt-for source="some.array" name="item-name" index="index-name" sort="sort.attribute.path">
      // </dt-for source="some.array" name="item-name" sort="desc sort.attribute.path1,sort.attribute.path2">
      case 'dt-for':
        console.log('TemplateProcessor.endTag(dt-for)', 'iterating loop body: ', this.currentBlock.dataText);
        this.currentBlock.childBlocks = [];
        // iterate given array
        if (attr.name && attr.source) {
          const dataCtx = this.currentBlock.collectedDataCtx();
          const array: Array<any> = dataCtx.get(attr.source);
          if (attr.sort) {
            console.log('TemplateProcessor.endTag(dt-for)', 'sort iteration: ', attr.sort);
            let sortPath = '' + attr.sort;
            let sortOrder = 1;
            if (sortPath.indexOf('desc ') === 0) {
              sortPath = sortPath.substr(5);
              sortOrder = -1;
            }
            const sortPaths = sortPath.split(',')
              .map(s => s.trim())
              .filter(s => s ? true : false);
            array.sort((a, b) => {
              return sortPaths.reduce((result, path) => {
                  if (result === 0) {
                    const sa = dataCtx.get(path, a);
                    const sb = dataCtx.get(path, b);
                    return sortOrder * (sb > sa ? -1 : (sb < sa ? 1 : 0));
                  }
                  return result;
              }, 0);
            });
          }
          array.forEach((el, idx) => {
            // add block for individual iteration
            const iteration = Textblock.fromIteration(this.template);
            this.currentBlock.append(iteration);
            // prepare property in data context
            iteration.dataCtx = {};
            iteration.dataCtx[attr.name] = el;
            if (attr.index) {
              iteration.dataCtx[attr.index] = idx;
            }
            iteration.debugLabel = attr.name + '/' + idx;
          });
          // parse loop body content on all iterations
          let p = Promise.resolve();
          this.currentBlock.childBlocks.map(iteration => {
            const t = new TemplateTokenizer(this.currentBlock.dataText);
            const c = new TemplateProcessor(this.templateService, this.template, null, iteration);
            p = p.then(() => t.process(c));
          });
          return p
            .finally(() => {
              // drop collected loop body content
              this.currentBlock.dataText = null;
              // shift cursor one level up
              this.currentBlock = this.currentBlock.parent;
              return null;
            });
        } else {
          console.warn('TemplateProcessor.endTag(dt-for)',
            'Error in Template Processing: Attribute "name" or "source" missing on tag <dt-for> in template: (name:'
            + this.template.name + ' / id:' + this.template.id);
          console.warn('TemplateProcessor.endTag(dt-for)', 'Fallback: Ignoring offending tag');
          // shift cursor one level up
          this.currentBlock = this.currentBlock.parent;
          return null;
        }

      // <dt-collapse-blocks>
      case 'dt-collapse-blocks':
        // obsolete: do nothing
        return null;

      // <dt-if>
      case 'dt-if':
        // shift cursor one level up
        this.currentBlock = this.currentBlock.parent;
        return null;

      // <dt-counter name="foo" init="0">
      // <dt-counter name="foo" inc="1">
      /**
       * The first variant places the variable (e.g. "foo") in the dataContext at processing time.
       * The second variant will increment this counter by the given value.
       */
      case 'dt-counter':
        if (attr.init !== undefined) {
          this.currentBlock.collectedDataCtx().remove(attr.name);
          // shift cursor one level up
          this.currentBlock = this.currentBlock.parent;
        }
        return null;

      // <dt-ref source="foo">
      /**
       * This tag is similar to <x-field class="datafield"/>, but it is evaluated immediately at processing time, not afterwards.
       * This allows, e.g. to count loop iterations with <dt-counter> and use the current iteration index by this tag.
       */
      case 'dt-ref':
        const valueTb = Textblock.fromHtml(this.template, '' + this.currentBlock.collectedDataCtx().get(attr.source));
        this.currentBlock.childBlocks = [valueTb];
        // shift cursor one level up
        this.currentBlock = this.currentBlock.parent;
        return null;
    }
  }

  private _collectLoopBody(text: string) {
    // console.log('', 'collect loop body: ', text);
    this.currentBlock.dataText += text;
  }

  private _findInclude(attr: any): Promise<Template | { name: string, label?: string }> {
    const rootTemplKey = this.template.root ? this.template.key : this.template.rootTemplate.key;
    const templName = attr.name;
    const fallbackName = attr.fallback ? attr.fallback : templName;
    const customerKey = attr.customer ? Number(this.currentBlock.resolveDataReference(attr.customer)) : null;
    const ingredientKey = attr.ingredient ? Number(this.currentBlock.resolveDataReference(attr.ingredient)) : null;
    const inciName = attr.inci ? this.currentBlock.resolveDataReference(attr.inci) : null;

    const paramCount = [customerKey, ingredientKey, inciName]
      .map(p => p != null && (''+p).length > 0 ? 1 : 0)
      .reduce((result, p) => result + p, 0)
    if (paramCount > 1) {
      return Promise.resolve(null);
    }

    return this.templateService.loadByName(
      rootTemplKey, this.currentBlock.language, templName,
      customerKey, ingredientKey, inciName
    )
      .then(list => {
        if (list.length === 1) {
          return list[0];
        } else if (list.length === 0) {
          // Try fallback
          if (fallbackName && (paramCount > 0 || fallbackName != templName)) {
            return this.templateService.loadByName(rootTemplKey, this.currentBlock.language, fallbackName, null, null, null)
              .then(list2 => {
                if (list2.length === 1) {
                  return list2[0];
                }
                return { name: templName, label: 'Template Error: ' + templName };
              });
          }
        }
        return { name: templName, label: 'Template Error: ' + templName };
      });
  }


  private _appendInclude(tb: Textblock, attr: any): Promise<void> {
    const rootTemplKey = this.template.root ? this.template.key : this.template.rootTemplate.key;
    const templName = attr.name;
    const fallbackName = attr.fallback ? attr.fallback : templName;
    const customerKey = attr.customer ? Number(this.currentBlock.resolveDataReference(attr.customer)) : null;
    const ingredientKey = attr.ingredient ? Number(this.currentBlock.resolveDataReference(attr.ingredient)) : null;
    const inciName = attr.inci ? this.currentBlock.resolveDataReference(attr.inci) : null;
    const doCollapse = attr.collapse ? true : false;

    const paramCount = [customerKey, ingredientKey, inciName]
      .map(p => p != null && (''+p).length > 0 ? 1 : 0)
      .reduce((result, p) => result + p, 0)
    if (paramCount > 1) {
      this._appendAlert_InvalidParams(tb, templName, null, attr, {
        customerKey: customerKey, ingredientKey: ingredientKey, inciName: inciName
      });
      return Promise.resolve();
    }

    return this.templateService.loadByName(
      rootTemplKey, this.currentBlock.language, templName,
      customerKey, ingredientKey, inciName
    )
      .then(list => {
        if (list.length === 1) {
          // OK
          tb.label = list[0].label;
          const t = new TemplateTokenizer(list[0].dataText);
          const c = new TemplateProcessor(this.templateService, list[0], null, tb, doCollapse);
          c.debugLabel = '' + (customerKey ? customerKey : '') + ' '
            + (ingredientKey ? ingredientKey : '') + ' '
            + (inciName ? inciName : '');
          return t.process(c);
        } else if (list.length === 0) {
          // Try fallback
          if (fallbackName && (paramCount > 0 || fallbackName != templName)) {
            console.log('TemplateProcessor._appendInclude()', 'No template found to include: ',
              this.currentBlock.language, templName, {
                customerKey: customerKey, ingredientKey: ingredientKey, inciName: inciName
              });
            console.log('TemplateProcessor._appendInclude()', 'Will try fallback search: ',
              this.currentBlock.language, fallbackName);
            return this.templateService.loadByName(rootTemplKey, this.currentBlock.language, fallbackName, null, null, null)
              .then(list2 => {
                if (list2.length === 1) {
                  // OK
                  tb.label = list2[0].label;
                  const t = new TemplateTokenizer(list2[0].dataText);
                  const c = new TemplateProcessor(this.templateService, list2[0], null, tb, doCollapse);
                  return t.process(c);
                } else if (list2.length === 0) {
                  // Error: Not found
                  this._appendAlert_NotFound(tb, templName, fallbackName, attr, {
                    customerKey: customerKey, ingredientKey: ingredientKey, inciName: inciName
                  });
                  return null;
                } else {
                  // Error: Multiple templates found
                  this._appendAlert_Multiple(tb, templName, fallbackName, attr, {
                    customerKey: customerKey, ingredientKey: ingredientKey, inciName: inciName
                  }, list2);
                  return null;
                }
              });
          } else {
            // Error: Not found
            this._appendAlert_NotFound(tb, templName, null, attr, {
              customerKey: customerKey, ingredientKey: ingredientKey, inciName: inciName
            });
            return null;
          }
        } else {
          // Error: Multiple templates found
          this._appendAlert_Multiple(tb, templName, null, attr,{
            customerKey: customerKey, ingredientKey: ingredientKey, inciName: inciName
          }, list);
          return null;
        }
      });
  }

  private _collapseBlocks() {
    console.log('TemplateProcessor._collapseBlocks()', 'collapse all child html blocks', this.currentBlock);
    // fetch content tree of for blocks within loop tag
    const blocks = this.currentBlock.childBlocks || [];
    this.currentBlock.childBlocks = [];
    // resolve all data fields within block list, data context would otherwise be lost
    blocks.forEach(tb => tb.resolveDatafields());
    blocks.forEach(tb => tb.resolveProperties());
    blocks.forEach(tb => tb.resolveCalculations());
    blocks.forEach(tb => tb.resolveDynamicBlocks());
    // concat all html fragments in child tree
    let html = '';
    let currentTemplate = null;
    blocks.forEach(tb => {
      tb.iteratePreorder(t => {
        if (t.needsIncludeSelection()) {
          // there's a block from a <dt-include-any> tag. We must keep it as is.
          this.currentBlock.append(new Textblock(t));
        }
        // otherwise: collect all html content
        if (t.isEditable()) {
          if (t.templateRef) {
            if (currentTemplate !== t.templateRef) {
              html += '<x-field class="context-ref"';
              html += 'data-name="' + t.templateRef.name + '" data-href="#/templates/' + t.templateRef.key + '">';
              html += '</x-field>';
              currentTemplate = t.templateRef;
            }
          } else {
            currentTemplate = null;
          }
          html = html + t.dataText;
        }
      });
    });
    if (!this.currentBlock.childBlocks.length) {  // aka: if no <dt-include-any> selection found
      this.doHtml(html);
    }
  }


  private _appendAlert_InvalidParams(tb: Textblock, templName: string, fallbackName: string, attr: any, params: object) {
    console.warn('TemplateProcessor._appendAlert_InvalidParams()', 'Template (' + this.currentBlock.language + ') not found to include:', 
      'name:', templName, ', fallback:', fallbackName, ' / params: ', params, ' / tag attributes: ', attr);

    const msgParams = params==null ? [] : Object.keys(params)
        .map(key => [ key, params[key] ])
        .filter(pair => pair[1] != null);

    let dataHTML = '<div><mark class="marker-pink">';
    dataHTML += '<p><b>TEMPLATE ERORR:</b> Reject template search for more than one additional parameters.<br/>';
    dataHTML += 'The current include tag requests to search for the parameter combination:</p>'
    dataHTML += '<ul>'
    msgParams.forEach(pair => {
      dataHTML += '<li>' + pair[0] + ': <em>' + pair[1] + '</em></li>'
    });
    dataHTML += '</ul>';
    dataHTML += '<p><b>HOW TO FIX IT:</b> Reduce the complexity of the &lt;' + tb.properties.typeProps.name + '&gt; tag.</p>',
    dataHTML += '</mark></div>',

    tb.append(Textblock.fromAlert(this.template, {
      name: templName,
      label: 'Empty Block (' + templName + ')',
      dataText: dataHTML
    } as Textblock));
  }

  private _appendAlert_NotFound(tb: Textblock, templName: string, fallbackName: string, attr: any, params: object) {
    console.warn('TemplateProcessor._appendAlert_NotFound()', 'Template (' + this.currentBlock.language + ') not found to include:', 
      'name:', templName, ', fallback:', fallbackName, ' / params: ', params, ' / tag attributes: ', attr);

    const rootTemplName = this.template.root ? this.template.name : this.template.rootTemplate.name;
    const msgParams = params==null ? [] : Object.keys(params)
        .map(key => [ key, params[key] ])
        .filter(pair => pair[1] != null);

    let dataHTML = '<div><mark class="marker-pink">';
    dataHTML += '<p><b>TEMPLATE ERORR:</b> No textblock found for this paragraph<br/>';
    dataHTML += 'Was expecting a template here using the key parameters:</p>'
    dataHTML += '<ul>'
    dataHTML += '<li>Language: <em>' + this.currentBlock.language + '</em></li>';
    dataHTML += '<li>Root Template: <em>' + rootTemplName + '</em></li>';
    dataHTML += '<li>Name: <em>' + templName + '</em></li>'
    msgParams.forEach(pair => {
      dataHTML += '<li>' + pair[0] + ': <em>' + pair[1] + '</em></li>'
    });
    dataHTML += '</ul>';
    if (fallbackName) {
      dataHTML += '<p>Even the fallback search failed for template with name: ' + fallbackName + '</p>'
    }
    dataHTML += '<p><b>HOW TO FIX IT:</b> Create the missing template.</p>',
    dataHTML += '</mark></div>',

    tb.append(Textblock.fromAlert(this.template, {
      name: templName,
      label: 'Empty Block (' + templName + ')',
      dataText: dataHTML
    } as Textblock));
  }

  private _appendAlert_Multiple(tb: Textblock, templName: string, fallbackName, attr: any, params: object, list: Template[]) {
    console.warn('TemplateProcessor._appendAlert_NotFound()', 'Template not found to include:', 
      'name:', templName, ', fallback:', fallbackName, ' / params: ', params, ' / tag attributes: ', attr);

    const rootTemplName = this.template.root ? this.template.name : this.template.rootTemplate.name;
    const msgParams = params==null ? [] : Object.keys(params)
        .map(key => [ key, params[key] ])
        .filter(pair => pair[1] != null);

    let dataHTML = '<div><mark class="marker-pink">';
    dataHTML += '<p><b>TEMPLATE ERORR:</b> Multiple textblocks found for this paragraph.</p>';

    if (fallbackName) { dataHTML += '<p>The initial search didn\'t return any template using the key parameters:</p>'; } 
    else { dataHTML += '<p>Was expecting only one template here using the key parameters:</p>'; }

    dataHTML += '<ul>';
    dataHTML += '<li>Language: <em>' + this.currentBlock.language + '</em></li>';
    dataHTML += '<li>Root Template: <em>' + rootTemplName + '</em></li>';
    dataHTML += '<li>Name: <em>' + templName + '</em></li>';
    msgParams.forEach(pair => {
      dataHTML += '<li>' + pair[0] + ': <em>' + pair[1] + '</em></li>';
    });
    dataHTML += '</ul>';

    if (fallbackName) { dataHTML += '<p>The subsequent fallback search returned a list of templates for name: ' + fallbackName + '.</p>'; } 
    else { dataHTML += '<p>The search returned a list of templates:</p>'; }

    dataHTML += '<ul>';
    list.forEach(t => {
      dataHTML += '<li><em>' + t.name + '</em> (key: ' + t.key + ') ' + '</li>';
    })
    dataHTML += '</ul>';
    dataHTML += '<p><b>HOW TO FIX IT:</b> Delete all but one of the duplicate templates.</p>';
    dataHTML += '</mark></div>';

    console.warn('TemplateProcessor._appendAlert_Multiple()', 'Multiple templates found to include: ', attr, list.map(b => b.label));
    tb.append(Textblock.fromAlert(this.template, {
      name: templName,
      label: 'Please choose variant ...',
      dataText: dataHTML,
    } as Textblock));
  }

}
