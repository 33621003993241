import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Function } from '../model';
import { DataService } from '../data.service';
import { EntityStore } from './entity_store';

@Injectable()
export class FunctionService extends EntityStore<Function> {

    constructor(http: HttpClient, dataService: DataService) {
        super(http, dataService.functions, '/api/functions', Function);

        this._prepareTypeahedNames = () => {
            this.data.names.all = this.data.list.map(f => f.name);
        };
    }

    getByName(name: string): Promise<Function> {
        return this.assertLoaded()
            .then(() => {
                return this.data.list.find(f => {
                    return f.name.toLocaleLowerCase() === name.toLocaleLowerCase();
                });
            });
    }

}
