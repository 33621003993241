<div class="container-fluid">
    <app-confirm #confirm></app-confirm>
    <form #f="ngForm" (submit)="save()">
      <br>
      <div class="row">
        <div class="col-11">
          <h3>{{user.surname || user.givenName ? ((user.givenName ? user.givenName : "") + " " + (user.surname ? user.surname : "")) : 'New User'}}</h3>
        </div>
        <div *ngIf="user.id" class="col-1">
          <div class="form-group" ngbTooltip="Close Edit" tooltipClass="dt-tooltip">
            <a [routerLink]="['/users', user.key]" class="form-control btn"><span class="float-end oi oi-caret-top"></span></a>
          </div>
        </div>
      </div>
  
      <div class="row" *ngIf="!user.id">
        <div class="col-12  col-lg-8 form-group">
          <label for="login">Login*</label>
          <input id="login" name="login" type="text" [(ngModel)]="user.login" #login="ngModel" required minlength="5" appTrim class="form-control">
          <div [hidden]="login.valid || login.pristine" class="alert alert-danger">Login is required, minimum length 5 characters.</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12  col-lg-8 form-group">
          <label for="title">Title</label>
          <input id="title" name="title" type="text" [(ngModel)]="user.title" appTrim class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-lg-4 form-group">
          <label for="givenName">Given Name*</label>
          <input id="givenName" name="givenName" type="text" [(ngModel)]="user.givenName" #givenName="ngModel" required appTrim class="form-control">
          <div [hidden]="givenName.valid || givenName.pristine" class="alert alert-danger">Given name is required</div>
        </div>
        <div class="col-6 col-lg-4 form-group">
          <label for="surname">Surname*</label>
          <input id="surname" name="surname" type="text" [(ngModel)]="user.surname" #surname="ngModel" required appTrim class="form-control">
          <div [hidden]="surname.valid || surname.pristine" class="alert alert-danger">Surname is required</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12  col-lg-8 form-group">
          <label for="mail">Mail*</label>
          <input id="mail" name="mail" type="text" [(ngModel)]="user.mail" #mail="ngModel" required appTrim class="form-control">
          <div [hidden]="mail.valid || mail.pristine" class="alert alert-danger">Mail address is required</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12  col-lg-8 form-group">
          <label for="jobTitle">Job Title (default: english)</label>
          <textarea rows=4 id="jobTitle" name="jobTitle" type="text" [(ngModel)]="user.jobTitle" #jobTitle="ngModel" appTrim class="form-control"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-lg-3 form-group form-check form-check-aligned">
          <input class="form-check-input" id="active" name="active" type="checkbox" [(ngModel)]="user.active" #author="ngModel">
          <label class="form-check-label" for="active">Active</label>
        </div>
        <div class="col-4 col-lg-3 form-group form-check form-check-aligned">
          <input class="form-check-input" id="author" name="author" type="checkbox" [(ngModel)]="user.author" #author="ngModel">
          <label class="form-check-label" for="author">Author</label>
        </div>
        <div class="col-4 col-lg-3 form-group form-check form-check-aligned">
          <input class="form-check-input" id="admin" name="admin" type="checkbox" [(ngModel)]="user.admin" #active="ngModel">
          <label class="form-check-label" for="admin">Admin</label>
        </div>
      </div>
      <div class="row" *ngIf="!user.id">
        <div class="col-12  col-lg-8 form-group">
          <label for="pwd">Initial Password*</label>
          <input id="pwd" name="pwd" type="text" [(ngModel)]="user.pwd" #pwd="ngModel" required minlength="8" appTrim class="form-control" #spy>
          <div [hidden]="pwd.valid || pwd.pristine" class="alert alert-danger">Password is required, minimum length 8 characters.</div>
        </div>
      </div>
      <div class="row" *ngIf="user.id && authService.currentUser() && authService.currentUser().admin">
        <div class="col-12  col-lg-8 form-group">
          <label for="pwd">Password</label>
          <input id="pwd" name="pwd" type="text" [(ngModel)]="user.pwd" #pwd="ngModel" minlength="8" appTrim class="form-control" #spy>
          <div [hidden]="pwd.valid || pwd.pristine" class="alert alert-danger">Minimum length 8 characters.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">Job Titles</div>
        <div class="col-12">
          <table class="table table-striped"><tbody>
            <tr>
              <th>Title</th>
              <th colspan="2">Language</th>
            </tr>
            <tr *ngIf="user.jobTitles.length == 0">
              <td colspan="2" class="p-0">
                <div class="w-25 p-1 d-inline-block">&nbsp;</div>
                <div class="p-1 d-inline-block">-- List is empty --</div>
              </td>
            </tr>
            <tr *ngFor="let jobTitle of user.jobTitles; index as i">
              <td>
                <app-editable-label name="title" [obj]="jobTitle" field="title" multiline="true"
                    placeholder="Job title"
                    (input)="form.form.markAsDirty()"></app-editable-label>
              </td>
              <td>{{jobTitle.language}}</td>
              <td>
                <button type="button" (click)="removeJobTitle(jobTitle, $event)" class="btn" ngbTooltip="Remove Job Title" tooltipClass="dt-tooltip">
                  <span class="oi oi-minus"></span>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="w-100 form-inline">
                  <p class="w-100 input-group">
                    <textarea rows=4 name="newJobTitle" type="text" [(ngModel)]="newJobTitle" appTrim class="form-control"></textarea>
                  </p>
                </div>
              </td>
              <td>
                <div class="w-100 form-inline">
                  <p class="w-100 input-group">
                    <select id="lang" name="lang" [(ngModel)]="language" required class="form-select" >
                      <option *ngFor="let l of languages" value="{{l.key}}">{{l.label}}</option>
                    </select>
                  </p>
                </div>
              </td>
              <td>
                <div class="w-100 form-inline">
                  <p class="w-100 input-group">
                    <span class="input-group-append">
                      <button type="button" (click)="addJobTitle()" class="btn"><span class="oi oi-plus"></span></button>
                    </span>
                  </p>
                </div>
              </td>
            </tr>
          </tbody></table>
        </div>
      </div>
      <div class="row">
          <div class="col-12 col-lg-8 form-group">
              <button class="btn btn-primary form-control">{{user.id ? 'Update' : 'Create'}}</button>
          </div>
      </div>
    </form>
  </div>
  