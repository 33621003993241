<!--The content below is only a placeholder and can be replaced.-->
<app-spinner></app-spinner>
<app-notify></app-notify>
<header [ngClass]="{'staging': isStaging()}">
  <div class="navbar-wrapper">
  <div class="navbar-sticky position-fixed">
    <nav class="navbar navbar-expand-lg navbar-light mb-3">
      <div class="navbar-brand" routerLink="">
        <span class="capital">S</span>afety <span class="capital">A</span>ssessment<br>
        <span class="capital">M</span>anagement <span class="capital">T</span>ool
      </div>
      <div class="navbar-logo"><span class="glyph-icon glyph-circle-filled" style="color:white">&#x2B24;</span><span class="text-muted">derma</span>test<sup>®</sup></div>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="collapse navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item"><a class="nav-link" *ngIf="data.activeReport && router.url.indexOf('/reports/new') === -1" [routerLink]="['/reports', data.activeReport.key ? data.activeReport.key : 'edit' ]">{{data.activeReport.productName}}</a></li>
        <li class="nav-item"><a class="nav-link" *ngIf="router.url.indexOf('/reports/new') === -1" [routerLink]="['/reports/new']">New Report</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/customers']">Settings</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/users']">Users</a></li>
        <li class="nav-item" *ngIf="isLoggedIn()"><a class="nav-link" (click)="logout()">Logout</a></li>
      </ul>
      </div>
    </nav>
  </div>
  </div>
</header>
<main [ngClass]="{'adminuser': authService.currentUser() && authService.currentUser().admin}">
  <router-outlet></router-outlet>
</main>
