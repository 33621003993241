<div class="container-fluid">
  <div class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6"></app-sidenav>
    <main class="col-lg-9 col-md-8 col-sm-7 col-5" role="main">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-6">
          <span class="table-size float-end">{{data.templates.search().length}}</span>
          <h2>Templates</h2>
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <select id="template" name="template" [(ngModel)]="data.templates.rootKeyFilter" class="form-select" >
            <option value="">-- All Documents --</option>
            <option *ngFor="let t of data.templates.roots()" value="{{t.key}}">{{t.name}}</option>
          </select>
        </div>
        <div class="col-6 col-md-4 col-lg-3 search-box" stickyThing>
          <input id="search" name="search" type="search" placeholder="Search" [(ngModel)]="data.templates.searchTerm" class="form-control">
        </div>
      </div>
      <app-alert></app-alert>

      <div class="container-fluid"> 
        <table class="table table-striped"><tbody>
          <tr>
            <th>Name</th>
            <th>Label</th>
            <th>Root</th>
            <th>Language</th>
            <th>INCI</th>
            <th>Customer</th>
            <th>Raw Material</th>
          </tr>
          <tr *ngFor="let template of data.templates.search()" class="entity" [ngClass]="{'table-active': data.templates.selected && template.key==data.templates.selected.key}">
            <td [ngClass]="{'dirty': template.dirty}"><span [ngbTooltip]="template.dirty ? 'Template has unsaved changes' : ''" tooltipClass="dt-tooltip" container="body">
              <a class="btn btn-link text-start" [routerLink]="['/templates', template.key, template.dirty ? 'edit' : '' ]">{{template.name}}</a>
            </span></td>
            <td>{{template.label}}</td>
            <td><input name="root" type="checkbox" disabled [(ngModel)]="template.root"></td>
            <td>{{template.language}}</td>
            <td>
              <a *ngFor="let i of data.incis.related(template)" [routerLink]="['/incis', i.key]" class="nav-link">{{i.name}}</a>
              <span *ngIf="data.incis.related(template).length == 0"
                  ngbTooltip="This INCI was not added yet." tooltipClass="dt-tooltip" container="body">{{template.inciName}}</span>
            </td>
            <td>
              <a *ngIf="template.customer" [routerLink]="['/customers', template.customer.key]" class="nav-link">{{template.customer.name}}</a>
              <span *ngIf="template.customerKey && !template.customer">Not found: {{template.customerKey}}</span>
            </td>
            <td>
              <a *ngIf="template.ingredient" [routerLink]="['/ingredients', template.ingredient.key]" class="nav-link">{{template.ingredient.getName()}}</a>
              <span *ngIf="template.ingredientKey && !template.ingredient">Not found: {{template.ingredientKey}}</span>
            </td>
          </tr>
        </tbody></table>
      </div>

      <div  *ngIf="data.templates.selected && !edit">
        <app-template-show [template]="data.templates.selected"></app-template-show>
      </div>
      <div  *ngIf="data.templates.selected && edit">
        <app-template-edit [template]="data.templates.selected"></app-template-edit>
      </div>

    </main>
  </div>
</div>
