<div *ngIf="activeModal" class="modal-header">
  <h4 class="modal-title">{{inci.name}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&nbsp;</span>
  </button>
</div>
<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <form #f="ngForm" (submit)="save()">
    <br>
    <div *ngIf="!activeModal" class="row">
      <div class="col-11">
        <h3>{{inci.name ? inci.name : 'New INCI Substance'}}</h3>
      </div>
      <div *ngIf="inci.id" class="col-1">
        <div class="form-group" ngbTooltip="Close Edit" tooltipClass="dt-tooltip">
          <a [routerLink]="['/incis', inci.key]" class="form-control btn"><span class="float-end oi oi-caret-top"></span></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 form-group"><label for="name">Declaration*</label>
        <input id="name" name="name" type="text" 
          [(ngModel)]="inci.name" #name="ngModel" 
          required appToUpperCase appTrim ngbAutofocus
          class="form-control">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">Name is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <label for="description">Description</label>
        <input id="description" name="description" type="text" [(ngModel)]="inci.description" appTrim class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-6 form-group">
        <label for="cas_no">CAS Number</label>
        <input id="cas_no" name="cas_no" type="text" [(ngModel)]="inci.casNo" appTrim  class="form-control">
      </div>
      <div class="col-6 form-group">
        <label for="einecs_no">EC/EINECS/ELINCS/NLP</label>
        <input id="einecs_no" name="einecs_no" type="text" [(ngModel)]="inci.einecsNo" appTrim  class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <label for="literature">Literature Reference (CIR)</label>
        <input id="literatureCir" name="literatureCir" type="text" [(ngModel)]="inci.literatureRefCir" appTrim  class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-12"><b>EUKV</b></div>
      <div class="col-8 form-group">
        <span *ngIf="!inci.eukv.list.length">-- No EUKV appendices declared --</span>
        <span *ngFor="let a of inci.eukv.list; index as i">
          <app-tag
              (cancel)="removeEukvAppendix(a)">
            <span>{{a.appendix | roman}}<sub>{{a.number}}</sub>: {{eukvLabels.label(a.appendix)}}</span>
          </app-tag>
        </span>
      </div>
      <div class="col-4 form-group">
        <div class="form-inline input-group">
          <select id="eukv_appendix" name="eukv_appendix"
              [(ngModel)]="eukvAppendix" #eukv_appendix="ngModel"
              class="form-select w-50" ngbTooltip="Appendix" tooltipClass="dt-tooltip">
            <option *ngFor="let o of eukvLabels.values" value="{{o.value}}">{{o.value | roman}}: {{o.label}}</option>
          </select>
          <input id="eukv_number" name="eukv_number" type="text"
              [(ngModel)]="eukvNumber" #eukv_number="ngModel" 
              appTrim class="form-control w-25" ngbTooltip="Number" tooltipClass="dt-tooltip">
          <button type="button" (click)="addEukvAppendix()" class="btn"><span class="oi oi-plus"></span></button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12"><b>Functions</b></div>
      <div class="col-8 form-group">
        <span *ngIf="!inci.functions.length">-- No functions declared --</span>
        <app-tag *ngFor="let fn of inci.functions; index as i"
            [label]="fn.ref ? fn.ref.name : '--'" [tooltip]="fn.default ? 'Default function' : ''"
            [ngClass]="{'dt-tag-primary': fn.default}"
            (cancel)="removeFunction(fn, $event)"></app-tag>
      </div>
      <div class="col-4 form-group">
        <div class="form-inline input-group">
          <input name="functionName" type="text" placeholder="Function Name"
              [(ngModel)]="functionName" [ngbTypeahead]="searchFunction"
              appToUpperCase class="form-control">
          <div class="input-group-text" ngbTooltip="Set 'default' flag on new function" tooltipClass="dt-tooltip">
            <input type="checkbox" id="functionDefault" name="functionDefault"
                [(ngModel)]="functionDefault"
                class="form-check-input">
          </div>
          <button type="button" (click)="addFunction()" class="btn"><span class="oi oi-plus"></span></button>
        </div>
      </div>
    </div>

    <div *ngIf="!activeModal" class="row">
      <div class="col-12">
        <table class="table table-striped"><tbody>
          <tr>
            <th class="w-10"><b>Exposition</b></th>
            <th></th>
          </tr>
          <tr *ngIf="inci.routes.length == 0">
            <td class="p-0" colspan="2">
              <div class="w-25 p-1 d-inline-block">&nbsp;</div>
              <div class="p-1 d-inline-block">-- List is empty --</div>
            </td>
          </tr>
          <tr *ngFor="let r of inci.routes; index as i">
            <td class="w-10">
              <div class="mt-1 form-group">
                <select id="route_{{i}}" name="route_{{i}}"
                    [(ngModel)]="r.route" #route="ngModel"
                    required class="form-select"
                    (change)="routeSelectionChanged(r)">
                  <option *ngFor="let ri of routeInfos()" [value]="ri.value">{{ri.label}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="defaultRoute_{{i}}">Default:</label>
                <input type="radio" id="defaultRoute_{{i}}" name="defaultRoute_{{i}}" value="{{r.route}}" [(ngModel)]="defaultRoute" class="ms-2">
              </div>
              <div class="mt-5" ngbTooltip="Remove this route" tooltipClass="dt-tooltip">
                <button type="button" (click)="removeRoute(i)" class="btn">
                  <span class="oi oi-minus"></span>
                </button>
              </div>
              <div class="mt-5" ngbTooltip="Clone this route" tooltipClass="dt-tooltip">
                <button type="button" (click)="cloneRoute(i)" class="btn">
                  <span class="oi oi-layers"></span>
                </button>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col-12"><b>Absorption:</b></div>
                <div class="col-4 form-group">
                  <div class="form-inline input-group">
                    <input id="route_absorption_{{i}}" name="route_absorption_{{i}}" type="number" [(ngModel)]="r.absorption"
                        min="0" required placeholder="Absorption" class="form-control"
                        [ngbTooltip]="r.absorption ? 'Absorption' : ''" tooltipClass="dt-tooltip">
                    <select id="absorption_unit_{{i}}" name="absorption_unit_{{i}}"
                      [(ngModel)]="r.absorptionUnit" #absorption_unit="ngModel"
                      class="form-select" ngbTooltip="Absorption Unit" tooltipClass="dt-tooltip">
                      <option *ngFor="let pu of POD.ABSORPTION_UNITS.values" value="{{pu.name}}">{{pu.label}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-8 form-group">
                  <app-editable-label name="label" [obj]="r" field="literatureRefAbsorption" placeholder="Literature Reference (Absorption)" (input)="changed()"></app-editable-label>
                </div>
              </div>

              <div class="row" *ngIf="r.absorptionUnit != '%'">
                <div class="col-4 form-group">
                  <div class="form-inline">
                    <input id="route_dose_{{i}}" name="route_dose_{{i}}" type="number" [(ngModel)]="r.dose"
                        min="0" required placeholder="Dose" class="form-control"
                        [ngbTooltip]="r.dose ? 'Dose' : ''" tooltipClass="dt-tooltip">
                    <span class="unit">µg/cm<sup>2</sup></span>
                  </div>
                </div>

                <div class="col-4 form-group">
                  <span class="operator">=></span>
                  <span class="value" title="Absorption">{{ r.absorptionPercent | num2txt:5 }}</span>
                  <span class="unit">%</span>
                </div>
              </div>

              <div class="row">
                <div class="col-12"><b>Reference:</b></div>
                <div class="col-2 form-group">
                  <input id="route_type_{{i}}" name="route_type_{{i}}" type="text" placeholder="Value type" [(ngModel)]="r.type" [ngbTypeahead]="routeValueTypes" required class="form-control">
                </div>
                <div class="col-10 form-group">
                  <app-editable-label name="label" [obj]="r" field="literatureRef" placeholder="Literature Reference (PoD)" (input)="changed()"></app-editable-label>
                </div>
              </div>
              <div class="row">
                <div class="col-2 form-group">
                  <app-editable-label class="w-50" name="label" [obj]="r" field="animal" placeholder="Animal" (input)="changed()"></app-editable-label>
                </div>
                <div class="col-2 form-group">
                  <app-editable-label class="w-50" name="label" [obj]="r" field="duration" placeholder="Duration" (input)="changed()"></app-editable-label>
                </div>
                <div class="col-2 form-group">
                  <app-selectable-label class="w-50" name="label2" [obj]="r" field="testedRoute" placeholder="Tested Route" (change)="changed()">
                    <option value="">-- default: {{r.route}} --</option>
                    <option *ngFor="let ri of routeInfos()" [value]="ri.value">{{ri.label}}</option>
                    <option value="inject">Injection</option>
                  </app-selectable-label>
                </div>
                <div class="col-6 form-group">
                  <app-editable-label name="label" [obj]="r" field="descr" placeholder="Description" (input)="changed()"></app-editable-label>
                </div>
              </div>

              <div class="row">
                <div class="col-12 form-group">
                  <div class="d-flex row-center">
                    <div class="">
                      <div><b>Value:</b></div>
                      <div>
                        <input id="route_value_{{i}}" name="route_value_{{i}}" type="number" [(ngModel)]="r.value"
                            min="0" appNormalize="decimal" placeholder="Value" required class="form-control"
                            [ngbTooltip]="r.value ? r.type + ' Value' : ''" tooltipClass="dt-tooltip">
                      </div>
                    </div>
                    <div class="">
                      <div><b>&nbsp;</b></div>
                      <div><span class="unit">mg/kg<sub>bw</sub>/d</span><span class="operator">/</span></div>
                    </div>

                    <div class=" ms-1">
                      <div><b>Factor:</b></div>
                      <div>
                        <input id="route_factor_{{i}}" name="route_factor_{{i}}" type="number" [(ngModel)]="r.factor"
                            min="0" appNormalize="decimal" placeholder="Factor" required class="form-control"
                            [ngbTooltip]="r.factor ? 'Factor' : ''" tooltipClass="dt-tooltip">
                      </div>
                    </div>
    
                    <div class="">
                      <div><b>&nbsp;</b></div>
                      <div><span class="operator">=</span></div>
                    </div>

                    <div>
                      <div class="ms-2"><b>PoD<sub>sys</sub>:</b></div>
                      <div>
                        <span class="value">{{r.pod | num2txt:5 }}</span>
                      </div>
                    </div>

                    <div class="">
                      <div><b>&nbsp;</b></div>
                      <div><span class="unit">mg/kg<sub>bw</sub>/d</span></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12"><b>Corrections:</b></div>
                <div class="col-9 form-group">
                  <span *ngIf="!(r.corrections.list && r.corrections.list.length)">-- No factor corrections --</span>
                  <app-tag *ngFor="let c of r.corrections.list" [label]="c.label" [tooltip]="c.properties.text" (cancel)="removeCorrection(r, c)"></app-tag>
                </div>
                <div class="col-3 form-group">
                  <div class="form-inline input-group">
                    <input id="route_corr_{{i}}" name="route_corr_{{i}}" type="text" [(ngModel)]="r._correctionText"
                        [ngbTypeahead]="searchCorrections" [inputFormatter]="correctionFormatter" [resultFormatter]="correctionFormatter" (selectItem)="selectCorrection(r, $event)"
                        placeholder="Factor correction" appTrim class="form-control">
                    <span class="input-group-append" 
                        ngbTooltip="Add correction" tooltipClass="dt-tooltip" container="body">
                        <button type="button" (click)="addCorrection(r)" class="btn"><span class="oi oi-plus"></span></button>
                    </span>
                  </div>
                </div>
              </div>
          </tr>
          <tr>
            <th class="w-10 text-center">
              <span class="oi oi-plus" (click)="addRoute()" ngbTooltip="Add permation route" tooltipClass="dt-tooltip"></span>
            </th>
            <th></th>
          </tr>
        </table>
      </div>
    </div>

    <div class="row">
        <div class="col-12 form-group">
            <button class="btn btn-primary form-control">{{inci.id ? 'Update' : 'Create'}}</button>
        </div>
    </div>
  </form>
</div>
