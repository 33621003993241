import { Ingredient } from './ingredient.model';

export class IngredientAlternative {
    ingredientId: number;

    ref: Ingredient;

    constructor(source?: IngredientAlternative) {
        if (source) {
            Object.assign(this, source);
        }
    }

}
