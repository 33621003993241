<div class="container-fluid">
  <div class="row">
    <app-sidenav class="col-lg-2 col-md-3 col-sm-4 col-6"></app-sidenav>
    <main class="col-lg-9 col-md-8 col-sm-7 col-5" role="main">
      <app-confirm #confirm_list></app-confirm>
      <div class="row">
        <div class="col-12 col-md-8 col-lg-9">
          <span class="table-size float-end">{{data.specifications.search().length}}</span>
          <h2>INCI Specifications</h2>
        </div>
        <div class="col-12 col-md-4 col-lg-3 search-box" stickyThing>
          <input id="search" name="search" type="search" placeholder="Search" [(ngModel)]="data.specifications.searchTerm" class="form-control">
        </div>
      </div>
      <app-alert></app-alert>

      <div class="container-fluid"> 
        <table class="table table-striped"><tbody>
          <tr>
            <th>Name</th>
            <th>Supplement</th>
            <th>Unit</th>
            <th>Description</th>
            <th></th>
          </tr>
          <tr *ngFor="let spec of data.specifications.search()" class="entity" [ngClass]="{'table-active': data.specifications.selected && spec.key==data.specifications.selected.key}">
            <td [ngClass]="{'dirty': spec.dirty}"><span [ngbTooltip]="spec.dirty ? 'Specification has unsaved changes' : ''" tooltipClass="dt-tooltip" container="body">
              <a class="btn btn-link text-start" [routerLink]="['/specifications', spec.key, spec.dirty ? 'edit' : '' ]">{{spec.label}}</a>
            </span></td>
            <td [innerHTML]="text2html(spec.supplement)"></td>
            <td [innerHTML]="text2html(spec.unit)"></td>
            <td>{{spec.descr}}</td>
            <td>
              <span *ngIf="data.specifications.selected && spec.key==data.specifications.selected.key"
                  ngbTooltip="Adjust sort order manually" tooltipClass="dt-tooltip" container="body">
                <span class="oi oi-arrow-thick-top" (click)="up(spec)"></span>
                <span class="oi oi-arrow-thick-bottom" (click)="down(spec)"></span>
              </span>
            </td>
          </tr>
        </tbody></table>
      </div>
      <div class="row" *ngIf="sortOrderIsDirty()">
        <div class="col-md-12 text-end">
          <button (click)="save()" class="btn btn-outline-secondary">Save Sort Order</button>
        </div>
      </div>

      <div  *ngIf="!data.specifications.selected">
        <small><span class="oi oi-info"></span>&nbsp;&nbsp;The system will always add <em>Trade Name</em>, <em>INCI declarations</em> and <em>CAS numbers</em> to the specifications table when generating report content.</small>
      </div>

      <div  *ngIf="data.specifications.selected && !edit">
        <app-specification-show [spec]="data.specifications.selected"></app-specification-show>
      </div>
      <div  *ngIf="data.specifications.selected && edit">
        <app-specification-edit [spec]="data.specifications.selected"></app-specification-edit>
      </div>
    </main>
  </div>
</div>
