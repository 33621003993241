import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appMultiRequire]',
  providers: [{provide: NG_VALIDATORS, useExisting: MultiRequireValidatorDirective, multi: true}]
})
export class MultiRequireValidatorDirective implements Validator {

  readonly OR = 'OR';
  readonly AND_OR_NONE = 'AND_OR_NONE';

  @Input('appMultiRequire') otherControlId: string | string[];
  // tslint:disable-next-line: no-input-rename
  @Input('appValidationOperator') operator = this.OR;

  validate(control: AbstractControl): {[key: string]: any} | null {
    const others = this.normalizeControlIDs().map(id => this.otherControl(control, id));
    const ok = {
      OR: this.validate_OR,
      AND_OR_NONE: this.validate_AND_OR_NONE
    }[this.operator].apply(this, [control, others]);
    others.forEach(ctrl => {
      if (ctrl) {
        setTimeout(() => {
          if (ok ? !ctrl.valid : ctrl.valid) {   // emulate XOR oparator
            ctrl.markAsTouched();
            ctrl.updateValueAndValidity();
          }
        }, 0);
      }
    });
    return ok ? null : { 'required': true };
  }

  validate_AND_OR_NONE(control: AbstractControl, others: AbstractControl[]): boolean {
    let allUnset = !this.hasValue(control);
    let allSet   =  this.hasValue(control);
    others.forEach(ctrl => {
      allUnset = allUnset && !this.hasValue(ctrl);
      allSet   = allSet   &&  this.hasValue(ctrl);
    });
    return allSet || allUnset;
  }

  validate_OR(control: AbstractControl, others: AbstractControl[]): boolean {
    return others.reduce((result, ctrl) => result || this.hasValue(ctrl), this.hasValue(control));
  }

  normalizeControlIDs(): string[] {
    if (this.otherControlId instanceof Array) {
      return this.otherControlId;
    }
    return this.otherControlId ? ['' + this.otherControlId] : [];
  }

  otherControl(control: AbstractControl, ctrlId: string): AbstractControl | null {
    return this.otherControlId && control.parent && control.parent.controls ? control.parent.controls[ctrlId] : null;
  }

  hasValue(control: AbstractControl): boolean {
    return control && control.value != null && control.value != '' && control.value != 'null';
  }
}
