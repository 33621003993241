import { TemplateProcessor } from './template.processor';
import { HtmlUtil } from '../common';

export class TemplateTokenizer {

  REGEX_DT_TAG: RegExp = /<(\/?)(dt-[\w-]+)(\s[^>]*)?>/gm;
  position = 0;

  constructor(private dataText: string) { }

  process(consumer: TemplateProcessor): Promise<void> {
    // console.log('Tokenizer.process()', 'start processing on input text: ', this.dataText);
    return Promise.resolve()
      .then(() => consumer.startTemplate())
      .then(() => {
        // parse template text for any <dt-xxx> tags
        let p = Promise.resolve();
        let index = 0;
        let match: RegExpExecArray;
        while ((match = this.REGEX_DT_TAG.exec(this.dataText)) != null) {
          const tag = match[0];
          const closeFlag = match[1];
          const tagName = match[2];
          const attrList = match[3];
          const lastIndex = this.REGEX_DT_TAG.lastIndex;
          p = p
            .then(() => {
              const matchStart = lastIndex - tag.length;
              if (matchStart > index) {
                const html = this.dataText.slice(index, matchStart);
                if (html.trim()) {
                  return consumer.doHtml(html);
                }
              }
            })
            .then(() => {
              if (closeFlag === '') {
                const attributes = HtmlUtil.parseAttributes(attrList);
                return consumer.startTag(tagName, attributes);
              } else {
                return consumer.endTag(tagName);
              }
            })
            .then(() => {
              index = lastIndex;
            });
        }
        p = p
          .then(() => {
            if (index < this.dataText.length) {
              const html = this.dataText.slice(index);
              if (html.trim()) {
                return consumer.doHtml(html);
              }
            }
          });
        return p;
      })
      .then(() => consumer.endTemplate());
  }
}
