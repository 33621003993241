import { DataContext } from './data-context';
import { HtmlUtil } from '../html.util';

// --------------------------------

export class ExprNode {
  parent?: ExprAggr;
  value(dataCtx: DataContext): number { return null; }
  acceptNode(token: ExprNode): ExprNode {
    // tslint:disable-next-line: no-use-before-declare
    if (token instanceof ExprAggr) {
      this.parent = token;
      token.nodes.push(this);
      return token;
    }
    console.error('ExprNode.acceptNode()',
        'Invalid token:', token ? token.toString() : null, '", not accepted by "', this.toString(), '".',
        'Parent:', this.parent);
    return null;
  }
  toString = ():string => '_';
  toValueExpr = (dataCtx: DataContext):string => '_';
}

class ExprLiteral extends ExprNode {
  val: number;
  value = (dataCtx) => this.val;
  toString = () => '' + this.val;
  toValueExpr = (dataCtx: DataContext) => '' + this.val;
}

class ExprRef extends ExprNode {
  alias: string;
  value = (dataCtx) => {
    const raw = dataCtx.get(this.alias);
    if (typeof(raw) === 'number') {
      return raw;
    }
    if (typeof(raw) === 'string') {
      return HtmlUtil.parseLocaleNumber(raw);
    }
    return raw != null ? Number(raw) : Number.NaN;
  }
  toString = () => '' + this.alias;
  toValueExpr = (dataCtx) => '' + this.value(dataCtx);
}

class ExprParenthesis extends ExprNode {
  constructor(private node: ExprNode) { super(); }
  value = (dataCtx) => this.node.value(dataCtx);
  toString = () => this.node.toString();
  toValueExpr = (dataCtx) => this.node.toValueExpr(dataCtx);
}

// --------------------------------

class ExprAggr extends ExprNode {
  // TODO: add operator precendence value here
  nodes: ExprNode[] = [];
  acceptNode(token) {
    // TODO: decide by operator precendence - add child or new parent?
    if (Object.getPrototypeOf(token) === Object.getPrototypeOf(this)) {
      return this;
    }
    if (token instanceof ExprAggr) {
      this.parent = token;
      token.nodes.unshift(this);
      return token;
    }
    if (token instanceof ExprLiteral || token instanceof ExprRef || token instanceof ExprParenthesis) {
      token.parent = this;
      this.nodes.push(token);
      return this;
    }
    console.error('ExprAggr.acceptNode()',
        'Invalid token "', token ? token.toString() : token, '", not accepted by "', this.toString(), '".',
        'Parent:', this.parent);
    return null;
  }
  toString = () => '()';
  toValueExpr = (dataCtx) => '()';
}

class ExprAdd extends ExprAggr {
  value = (dataCtx) => this.nodes.reduce((sum, node) => sum + node.value(dataCtx), 0);
  toString = () => this.nodes ? '(' + this.nodes.map(n => n.toString()).join(' + ') + ')' : '0';
  toValueExpr = (dataCtx: DataContext) => this.nodes ? '(' + this.nodes.map(n => n.toValueExpr(dataCtx)).join(' + ') + ')' : '0';
}

class ExprMinus extends ExprAggr {
  value(dataCtx) {
    if (this.nodes.length === 0) {
      return 0;
    } else if (this.nodes.length === 1) {
      return this.nodes[0].value(dataCtx);
    }
    return this.nodes.slice(1).reduce((diff, node) => diff - node.value(dataCtx), this.nodes[0].value(dataCtx));
  }
  toString = () => this.nodes ? '(' + this.nodes.map(n => n.toString()).join(' - ') + ')' : '0';
  toValueExpr = (dataCtx) => this.nodes ? '(' + this.nodes.map(n => n.toValueExpr(dataCtx)).join(' - ') + ')' : '0';
}

class ExprMult extends ExprAggr {
  nodes = [];
  value = (dataCtx): number => this.nodes.reduce((result, node) => result * node.value(dataCtx), 1);
  toString = () => this.nodes ? '(' + this.nodes.map(n => n.toString()).join(' * ') + ')' : '1';
  toValueExpr = (dataCtx) => this.nodes ? '(' + this.nodes.map(n => n.toValueExpr(dataCtx)).join(' * ') + ')' : '1';
}

class ExprDiv extends ExprAggr {
  value(dataCtx) {
    if (this.nodes.length === 0) {
      return 0;
    } else if (this.nodes.length === 1) {
      return this.nodes[0].value(dataCtx);
    }
    return this.nodes.slice(1).reduce((result, node) => result / node.value(dataCtx), this.nodes[0].value(dataCtx));
  }
  toString = () => this.nodes ? '(' + this.nodes.map(n => n.toString()).join(' / ') + ')' : '0';
  toValueExpr = (dataCtx) => this.nodes ? '(' + this.nodes.map(n => n.toValueExpr(dataCtx)).join(' / ') + ')' : '0';
}

class ExprMax extends ExprAggr {
  value(dataCtx): number {
    const values = this.nodes.map(n => n.value(dataCtx));
    if (!values.find(v => v != null && !isNaN(v))) {
      return NaN;
    }
    return values.reduce((maximum, v) => {
      if (v == null || v == undefined || isNaN(v)) {
        return maximum;
      }
      if (maximum == null) {
        return v;
      }
      return Math.max(maximum, v);
    }, null);
  }
  toString = () => this.nodes ? 'max(' + this.nodes.map(n => n.toString()).join(', ') + ')' : '0';
  toValueExpr = (dataCtx) => this.nodes ? 'max(' + this.nodes.map(n => n.toValueExpr(dataCtx)).join(', ') + ')' : '0';
}

class ExprMin extends ExprAggr {
  value(dataCtx) {
    const values = this.nodes.map(n => n.value(dataCtx));
    if (!values.find(v => v != null && !isNaN(v))) {
      return NaN;
    }
    return values.reduce((minimum, v) => {
      if (v == null || v == undefined || isNaN(v)) {
        return minimum;
      }
      if (minimum == null) {
        return v;
      }
      return Math.min(minimum, v);
    }, null);
  }
  toString = () => this.nodes ? 'min(' + this.nodes.map(n => n.toString()).join(', ') + ')' : '--';
  toValueExpr = (dataCtx) => this.nodes ? 'min(' + this.nodes.map(n => n.toValueExpr(dataCtx)).join(', ') + ')' : '--';
}

// --------------------------------

export class ExpressionParser {
  REGEX_TOKENS: RegExp = /max\(|min\(|\+|\-|\*|\/|[\d\.]+|[\w\.\\]+|\(|\)/gm;

  root: ExprNode;

  constructor(private dataText: string) { }

  parse(depth?: number, root?: ExprNode): ExprNode {
    // ---------
    // Caution: this parser respects only a rudimentary version of operator precendence
    // Examples:
    //  '2 - - 1' => 2 - 1
    //  '3 + 4 / 2' => (3+4)/2
    //
    // TODO: Implement proper expression parsing.
    // ---------
    // TODO: Implement function exec expression.
    // Example: $formatSomething(data_ref_a_{{index}}, data_ref_b_{{index}})
    // ---------

    // console.log('ExpressionParser.parse()', 'parsing expression: ', this.dataText);
    if (depth == null) depth = 0;
    let match: RegExpExecArray;
    let n = null;
    while ((match = this.REGEX_TOKENS.exec(this.dataText)) != null) {
      const token = match[0];
      if (token == '(') {
        n = new ExprParenthesis(this.parse(depth + 1));
      }
      else if (token == 'max(' || token == 'min(') {
        n = new ExprParenthesis(this.parse(depth + 1, this.toNode(token)));
      }
      else if (token == ')') {
        break;
      }
      else {
        n = this.toNode(token);
      }

      if (n == null) {
        continue;
      }
      if (root == null) {
        root = n;
      } else {
        root = root.acceptNode(n);
      }
    }
    if (depth == 0) {
      // console.log('ExpressionParser.parse()', 'parsed expression:  ', this.dataText, ' -> ', this.root);
      this.root = root;
    }
    return root;
  }

  toNode(token: string): ExprNode {
    switch (token) {
      case '+':
        return new ExprAdd();
      case '-':
        return new ExprMinus();
      case '*':
        return new ExprMult();
      case '/':
        return new ExprDiv();
      case 'max(':
        return new ExprMax();
      case 'min(':
        return new ExprMin();
    }
    if (/^[\d\.]+$/.test(token)) {
      const n = new ExprLiteral();
      n.val = Number(token);
      return n;
    }
    if (/^[\w\.\\]+$/.test(token)) {
      const n = new ExprRef();
      n.alias = token;
      return n;
    }
    return null;
  }

}

