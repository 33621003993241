import { InciFunction } from './inci_function.model';
import { InciRouteCorrection, ValueSkeleton } from './value.model';
import { BaseEntity } from './base_entity';
import { Constants } from '../constants';

export class InciRoute extends BaseEntity {

    constructor(source?: InciRoute) {
        super(source);

        this.type = this.type || 'NOAEL';
        this.factor = this.factor || 1.0;
        this.absorption = this.absorption || 100;
        this.absorptionUnit = this.absorptionUnit || '%';
        this.corrections = this.corrections || { list: [] };
        this.testedRoute = this.testedRoute || '';
    }

    route: string;          // dermal, oral, inhalation
    type: string;           // NOAEL, LOAEL, BMDL, text
    value: number;
    factor: number;         // corrections factor, divisor
    absorption: number;
    absorptionUnit: string;
    dose: number;
    corrections: {
        list: (InciRouteCorrection | ValueSkeleton)[]
    };
    duration: string;       // experiment parameter
    animal: string;         // experiment parameter
    testedRoute: string;    // experiment parameter
    literatureRef: string;
    literatureRefAbsorption: string;
    descr: string;
    default: boolean        // only one true flag per (INCI, route)

    // transient properties
    _correctionText: string;

    isNumeric(): boolean {
        return this.isUnit('%')
    }
    isUnit(unit: string): boolean {
        return this.absorptionUnit == unit;
    }

    get absorptionPercent(): number {
        return this.absorptionUnit == '%' ? this.absorption : 100 * this.absorption / this.dose;
    }

    get pod(): number {
        return this.value / this.factor;
    }
  }

export class Inci extends BaseEntity {

    constructor(source?: Inci) {
        super(source);
        this.functions = this.functions || [];
        this.routes = this.routes || [];
        this.eukv = this.eukv || { list: [] };
    }

    name: string;
    description: string;
    eukv: { list: { appendix: number, number: string }[] };
    casNo: string;
    einecsNo: string;
    perfume: boolean;
    literatureRefCir: string;
    ld50Value: number;
    ldUnit: string;

    functions: InciFunction[];
    routes: InciRoute[];

    // legacy support: this property was previously located immediately at inci level
    get noaelValue(): number {
        const r = this.getRoute();
        return r ? r.value: null;
    }
    // legacy support: this property was previously located immediately at inci level
    get literatureRefNoael(): string {
        const r = this.getRoute();
        return r ? r.literatureRef: null;
    }

    hasCir(): boolean {
        return this.literatureRefCir ? true : false;
    }

    isEukvAppendix(num?: number): boolean {
        if (num != null) {
            return this.eukv.list.filter(n => n.appendix === num).length > 0;
        }
        return this.eukv.list.filter(n => n.appendix > 0).length > 0;
    }

    getEukvNumber(appendix: number): string {
        const a = this.eukv.list
            .find(a => a.appendix == appendix);
        return a ? a.number : '--';
    }

    // legacy support: templates might still use this name, previous to rename refactoring
    getNoael(route?: string): InciRoute {
      return this.getRoute(route);
    }

    getRoute(route?: string): InciRoute {
        if (route && !Constants.POD.ROUTES.all().map(r => r.value).includes(route)) {
            console.warn('getRoute()', "Given route parameter is invalid. Fallback value might be incorrect!", route);
        }
        var result: InciRoute = null; 
        if (route) {
            result = this.routes.find(n => n.route == route);
        }
        if (!result) {
            result = this.routes.find(n => n.default);
        }
        if (!result) {
            result = this.routes.find(n => n.route == null);
        }
        return result;
    }

    getRouteInfo(): {short: string, value: string}[] {
        return this.routes.map(n => Constants.POD.ROUTES.resolve(n.route))
      }

    /* This method allows SED calculation, even if no absorption/noael data is present.
     * It falls back to the global default values of known exposition routes.
     * Measure of last resort is a value of 100% . */
    getAbsorption(route?: string): number {
        const ir = this.getRoute(route);
        if (ir) {
            return ir.absorptionPercent;
        }
        const routeSpec = Constants.POD.ROUTES.resolve(route);
        if (routeSpec) {
            return routeSpec.defaultAbsorption;
        }
        console.warn('getAbsorption()', "Given route parameter is invalid. Fallback value might be incorrect!", route);
        return 100;
    }
}
