import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService } from '../../common';
import { Docx } from '../../common/model';
import { DocxService } from '../../common/services';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-docx-show',
  templateUrl: './docx-show.component.html'
})
export class DocxShowComponent extends BaseMainpage implements OnInit {

  @Input() docx: Docx;
  @Output() docxChange: EventEmitter<Docx> = new EventEmitter<Docx>();

  @ViewChild('f', { static: true }) form: NgForm;

  private formData = new FormData();

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private docxService: DocxService) {
    super(spinner, notify, router, data);
    console.log('DocxShowComponent.create()');
  }

  ngOnInit() {
    console.log('DocxShowComponent.init()');
  }

  download(event: Event) {
    event.preventDefault();
    this.docxService.download(this.docx)
      .then(blob => {
        const fileURL = URL.createObjectURL(blob);

        const a: HTMLAnchorElement = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = fileURL;
        a.download = this.data.docxs.selected.name + '.docx';
        a.click();
        window.URL.revokeObjectURL(fileURL);
      });
  }

  onFileSelect(event) {
    event.preventDefault();
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formData.set('file', file);
    }
  }

  upload(event: Event) {
    event.preventDefault();
    this.startSpinner();
    this.docxService.upload(this.docx.key, this.formData)
      .then(e => this.notify_ok())
      .catch(err => this.notify_error('DocxShowComponent.upload()', err))
      .finally(() => this.stopSpinner());
  }
}
