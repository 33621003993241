import { Component, Input, ElementRef, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-selectable-label',
  templateUrl: 'selectable-label.component.html'
})
export class SelectableLabelComponent {

  @Input() obj: any;
  @Input() field: string;
  @Input() name: string;
  @Input() placeholder: string;

  @Output() change: EventEmitter<any> = new EventEmitter();

  @ViewChild('select') selectEl: ElementRef;

  editMode = false;

  constructor(private el: ElementRef) { }

  @HostListener('click') onClick() {
    this.editMode = true;
    setTimeout(() => {
      if (this.selectEl) { this.selectEl.nativeElement.focus(); }
    }, 10);
  }

  changed() {
    this.change.emit(null);
  }

  finished() {
    this.editMode = false;
    const text = this.obj[this.field];
    if (text) {
      this.obj[this.field] = text.trim();
    }
  }

}
