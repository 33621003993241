import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { Textblock } from 'src/app/common/model';

import { BaseMainpage, DataService, SpinnerService, NotifyService } from '../../common';

@Component({
  selector: 'app-template-history',
  templateUrl: './number-precision.component.html'
})
export class NumberPrecisionComponent extends BaseMainpage implements OnInit, AfterViewInit {

  public previewFactory = InlineEditor;

  public editorConfig = {
    heading: {
      options: [
        { model: 'heading1', view: 'h1', title: 'Überschrift 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Überschrift 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Überschrift 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Überschrift 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Überschrift 5', class: 'ck-heading_heading5' },
        { model: 'heading6', view: 'h6', title: 'Überschrift 6', class: 'ck-heading_heading6' },
        { model: 'toc_heading', view: { name: 'div', classes: 'Überschrift_Inhalt_SIB' },
            title: 'Überschrift Inhalt', class: 'ck-heading_heading1 text-underlined' },
        { model: 'toc', view: { name: 'div', classes: 'TOC' }, title: 'Inhaltsverzeichnis', class: 'dt-toc' },
        { model: 'annotation', view: { name: 'div', classes: 'Annotation_SIB' }, title: 'Annotation', class: 'dt-annotation' },
        { model: 'sig_head', view: { name: 'div', classes: 'Signatur_Header_SIB' }, title: 'Signatur Name', class: 'dt-sig-head' },
        { model: 'sig_text', view: { name: 'div', classes: 'Signatur_SIB' }, title: 'Signatur Text', class: 'dt-sig-text' },
        { model: 'biblio', view: { name: 'div', classes: 'Literatur_SIB' }, title: 'Literatur', class: 'dt-biblio' },
        { model: 'quotation', view: { name: 'div', classes: 'dt-quotation' }, title: 'Quotation', class: 'ck-heading_paragraph' },
        { model: 'paragraph', title: 'Standard', class: 'ck-heading_paragraph' }
      ]
    }
  };

  private test_case = '<p></p>' +
  '<p><strong>Input:</strong> <x-field class="property" data-alias="base_value">555.5555</x-field></p>' +
  '<p><strong>Ohne Formatierungsangabe:</strong> <x-field class="calculation" data-formula="base_value">XXX</x-field></p>' +
  '<figure class="table"><table><tbody> \
  <tr> <td><p><em>format="num2txt:[prec]:[sci]"</em></p></td> \
    <td><strong>Precisions:</strong> 1</td> <td>2</td> <td>3 (default)</td> <td>4</td> \
  </tr> \
  <tr>  <td><strong>Decimal Threshold:</strong> 0</td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:1:0">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:2:0">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:3:0">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:4:0">XX</x-field></p></td> \
  </tr> \
  <tr>  <td>1</td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:1:1">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:2:1">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:3:1">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:4:1">XX</x-field></p></td> \
  </tr> \
  <tr>  <td>2</td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:1:2">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:2:2">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:3:2">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:4:2">XX</x-field></p></td> \
  </tr> \
  <tr>  <td>3 (default)</td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:1:3">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:2:3">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:3:3">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:4:3">XX</x-field></p></td> \
  </tr> \
  <tr>  <td>4</td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:1:4">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:2:4">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:3:4">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:4:4">XX</x-field></p></td> \
  </tr> \
  <tr>  <td>5</td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:1:5">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:2:5">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:3:5">XX</x-field></p></td> \
    <td><p><x-field class="calculation" data-formula="base_value" format="num2txt:4:5">XX</x-field></p></td> \
  </tr> \
</tbody></table></figure>' +
'<p></p>';

  public testContent: Textblock = null;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute) {
    super(spinner, notify, router, data);
    console.log('NumberPrecisionComponent.create()');
  }

  ngOnInit() {
    this.testContent = new Textblock({
      name: 'testBlock',
      dataCtx: { data: { ctx: {} } },
      properties: { type: 'html', frozen: false },
      dataText: this.test_case
    });
  }

  ngAfterViewInit() {
    this.recalculate();
  }

  recalculate() {
    console.log('NumberPrecisionComponent.recalculate()');
    setTimeout(() => {
      this.testContent.collectedDataCtx().reset();
      this.testContent.resolveDatafields();
      this.testContent.collectProperties();
      this.testContent.refreshCalculations();
      this.testContent.refreshDynamicBlocks();
    }, 0);
  }

}
