import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ConfirmComponent } from '../../../common';
import { Value } from '../../../common/model';
import { ValueService } from '../../../common/services';

@Component({
  selector: 'app-correction-show',
  templateUrl: './correction-show.component.html'
})
export class CorrectionShowComponent extends BaseMainpage implements OnInit {

  @Input() val: Value;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private valueService: ValueService) {
    super(spinner, notify, router, data);
    console.log('CorrectionShowComponent.create()');
  }

  ngOnInit() {
    console.log('CorrectionShowComponent.init()');
  }

  delete() {
    this.confirm.open('Correction', 'Delete correction: ' + this.val.label + ' ?', 'Delete')
      .then(() => {
        this.startSpinner();
        this.valueService.delete(this.val)
          .then(() => {
            this.router.navigate(['/values/correction']);
          })
          .finally(() => {
            this.stopSpinner();
          });
      })
      .catch(() => { /* do nothing */ });
  }
}
