import { BaseMainpage } from './mainpage.base';
import { NgForm } from '@angular/forms';
import { ConfirmComponent } from './directives';
import { BaseEntity } from './model/base_entity';
import { EntityStore } from './services/entity_store';

export class BaseEditpage<T extends BaseEntity> {

  constructor(
      private mainPage: BaseMainpage,
      private form: NgForm,
      private confirm: ConfirmComponent,
      private service: EntityStore<T>,
      private entity: T) {
        entity.dirty ? form.form.markAsDirty() : form.form.markAsPristine();
      }

  _validate?: () => boolean;
  _preSave?: () => void;
  _postSave?: () => void;
  _postCreate?: (e: T) => void;

  private entityName = () => this.entity.constructor.name;
  caption: () => string = () => '' + (this.entity['name'] ? this.entity['name'] : this.entity.key);

  canDeactivate(): boolean | Promise<boolean> {
    console.log('BaseEditpage.canDeactivate()');
    if (this.form && this.form.dirty) {
      return this.confirm.open(
          (this.entity.id ? '' : 'New ') + this.entityName(),
          'Save ' + this.entityName() + ': ' + this.caption() + ' ?',
          'Save')
        .then(
          () => { this.save(); },               // Option: Save
          () => { this.entity.dirty = true; }   // Option: Dismiss
        )
        .then(() => true )
        .catch(() => true );
    }
    return true; // false stops navigation, true continue navigation
  }

  save(): Promise<void> {
    console.log('BaseEditpage.save()', this.entityName());
    if (this.mainPage.isSpinnerActive()) {
      console.log('BaseEditpage.save()', 'update operation already running, skipping');
      return Promise.reject();
    }
    if (this.form.valid && this.form.dirty && (!this._validate || this._validate())) {
      this.mainPage.startSpinner();
      if (this._preSave) { this._preSave(); }
      return  (() => {
        if (!this.entity.id) {
          console.log('BaseEditpage.save()', 'create new ' + this.entityName());
          return this.service.create(this.entity)
            .then(e => {
              this.entity.dirty = false;
              this.form.form.markAsPristine();
              return e;
            })
            .then(e => {
              if (this._postCreate) { this._postCreate(e); }
            });
        } else {
          console.log('BaseEditpage.save()', 'update existing ' + this.entityName());
          return this.service.update(this.entity)
            .then(e => {
              this.entity.dirty = false;
              this.form.form.markAsPristine();
              return e;
            })
            .then(e => this.mainPage.notify_ok());
        }
      })()
      .then(() => {
        if (this._postSave) { this._postSave(); }
      })
      .catch(err => this.mainPage.notify_error('BaseEditpage.save()', err))
      .finally(() => this.mainPage.stopSpinner());
    } else {
      console.log('BaseEditpage.save()', 'Reject save action');
    }
    return Promise.resolve();
  }

}
