<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{spec.label}}</h3>

      <div class="row">
        <div class="col-4 col-md-2 col-lg-2 col-form-label">Supplement:</div>
        <div class="col-8 col-md-10 col-lg-4"><span class="form-control-plaintext" [innerHTML]="text2html(spec.supplement)"></span></div>
        <div class="col-4 col-md-2 col-lg-2 col-form-label">Unit:</div>
        <div class="col-8 col-md-10 col-lg-4"><span class="form-control-plaintext" [innerHTML]="text2html(spec.unit)"></span></div>
      </div>
      <div class="row">
        <label class="col-12 col-md-2 col-form-label">Description:</label>
        <div class="col-12 col-md-10"><span class="form-control-plaintext">{{spec.descr}}</span></div>
      </div>
    </div>
    <div class="col-1">
      <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
        <a [routerLink]="['/specifications', spec.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
      </div>
      <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
        <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-2 col-form-label">Labels:</div>
    <div class="col-12 col-md-10"><div class="form-control-plaintext">
      <table class="table table-striped w-100"><tbody>
        <tr>
          <th>Label</th>
          <th>Supplement</th>
          <th>Description</th>
          <th>Language</th>
        </tr>
        <tr *ngFor="let label of spec.labels; index as i">
          <td>{{label.label}}</td>
          <td><span [innerHTML]="text2html(label.supplement)"></span></td>
          <td>{{label.descr}}</td>
          <td>{{label.language}}</td>
        </tr>
      </tbody></table>
    </div></div>
  </div>
</div>
