export class BaseEntity {

  constructor(source?: BaseEntity) {
    if (source) {
      Object.assign(this, source);
      if (this.createdAt && typeof(this.createdAt) === 'string') {
        this.createdAt = new Date(this.createdAt);
      }
    }
  }

  id: number;
  key: number;
  createdAt: Date;
  createdBy: string;
  obsolete: Date;

  // attributes not persisted
  immutable = true; // user entities are mutable
  dirty = false;
}
