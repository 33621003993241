import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { BaseMainpage, BaseEditpage, Constants, DataService, SpinnerService, NotifyService, AuthenticationService, ConfirmComponent, ComponentDeactivate } from '../common';
import { User, UserJobTitle } from '../common/model';
import { UserService } from '../common/services';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @Input() user: User;
  @Input() language = 'de';

  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  public languages = Constants.LANG;
  public newJobTitle: string;
  public newJobTitleDescr: string;

  private editpage: BaseEditpage<User> = null;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      public authService: AuthenticationService,
      private userService: UserService) {
    super(spinner, notify, router, data);
    console.log('UserEditComponent.create()');
  }

  ngOnInit() {
    console.log('UserEditComponent.init()');
    this.editpage = new BaseEditpage(this, this.form, this.confirm, this.userService, this.user);
    this.editpage._postCreate = (usr: User) => {
      this.router.navigate(['/users', usr.key]);
    };
  }

  canDeactivate() {
    return this.editpage.canDeactivate();
  }

  save(): Promise<void> {
    return this.editpage.save();
  }

  // save() {
  //   console.log('UserEditComponent.addJobTitle()', this.form.valid, this.form.dirty);
  //   for (const c in this.form.controls) {
  //     console.log(c, this.form.controls[c].invalid);
  //   }
  //   if (this.form.valid && this.form.dirty) {
  //     this.startSpinner();
  //     if (this.data.users.selected.id) {
  //       this.userService.update(this.data.users.selected)
  //           .then(user => {
  //               this.data.users.selected = user;
  //               this.form.form.markAsPristine();
  //           })
  //           .then(() => this.notify_ok())
  //           .catch(err => this.notify_error('UserEditComponent.submit()', err))
  //           .finally(() => this.stopSpinner());
  //     } else {
  //       this.userService.create(this.data.users.selected)
  //           .then(user => {
  //               this.data.users.selected = user;
  //               this.form.form.markAsPristine();
  //               this.router.navigate(['/users']);
  //           })
  //           .catch(err => this.notify_error('UserEditComponent.submit()', err))
  //           .finally(() => this.stopSpinner());
  //     }
  //   }
  // }

  addJobTitle() {
    console.log('UserEditComponent.addJobTitle()', this.newJobTitle);
    if (this.newJobTitle) {
      const ujt = new UserJobTitle();
      ujt.title = this.newJobTitle;
      ujt.descr = this.newJobTitleDescr;
      ujt.language = this.language;
      this.user.jobTitles.push(ujt);
      this.newJobTitle = null;
      this.newJobTitleDescr = null;
    }
  }

  removeJobTitle(jobTitle: UserJobTitle, event: Event) {
    console.log('UserEditComponent.removeJobTitle()', jobTitle);
    event.preventDefault();
    this.user.jobTitles = this.user.jobTitles.filter(l => l !== jobTitle);
    this.form.form.markAsDirty();
  }
}
