import { HtmlUtil } from '../html.util';
import { ExprNode, ExpressionParser } from './expressions';
import { DataContext } from './data-context';

function selectFormatter(name: string, defName?: string): (value: any) => string {
  if (name === 'text2html') {
    return HtmlUtil.text2html;
  } else if (name === 'roman') {
    return HtmlUtil.num2roman;
  } else if (name === 'num2txt') {
    return HtmlUtil.num2txt;  // default precision
  } else if (name && name.indexOf('num2txt:') === 0) {
    const parts = name.split(':')
    const prec = parts.length > 1 ? Number(parts[1]) : undefined;
    const sciSmall = parts.length > 2 ? Number(parts[2]) : undefined;
    const sciLarge = parts.length > 3 ? Number(parts[3]) : undefined;
    return v => HtmlUtil.num2txt(v, prec, sciSmall, sciLarge);
  } else if (name === 'nl2br') {
    return HtmlUtil.nl2br;
  } else if (defName != null) {
    return selectFormatter(defName);
  }
  return null;
}

/**
 * DataField
 *
 * References a variable or the result of a method call from the objects within the data context.
 * The value is rendered as an autogenerated readonly field, similar to, say, pages numbers in Microsoft Word.
 * 
 * Avaliable attributes:
 * - source: The path to an attribute or a method call in the data context
 * - alias: (optional) The resulting value will be available in the data context to those <x-fields/> in the report document that appear after this datafield.
 * - final: (optinal) (values: 'true', 'false') If set to 'true', this datafield is not subject to re-evaluation when the user triggers the 'recalculate' button.
 * - style: (optinal) Some CSS style directives
 * - format: (optional) A token to apply a specific formatting rule on the value. See method 'selectFormatter' for available options.
 *
 * Examples:
 * <x-field class="datafield" data-source="foo"></x-field>
 * <x-field class="datafield" data-source="foo" data-alias="bar"></x-field>
 * <x-field class="datafield" style="display:none"></x-field>
 **/
export class DataField {
  source: string;
  alias?: string;
  final?: boolean;
  style?: string;
  format?: string;
  value: any = null;
  formatTagsStart: string;
  formatTagsEnd: string;

  static regexTemplate = () => /{{([\w\.\-\[\]\(\)\\]+)}}/gm;
  static regexHtml = () =>  /<x-field([^>]*class="datafield"[^>]*)>((<\w*>)*)([^<>]*)((<\/\w*>)*)<\/x-field>/gm;

  static fromTemplateMatch(match) {
    const df = new DataField();
    df.source = match[1];
    return df;
  }

  static fromHtmlMatch(match) {
    const df = new DataField();
    const attrs = HtmlUtil.parseAttributes(match[1]);
    df.source = attrs.source;
    df.alias = attrs.alias;
    df.final = attrs.final === 'true';
    df.style = attrs.style;
    df.format = attrs.format;
    df.formatTagsStart = match[2];
    df.formatTagsEnd = match[5];
    df.value = match[4];
    return df;
  }

  formatValue(language: string): string {
    if (this.value == null || this.value == '') {
      return '--';
    }
    const formatter = selectFormatter(this.format, (typeof this.value == 'number') ? 'num2txt' : 'nl2br');
    return (formatter != null ? formatter(this.value) : this.value)
  }

  toHtmlTag(language: string) {
    return '<x-field class="datafield" data-source="' + this.source
      + (this.alias ? ('" data-alias="' + this.alias) : '')
      + (this.format ? ('" data-format="' + this.format) : '')
      + (this.final ? ('" data-final="true') : '')
      + (this.style ? ('" style="' + this.style) : '')
      + '">'
      + (this.formatTagsStart ? this.formatTagsStart : '')
      + this.formatValue(language)
      + (this.formatTagsEnd ? this.formatTagsEnd : '')
      + '</x-field>';
  }
}

/**
 * Property
 *
 * Provides an HTML input field within the report's content editor component.
 * The entered value will be rendered into the document at this place and the value will also be available to subsequent claculation fields.
 * 
 * No formatting neccessary, because the author is free to enter the value just the way she likes.
 * 
 * Avaliable attributes:
 * - alias: (optional) The resulting value will be available in the data context to those <x-fields/> in the report document that appear after this property field.
 * 
 **/
 export class Property {
  alias: string;
  value: string;
  formatTagsStart: string;
  formatTagsEnd: string;

  // Caution: implicit assumption - no inline tags within datafield span
  static regexHtml = () =>  /<x-field([^>]+class="property"[^>]*)>((<\w*>)*)([^<>]*)((<\/\w*>)*)<\/x-field>/gm;

  static fromHtmlMatch(match) {
    const df = new Property();
    const attrs = HtmlUtil.parseAttributes(match[1]);
    df.alias = attrs.alias;
    df.value = match[4];
    df.formatTagsStart = match[2];
    df.formatTagsEnd = match[5];
    if (df.value != null) {
      df.value = df.value.replace(/&nbsp;/, ' ');
    }
    return df;
  }

  formatValue(language: string) {
    this.value = HtmlUtil.formatLocaleNumber(this.value, language);
  }

  toHtmlTag() {
    return '<x-field class="property" data-alias="' + this.alias + '">'
      + (this.formatTagsStart ? this.formatTagsStart : '')
      + this.value
      + (this.formatTagsEnd ? this.formatTagsEnd : '')
      + '</x-field>';
  }
}

/**
 * Calculation
 *
 * A calculation formula that combines any value avaliable via the data context.
 * The resulting value after evaluating the formula based on the data context is rendered as a readonly value field similar to the above <x-field class="datafield">
 * 
 * Avaliable attributes:
 * - formula: A calculation expression of operators and data context path tokens.
 * - alias: (optional) The resulting value will be available in the data context to those <x-fields/> in the report document that appear after this calculation field.
 * - format: (optional) A token to apply a specific formatting rule on the value. See method 'selectFormatter' for available options.
 *
 * Examples:
 **/
 export class Calculation {
  alias: string;
  formula: string;
  evalFormula: string;
  value: any;
  format?: string;
  formatter?: (value: any) => string;
  formatTagsStart: string;
  formatTagsEnd: string;

  expression: ExprNode;

  // Caution: implicit assumption - no inline tags within datafield span
  static regexHtml = () =>  /<x-field([^>]*class="calculation"[^>]*)>((<\w*>)*)([^<>]*)((<\/\w*>)*)<\/x-field>/gm;

  static fromHtmlMatch(match) {
    const c = new Calculation();
    const attrs = HtmlUtil.parseAttributes(match[1]);
    c.formula = attrs.formula;
    c.evalFormula = null;
    c.alias = attrs.alias;
    c.format = attrs.format;
    c.formatter = selectFormatter(c.format, 'num2txt');
    c.value = match[4];
    c.formatTagsStart = match[2];
    c.formatTagsEnd = match[5];
    return c;
  }

  parseFormula() {
    const p = new ExpressionParser(this.formula);
    p.parse();
    this.expression = p.root;
  }

  toHtmlTag(language: string) {
    return '<x-field class="calculation" data-formula="' + this.formula
      + (this.alias ? ('" data-alias="' + this.alias) : '')
      + (this.format ? ('" data-format="' + this.format) : '')
      + (this.evalFormula ? ('" data-evalformula="' + this.evalFormula) : '')
      + ('" data-value="' + HtmlUtil.text2html('' + this.value))
      + '">'
      + (this.formatTagsStart ? this.formatTagsStart : '')
      + this.formatValue(language)
      + (this.formatTagsEnd ? this.formatTagsEnd : '')
      + '</x-field>';
  }

  refresh(dataCtx: DataContext) {
    this.value = this.expression.value(dataCtx);
    this.evalFormula = this.expression.toValueExpr(dataCtx);
  }

  formatValue(language: string): string {
    return HtmlUtil.formatLocaleNumber(this.formatter != null ? this.formatter(this.value) : this.value, language);
  }
}

/**
 * Dynamic page content
 * 
 * Unlike the above field tags, this dynamic field deals with HTML content fragments contained between its start and end tag.
 * The attributes define a conditional test, wether the content should be displayed or not.
 * The interesting twist is, that this evaluation takes place after content generation and at later recalculation time.
 * 
 * Avaliable attributes:
 * - prop: A path token to some attribute or method call within the data context
 * - test: A test contition, equal to the one used in template <dt-if/> tags within templates.
 *
 * Examples:
 **/
 export class Dynamic {
  prop: string;
  test: string;
  value: string;
  active = false;
  formatTagsStart: string;
  formatTagsEnd: string;

  // Caution: implicit assumption - no inline tags within datafield span
  static regexHtml = () =>  /<x-field([^>]*class="dynamic"[^>]*)>((<[^>]*>)*)([^<>]*)((<\/\w*>)*)<\/x-field>/gm;

  static fromHtmlMatch(match) {
    const d = new Dynamic();
    const attrs = HtmlUtil.parseAttributes(match[1]);
    d.prop = attrs.prop;
    d.test = attrs.test;
    d.value = match[4];
    d.formatTagsStart = match[2];
    d.formatTagsEnd = match[5];
    return d;
  }

  toHtmlTag() {
    return '<x-field class="dynamic" data-prop="' + this.prop + '" data-test="' + this.test
      + (!this.active ? '" style="display:none' : '')
      + '">'
      + (this.formatTagsStart ? this.formatTagsStart : '')
      + (this.value ? this.value : '')
      + (this.formatTagsEnd ? this.formatTagsEnd : '')
      + '</x-field>';
  }

  refresh(dataCtx: DataContext) {
    this.active = dataCtx.evalIfTest({
      prop: this.prop,
      test: this.test
    });
  }

}
