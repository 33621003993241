import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseMainpage, DataService, SpinnerService, NotifyService, ComponentDeactivate } from '../common';
import { User } from '../common/model';
import { UserService } from '../common/services';
import { UserEditComponent } from './user-edit.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent extends BaseMainpage  implements OnInit, ComponentDeactivate {

  public edit: boolean;

  @ViewChild(UserEditComponent) userEdit: UserEditComponent;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private route: ActivatedRoute,
      private userService: UserService) {
    super(spinner, notify, router, data);
    console.log('UsersComponent.create()');
  }

  ngOnInit() {
    console.log('UsersComponent.init()');
    this.route.params.subscribe(params => {
      this._show(params['key']);
      this.edit = params['action'] === 'edit';
    });
    this._loadAll();
  }

  canDeactivate() {
    return !this.userEdit || this.userEdit.canDeactivate();
  }

  _show(key: string) {
    console.log('UserComponent._show()', 'show user: ', key);
    if (key) {
      this.userService.getByKey(Number(key))
        .then(usr => {
          this.data.users.selected = usr;
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 0);
        });
    } else {
      this.data.users.selected = null;
    }
  }

  private _loadAll() {
    console.log('UsersComponent._loadAll()');
    this.startSpinner();
    this.userService.assertLoaded()
        .then(() => {
          this.data.users.sort((a: User, b: User) => a.login.localeCompare(b.login));
        })
        .catch(err => {
            console.log('UsersComponent._loadAll()', 'Failed to load user list: ', err);
        })
        .finally(() => {
          this.stopSpinner();
        });
    }
}
