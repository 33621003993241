import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, Constants, ComponentDeactivate, ConfirmComponent, BaseEditpage } from '../../common';
import { DataService, SpinnerService, NotifyService } from '../../common';
import { Function, FunctionLabel } from '../../common/model';
import { FunctionService } from '../../common/services';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-function-edit',
  templateUrl: './function-edit.component.html'
})
export class FunctionEditComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @Input() fn: Function;
  @Input() language = 'de';

  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  public languages = Constants.LANG;
  public newLabel: string;
  public newDescr: string;

  private editpage: BaseEditpage<Function> = null;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private functionService: FunctionService) {
    super(spinner, notify, router, data);
    console.log('FunctionEditComponent.create()');
  }

  ngOnInit() {
    console.log('FunctionEditComponent.init()');
    this.editpage = new BaseEditpage(this, this.form, this.confirm, this.functionService, this.fn);
    this.editpage._postCreate = (f: Function) => {
      this.router.navigate(['/functions', f.key]);
    };
  }

  canDeactivate() {
    return this.editpage.canDeactivate();
  }

  save(): Promise<void> {
    return this.editpage.save();
  }

  addLabel() {
    if (this.newLabel) {
      const l = new FunctionLabel();
      l.label = this.newLabel;
      l.descr = this.newDescr;
      l.language = this.language;
      this.fn.labels.push(l);
      this.newLabel = null;
      this.newDescr = null;
    }
  }

  removeLabel(label: FunctionLabel, event: Event) {
    console.log('FunctionEditComponent.removeLabel()', label);
    event.preventDefault();
    this.fn.labels = this.fn.labels.filter(l => l !== label);
    this.form.form.markAsDirty();
  }
}
