<div class="container-fluid">
    <app-confirm #confirm></app-confirm>
    <br>
    <div class="row">
      <div class="col-11">
        <h3>{{user.givenName}} {{user.surname}}</h3>
  
        <div class="row">
            <label class="col-md-2 col-form-label">Title:</label>
            <div class="col-md-10"><span class="form-control-plaintext">{{user.title}}</span></div>
        </div>
        <div class="row">
            <label class="col-md-2 col-form-label">Given Name:</label>
            <div class="col-md-10"><span class="form-control-plaintext">{{user.givenName}}</span></div>
        </div>
        <div class="row">
            <label class="col-md-2 col-form-label">Surname:</label>
            <div class="col-md-10"><span class="form-control-plaintext">{{user.surname}}</span></div>
        </div>
        <div class="row">
            <label class="col-md-2 col-form-label">Mail:</label>
            <div class="col-md-10"><span class="form-control-plaintext">{{user.mail}}</span></div>
        </div>
        <div class="row">
            <label class="col-md-2 col-form-label">Job Title (en):</label>
            <div class="col-md-10"><span class="form-control-plaintext" [innerHTML]="user.jobTitle | text2html"></span> </div>
        </div>
        <div class="row">
          <div class="col-3 form-group form-check form-check-aligned">
            <input class="form-check-input" readonly disabled type="checkbox" [(ngModel)]="user.active">
            <label class="form-check-label">Active</label>
          </div>
          <div class="col-3 form-group form-check form-check-aligned">
            <input class="form-check-input" readonly disabled type="checkbox" [(ngModel)]="user.author">
            <label class="form-check-label">Author</label>
          </div>
          <div class="col-3 form-group form-check form-check-aligned">
            <input class="form-check-input" readonly disabled type="checkbox" [(ngModel)]="user.admin">
            <label class="form-check-label">Admin</label>
          </div>
        </div>
      </div>
      <div class="col-md-1">
        <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
          <a [routerLink]="['/users', user.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
        </div>
        <!-- <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
          <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-2">Job Titles</div>
      <div class="col-12 col-md-10 col-lg-8">
        <table class="table table-striped w-100"><tbody>
          <tr>
            <th>Title</th>
            <th>Language</th>
          </tr>
          <tr *ngIf="user.jobTitles.length == 0">
            <td colspan="2" class="p-0">
              <div class="w-25 p-1 d-inline-block">&nbsp;</div>
              <div class="p-1 d-inline-block">-- List is empty --</div>
            </td>
          </tr>
          <tr *ngFor="let title of user.jobTitles; index as i">
            <td [innerHTML]="title.title | text2html"></td>
            <td>{{title.language}}</td>
          </tr>
        </tbody></table>
      </div>
    </div>
  </div>
  