import { Component, OnInit } from '@angular/core';
import { BaseMainpage, DataService, SpinnerService, Constants, AlertService, NotifyService } from '../common';
import { ReportLightService } from '../common/services';
import { Router } from '@angular/router';
import { Report } from '../common/model';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html'
})
export class LandingComponent extends BaseMainpage implements OnInit {

  public consts = Constants;
  private _sort_fn = (a: Report, b: Report) => b.createdAt.getTime() - a.createdAt.getTime()

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private alertService: AlertService,
      private reportLightService: ReportLightService) {
    super(spinner, notify, router, data);
    console.log('LandingComponent.create()');
  }

  ngOnInit() {
    console.log('LandingComponent.init()');
    this.startSpinner();
    this.reportLightService.assertLoaded('/api/reportslight/recent')
      .then(() => {
        this.data.reportsLight.sort(this._sort_fn);
      })
      .catch(err => {
        console.error('LandingComponent.init()', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
  }

  search() {
    console.log('LandingComponent.search()', this.data.reportsLight.searchTerm);
    this.startSpinner();
    this.alertService.clear();
    ((() => {
      if (this.data.reportsLight.searchTerm) {
        return this.reportLightService.search(this.data.reportsLight.searchTerm)
          .then(() => {
            // sort descending by date
            this.data.reportsLight.sort(this._sort_fn);
          });
      } else {
        this.reportLightService.reset();
        return this.reportLightService.assertLoaded('/api/reportslight/recent')
          .then(() => {
            this.data.reportsLight.sort(this._sort_fn);
          });
        }
    })())
      .catch(err => {
        console.error('LandingComponent.search()', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
  }
}
