import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, Constants, ComponentDeactivate, ConfirmComponent, BaseEditpage } from '../../../common';
import { DataService, SpinnerService, NotifyService } from '../../../common';
import { Value } from '../../../common/model';
import { ValueService } from '../../../common/services';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-correction-edit',
  templateUrl: './correction-edit.component.html'
})
export class CorrectionEditComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @Input() val: Value;

  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  private editpage: BaseEditpage<Value> = null;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private valueService: ValueService) {
    super(spinner, notify, router, data);
    console.log('CorrectionEditComponent.create()');
  }

  ngOnInit() {
    console.log('CorrectionEditComponent.init()');
    this.editpage = new BaseEditpage(this, this.form, this.confirm, this.valueService, this.val);
    this.editpage._postCreate = (f: Value) => {
      this.router.navigate(['/values/correction', f.key]);
    };
  }

  canDeactivate() {
    return this.editpage.canDeactivate();
  }

  save(): Promise<void> {
    return this.editpage.save();
  }
}
