<div class="container-fluid">
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{docx.name}}</h3>

      <div class="row">
        <label class="col-12 col-md-2 col-form-label">Description:</label>
        <div class="col-12 col-md-10">{{docx.description}}</div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-6">
          <button class="btn btn-primary" (click)="download($event)"><span class="oi oi-data-transfer-download"></span> Download</button>
        </div>
        <div class="col-md-6">
          <form #f="ngForm" (submit)="upload($event)">
            <input id="file" name="file" type="file" (change)="onFileSelect($event)">
            <div class="col-md-12"><button class="btn btn-primary"><span class="oi oi-data-transfer-upload"></span> Upload</button></div>
          </form>
        </div>
      </div>
  </div>
</div>
