<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <br>
  <div class="row">
    <div class="col-11">
      <h3>{{inci.name}}</h3>

      <div class="row">
        <label class="col-12 col-md-3 col-form-label">Description:</label>
        <div class="col-12 col-md-9"><span class="form-control-plaintext">{{inci.description || '-'}}</span></div>
      </div>
      <div class="row">
        <label class="col-4 col-md-3 col-form-label">CAS Number:</label>
        <div class="col-8 col-md-3"><span class="form-control-plaintext">{{inci.casNo || '-'}}</span></div>
        <label class="col-4 col-md-3 col-form-label">EC/EINECS/ELINCS/NLP:</label>
        <div class="col-8 col-md-3"><span class="form-control-plaintext">{{inci.einecsNo || '-'}}</span></div>
      </div>
      <div class="row">
        <label class="col-4 col-md-3 col-form-label">Literature Reference (CIR):</label>
        <div class="col-8 col-md-9"><span class="form-control-plaintext">{{inci.literatureRefCir || '-'}}</span></div>
      </div>

      <div class="row">
        <label class="col-4 col-md-3 col-form-label">EUKV Appendix:</label>
        <div class="col-8 col-md-9">
          <span *ngFor="let a of inci.eukv.list">
            <app-tag readonly="true">
              <span>{{a.appendix | roman}}<sub>{{a.number}}</sub>: {{eukvLabels.label(a.appendix)}}</span>
            </app-tag>
          </span>
          <span *ngIf="inci.eukv.list.length == 0">-</span>
        </div>
      </div>

      <div class="row">
        <label class="col-4 col-md-3 col-form-label">Functions:</label>
        <div class="col-8 col-md-9">
          <span *ngFor="let fn of inci.functions; index as i">
            <app-tag readonly="true"
                [ngClass]="{'dt-tag-primary': fn.default}"
                [tooltip]="fn.default ? 'Default function' : ''">
                <a [routerLink]="['/functions', fn.functionKey ]">{{fn.ref ? fn.ref.name : fn.functionKey}}</a>
            </app-tag>
          </span>
        </div>
      </div>

      <div class="row" *ngIf="inci.routes.length == 0">
        <label class="col-4 col-md-3 col-form-label">PoD<sub>sys</sub>:</label>
        <div class="col-8 col-md-9"><span class="form-control-plaintext">-</span></div>
      </div>
    </div>

    <div class="col-1">
      <div class="form-group" ngbTooltip="Edit" tooltipClass="dt-tooltip">
        <a [routerLink]="['/incis', inci.key, 'edit']" class="form-control btn"><span class="float-end oi oi-pencil"></span></a>
      </div>
      <div class="form-group" ngbTooltip="Delete" tooltipClass="dt-tooltip">
        <button (click)="delete()" class="form-control btn"><span class="float-end oi oi-x"></span></button>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="inci.routes.length > 0">
    <div class="col-12 col-lg-2 col-form-label">PoD<sub>sys</sub>:</div>
    <div class="col-12 col-lg-10">
      <table class="table"><tbody>
        <tr class="even">
          <th>Route</th>
          <th>Test Setup</th>
          <th>Values</th>
          <th class="w-50">Literature</th>
        </tr>
        <ng-container *ngFor="let r of inci.routes; index as i">
          <tr [ngClass]="{'even': i%2==1}">
            <td rowspan="3">
              <span class="pe-2">{{r.route || '-?-'}}</span>
              <input *ngIf="r.default" type="checkbox" readonly disabled [(ngModel)]="r.default"
                  ngbTooltip="Default route" tooltipClass="dt-tooltip">
            </td>
            <td>
              <div ngbTooltip="Type | Animal | Duration" tooltipClass="dt-tooltip">
                <span class="value p-0">{{r.type}}</span>
                <span class="separator">|</span>
                <span class="value">{{r.animal || '-'}}</span>
                <span class="separator">|</span>
                <span class="value">{{r.duration || '-'}}</span>
              </div>
            </td>
            <td>
              <div ngbTooltip="Base Value | Factor" tooltipClass="dt-tooltip">
                <span class="value">{{r.value}}</span>
                <span class="unit">mg/kg<sub>bw</sub>/d</span>
                <span class="separator">|</span>
                <span class="value">{{r.factor}}</span>
              </div>
              <div ngbTooltip="Absorption" tooltipClass="dt-tooltip">
                <span class="value">{{r.absorption}}</span>
                <span *ngIf="r.dose">
                  <span class="operator">/</span>
                  <span class="value">{{r.dose}}</span>
                </span>
                <span class="unit">{{ POD.ABSORPTION_UNITS.resolve(r.absorptionUnit).label }}</span>
              </div>
            </td>
            <td class="w-50">
              <div ngbTooltip="Literature Reference (PoD)" tooltipClass="dt-tooltip">{{r.literatureRef || '-'}}</div>
              <div ngbTooltip="Literature Reference (Absorption)" tooltipClass="dt-tooltip">{{r.literatureRefAbsorption || '-'}}</div>
            </td>
          </tr>
          <tr [ngClass]="{'even': i%2==1}">
            <td *ngIf="r.corrections.list.length" colspan="3">
              <app-tag *ngFor="let c of r.corrections.list"
                  [label]="c.label" [tooltip]="c.properties.text" readonly="true"></app-tag>
            </td>
          </tr>
          <tr [ngClass]="{'even': i%2==1}">
            <td *ngIf="r.descr" colspan="3">
              <span ngbTooltip="Description" tooltipClass="dt-tooltip">{{r.descr}}</span>
            </td>
          </tr>
        </ng-container>
      </tbody></table>
    </div>
  </div>

  <!-- Related Items: -->
  <div class="row">
    <div class="col-12 col-md-6 form-group" *ngIf="data.templates.related(inci).length">
      <label>Related Templates:</label>
      <div>
        <a *ngFor="let t of data.templates.related(inci)"
            class="me-2" [routerLink]="['/templates', t.key]" class="nav-link">{{t.name}}<sub>({{t.language}})</sub></a>
      </div>
    </div>
  </div>
</div>
