import { Customer } from './customer.model';
import { Template } from './template.model';
import { User } from './user.model';
import { Result } from './result.model';
import { ReportUser } from './report_user.model';
import { Textblock } from './textblock.model';
import { ReportIngredient } from './report_ingredient.model';
import { BaseEntity } from './base_entity';
import { IngredientInci } from './ingredient_inci.model';
import { Ingredient } from './ingredient.model';

export enum ReportSection {
  Basics = 1,
  Users = 2,
  Ingredients = 3,
  Properties = 4,
  Content = 5,
  DOCX = 6
}

export class Report extends BaseEntity {

  constructor(source?: Report) {
    super(source);
    this.data = this.data || {};
    this.data.ctx = this.data.ctx || {};
    this.data.structure = this.data.structure || {};
    this.coAuthors = this.coAuthors || [];
    this.ingredients = this.ingredients || [];
  }

  studyNumber: string;
  title: string;
  language: string;
  productName: string;
  recipeNumber: string;
  cpnp: string;
  prodtype: string;
  application: string;
  filename: string;
  published: Date;

  customer: Customer;
  customerId: number;
  manufacturer: Customer;
  manufacturerId: number;
  bottler: Customer;
  bottlerId: number;
  distributor: Customer;
  distributorId: number;
  template: Template;
  templateId: number;

  users: ReportUser[];
  author: User;
  coAuthors: User[];

  data: any;
  result: Result;

  textblocks: Textblock[];
  contentTree: Textblock;

  ingredients: ReportIngredient[];

  getAccumulatedIncis(): IngredientInci[] {
    const all = this.ingredients.reduce<IngredientInci[]>((incis, ingred) => {
      return incis.concat(ingred.ref.incis.map(inci => {
        const i = new IngredientInci(inci);
        i.aggregated = true;
        i.percentMin = inci.percentMinTrim() * ingred.percentMinTrim() / 100.0;
        i.percentMax = inci.percentMaxTrim() * ingred.percentMaxTrim() / 100.0;
        return i;
      }));
    }, []);

    const allIncis = all
      .map(i => i.ref)
      .filter((value, index, array) => array.indexOf(value) === index);

    const result = allIncis
      .reduce<IngredientInci[][]>((collect, inci) => {
        const iiByRef = all.filter(i => i.ref === inci);
        collect.push(iiByRef);
        return collect;
      }, [])
      .map(array => {
        const sum = new IngredientInci();
        sum.aggregated = true;
        sum.ref = array[0].ref;
        sum.percentMin = 0;
        sum.percentMax = 0;
        array.forEach(ii => {
            sum.percentMin += ii.percentMinTrim();
            sum.percentMax += ii.percentMaxTrim();
          });
        return sum;
      });
      console.log('Report.getAccumulatedIncis()', 'Result: ', result);
      return result;
  }

  isSectionValid(section: ReportSection): boolean {
    switch (section) {
      case ReportSection.Basics:
        return (this.customer && this.title  && this.productName && this.recipeNumber && this.template && this.language && this.application) ? true : false;
      case ReportSection.Users:
        return this.author && this.coAuthors != null && this.coAuthors.length >= 2;
      case ReportSection.Ingredients:
        return this.ingredients.length && this.ingredients.reduce<boolean>((flag, ingredient) => {
          return ingredient.ingredientId != null;
        }, true);
      case ReportSection.Properties:
        return this.data != null && Object.keys(this.data).reduce((flag, key) => {
          if (!flag) { return false; }
          const val = this.data[key];
          if (val == null || '' + val === '') { return false; }
          const prop = this.template && this.template.properties && this.template.properties.docx 
              ? this.template.properties.docx.find(p => p.name === key) : null;
          if (prop != null && prop.type === 'number' && prop.min != null) {
            return Number(prop.min) <= Number(val);
          }
          return true;
        }, true);
      case ReportSection.Content:
        return this.hasContent() && this.textblocks.reduce<boolean>((flag, block) => {
          return flag && block.properties.frozen;
        }, true);
      case ReportSection.DOCX:
        return this.published != null;
    }
    return false;
  }

  isSectionsValid(sections: ReportSection[]): boolean {
    return sections
      .map(s => this.isSectionValid(s))
      .reduce((result, flag) => result && flag, true);
  }

  isValid(): boolean {
    return this.isSectionValid(ReportSection.Basics)
        && this.isSectionValid(ReportSection.Users)
        && this.isSectionValid(ReportSection.Properties)
        && this.isSectionValid(ReportSection.Content);
  }

  hasContent(): boolean {
    return this.textblocks && this.textblocks.length > 0;
  }

  ingredientsPercentMinTrim(): number {
    return (this.ingredients || [])
      .reduce(
        (result, item) => result + item.percentMinTrim(), 0);
  }

  ingredientsPercentMaxTrim(): number {
    return (this.ingredients || [])
      .reduce(
        (result, item) => result + item.percentMaxTrim(), 0);
  }

}
