import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BaseMainpage, BaseEditpage, ConfirmComponent, ComponentDeactivate } from '../../common';
import { DataService, SpinnerService, NotifyService } from '../../common';
import { Customer } from '../../common/model';
import { CustomerService } from '../../common/services';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html'
})
export class CustomerEditComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @Input() customer: Customer;

  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  private editpage: BaseEditpage<Customer> = null;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private customerService: CustomerService) {
    super(spinner, notify, router, data);
    console.log('CustomerEditComponent.create()');
  }

  ngOnInit() {
    console.log('CustomerEditComponent.init()');
    this.editpage = new BaseEditpage(this, this.form, this.confirm, this.customerService, this.customer);
    this.editpage._postCreate = (c: Customer) => {
      this.router.navigate(['/customers', c.key]);
    };
  }

  canDeactivate() {
    return this.editpage.canDeactivate();
  }

  save(): Promise<void> {
    return this.editpage.save();
  }
}
