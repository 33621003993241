<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <form #f="ngForm" (submit)="save()">
    <br>
    <div class="row">
      <div class="col-11">
        <h3>{{template.name ? template.name : 'New Template'}}</h3>
      </div>
      <div *ngIf="template.id" class="col-1">
        <div class="form-group" ngbTooltip="Close Edit" tooltipClass="dt-tooltip">
          <a [routerLink]="['/templates', template.key]" class="form-control btn"><span class="float-end oi oi-caret-top"></span></a>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-3 col-lg-2"><label for="name">Name*</label></div>
      <div class="col-9 col-lg-6">
        <input id="name" name="name" type="text" [(ngModel)]="template.name" #name="ngModel" required appTrim  class="form-control">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">Name is required</div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-3 col-lg-2"><label for="language">Language*</label></div>
      <div class="col-9 col-lg-6">
        <select id="language" name="language" [(ngModel)]="template.language" required class="form-select" (change)="validateTemplate()">
          <option *ngFor="let l of languages" value="{{l.key}}">{{l.label}}</option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-4 col-lg-3 form-goup form-check form-check-aligned">
        <input class="form-check-input" id="root" name="root" type="checkbox" [(ngModel)]="template.root" (change)="validateTemplate()">
        <label class="form-check-label" for="root">Root</label>
      </div>
      <div class="col-4 col-lg-3 form-goup form-check form-check-aligned">
        <input class="form-check-input" id="newPage" name="newPage" type="checkbox" [(ngModel)]="template.properties.newPage">
        <label class="form-check-label" for="newPage">New Page</label>
      </div>
      <div class="col-4 col-lg-3 form-goup form-check form-check-aligned">
        <input class="form-check-input" id="autoFreeze" name="autoFreeze" type="checkbox" [(ngModel)]="template.properties.autoFreeze">
        <label class="form-check-label" for="autoFreeze">AutoFreeze</label>
      </div>
    </div>
    <div class="row form-group" *ngIf="template.root">
      <div class="col-3 col-lg-2"><label for="document">Document</label></div>
      <div class="col-9 col-lg-6">
        <select id="document" name="document" [(ngModel)]="template.documentKey" class="form-select" >
          <option value="">-- None --</option>
          <option *ngFor="let d of data.docxs.list" value="{{d.key}}">{{d.name}}</option>
        </select>
      </div>
    </div>
    <div class="row form-group" *ngIf="!template.root">
      <div class="col-3 col-lg-2"><label for="template">Root-Template</label></div>
      <div class="col-9 col-lg-6">
        <select id="template" name="template" [(ngModel)]="template.templateKey" class="form-select" [appTemplateLang]="template" [appDataService]="data">
          <option value="">-- None --</option>
          <option *ngFor="let t of data.templates.roots(template.language)" value="{{t.key}}">{{t.name}}</option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-3 col-lg-2"><label for="label">Label</label></div>
      <div class="col-9 col-lg-6">
        <input id="label" name="label" type="text" [(ngModel)]="template.label" appTrim class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-3 col-lg-2"><label for="descr">Description</label></div>
      <div class="col-9 col-lg-6">
        <input id="descr" name="descr" type="text" [(ngModel)]="template.description" appTrim class="form-control">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-3 col-lg-2"><label for="inci">INCI Specific</label></div>
      <div class="col-9 col-lg-6">
        <input id="inci" name="inci" type="text" [(ngModel)]="template.inciName" [ngbTypeahead]="searchInci" appToUpperCase appTrim class="form-control">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-3 col-lg-2"><label for="customer">Customer Specific</label></div>
      <div class="col-9 col-lg-6">
        <select id="customer" name="customer" [(ngModel)]="template.customer" class="form-select" (change)="customerChanged()">
          <option [ngValue]="null">-- None --</option>
          <option [ngValue]="cust" *ngFor="let cust of data.customers.list">{{cust.name}}</option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-3 col-lg-2"><label for="ingredient">Raw-Material Specific</label></div>
      <div class="col-9 col-lg-6">
        <select id="ingredient" name="ingredient" [(ngModel)]="template.ingredient" class="form-select" (change)="ingredientChanged()">
          <option [ngValue]="null">-- None --</option>
          <option [ngValue]="ingr" *ngFor="let ingr of data.ingredients.list">{{ingr.getTypeaheadCaption()}}</option>
        </select>
      </div>
    </div>
    <div class="row form-group" *ngIf="template.root">
      <div class="col-md-12"><label for="props">Properties</label></div>
      <div class="col-md-12">
        <textarea id="props" name="props" [(ngModel)]="template.properties_s" (change)="onPropsChange('props')" class="form-control required"></textarea>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-md-12 col-form-label">Content:</label>
      <div class="col-md-12">
        <ace id="dataText" name="dataText" #editor
          [config]="editorOptions"
          [mode]="'html'"
          [theme]="'textmate'"
          [(value)]="template.dataText"
          (valueChange)="onEditorContentChange()"
          class="dt-form-control"></ace>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-md-12 col-form-label">Preview:</label>
      <div class="col-md-12"><div class="dt-form-control">
        <ckeditor name="ckeditor" [editor]="previewFactory" #preview
          [config]="previewConfig"
          [ngModel]="template.dataText"
          [disabled]="true"></ckeditor>
      </div></div>
    </div>
    <div class="row">
        <div class="col-lg-8 col-md-12 col-sm-12 form-group">
            <button class="btn btn-primary form-control">{{template.id ? 'Update' : 'Create'}}</button>
        </div>
    </div>

    <!-- Related Items: -->
    <div class="row">
      <div class="col-12 col-md-6 form-group" *ngIf="data.templates.related(template, 'includedBy').length">
        <label>Included By:</label>
        <div>
          <a *ngFor="let t of data.templates.related(template, 'includedBy')"
              class="me-2 nav-link" [routerLink]="['/templates', t.key]">{{t.name}}<sub>{{t.relationIndicator(template.language)}}</sub></a>
        </div>
      </div>
      <div class="col-12 col-md-6 form-group" *ngIf="data.templates.related(template).length">
        <label>Similar Templates:</label>
        <div>
          <a *ngFor="let t of data.templates.related(template)"
              class="me-2 nav-link" [routerLink]="['/templates', t.key]">{{t.name}}<sub>{{t.relationIndicator(template.language)}}</sub></a>
        </div>
      </div>
    </div>
  </form>
</div>
