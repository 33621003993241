import { BaseEntity } from './model/base_entity';

class SearchToken {

    constructor(
        public term: string,
        public flags: string[] = []) {}

    static parse(searchTerm: string): SearchToken[] {
        return [new SearchToken(searchTerm)];
    }
}

export class Search<T extends BaseEntity> {

    tokens: SearchToken[] = [];

    constructor(private searchTerm: string) {
        this.tokens = SearchToken.parse(searchTerm);
    }

    public run(list: T[], _preSearch?: (t: T) => T): T[] {
        return list.filter(
            o => JSON.stringify(_preSearch ? _preSearch(o) : o).toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase()) > -1);
    }
}
