import { IngredientInci } from './ingredient_inci.model';
import { BaseEntity } from './base_entity';
import { IngredientSpecification } from './ingredient_specification.model';

export class Ingredient extends BaseEntity {

    constructor(source?: Ingredient) {
        super(source);
        this.incis = this.incis || []
        this.specifications = this.specifications || { list: [] };
    }

    tradename: string;  // NOT NULL
    name: string;
    language: string;
    manufacturer: string;
    literatureRef: string;

    incis: IngredientInci[];
    specifications: {
        list: IngredientSpecification[]
    };

    // perfumme related fields
    perfume: boolean;
	codeNo: string;
	allergenList: boolean;
	ifraCert: boolean;
	ifraCertRevision: string
	perfumeSecurityReport: boolean;

    getSpecificationValue(key: number) {
        const s = this.specifications.list.find(s => s.ref.key == key);
        return s ? s.value : null;
    }

    getName() {
        return this.name || this.tradename;
    }

    getLabel() {
        return (this.name && this.name != this.tradename ? this.name + ' / ' : '')
            + this.tradename
            + (this.manufacturer ? ' (' + this.manufacturer + ')' : '')
    }

    getTypeaheadCaption(): string {
        return this.getLabel();
    }

    isIdentifiedBy(caption: string): boolean {
        if (!caption) return false;
        if (caption.toLowerCase() == this.getTypeaheadCaption().toLowerCase()) return true;
        if (caption.indexOf('(') == -1) return caption.toLowerCase() === this.getName().toLowerCase();
    }
}
