<div class="container-fluid">
  <app-confirm #confirm></app-confirm>
  <form #f="ngForm" (submit)="save()">
    <br>
    <div class="row">
      <div class="col-11">
        <h3>{{customer.name ? customer.name : 'New Customer'}}</h3>
      </div>
      <div *ngIf="customer.id" class="col-1">
        <div class="form-group" ngbTooltip="Close Edit" tooltipClass="dt-tooltip">
          <a [routerLink]="['/customers', customer.key]" class="form-control btn"><span class="float-end oi oi-caret-top"></span></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 form-group"><label for="name">Name*</label>
        <input id="name" name="name" type="text" [(ngModel)]="customer.name" #name="ngModel" required appTrim class="form-control">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">Name is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 form-group"><label for="address1">Address*</label>
        <input id="address1" name="address1" type="text" [(ngModel)]="customer.address.address1" #address1="ngModel" required appTrim class="form-control">
        <div [hidden]="address1.valid || address1.pristine" class="alert alert-danger">First Address field is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 form-group">
        <input id="address2" name="address2" type="text" [(ngModel)]="customer.address.address2" appTrim class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-2 col-lg-2  form-group">
        <label for="postcode">Postcode*</label>
        <input id="postcode" name="postcode" type="text" [(ngModel)]="customer.address.postcode" #postcode="ngModel" required appTrim class="form-control">
        <div [hidden]="postcode.valid || postcode.pristine" class="alert alert-danger">Postcode is required</div>
      </div>
      <div class="col-12 col-md-10 col-lg-6 form-group">
        <label for="city">City*</label>
        <input id="city" name="city" type="text" [(ngModel)]="customer.address.city" #city="ngModel" required appTrim class="form-control">
        <div [hidden]="city.valid || city.pristine" class="alert alert-danger">City is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 form-group"><label for="givenName">Region</label>
        <input id="region" name="region" type="text" [(ngModel)]="customer.address.region" appTrim class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 form-group">
        <label for="country">Country*</label>
        <input id="country" name="country" type="text" [(ngModel)]="customer.address.country" #country="ngModel" required appTrim class="form-control">
        <div [hidden]="country.valid || country.pristine" class="alert alert-danger">Country is required</div>
      </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-8 form-group">
            <button class="btn btn-primary form-control">{{customer.id ? 'Update' : 'Create'}}</button>
        </div>
    </div>
  </form>
</div>
