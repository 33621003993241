import { BaseEntity } from './base_entity';

export class Address extends BaseEntity {

  constructor(source?: Address) {
    super(source);
  }

  name: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  region: string;
  country: string;
}
