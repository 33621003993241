<div *ngIf="message" 
    class="alert alert-dismissible fade show"
    [ngClass]="{ 
        'alert-success': message.type === 'success', 
        'alert-info': message.type === 'info', 
        'alert-warning': message.type === 'warning', 
        'alert-danger': message.type === 'error'
    }">
    {{message.text}}
    <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&nbsp;</span>
      </button>
</div>