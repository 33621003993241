import { Component, OnInit, Input, ViewChild, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { BaseMainpage, Constants, ConfirmComponent, ComponentDeactivate, BaseEditpage, HtmlUtil } from '../../common';
import { DataService, SpinnerService, NotifyService, } from '../../common';
import { Inci, InciFunction, InciRoute, InciRouteCorrection, ValueSkeleton } from '../../common/model';
import { InciService, FunctionService, ValueService } from '../../common/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-inci-edit',
  templateUrl: './inci-edit.component.html'
})
export class InciEditComponent extends BaseMainpage implements OnInit, ComponentDeactivate {

  @Input() inci: Inci;
  @Input() language = 'de';

  @ViewChild('f', { static: true }) form: NgForm;
  @ViewChild('confirm', { static: true }) confirm: ConfirmComponent;

  public functionName: string;
  public functionDefault: boolean = false;
  public eukvAppendix: number = 2;
  public eukvNumber: string = '';
  public languages = Constants.LANG;
  public eukvLabels = Constants.EUKV_APPENDIX;
  public POD = Constants.POD;
  public defaultRoute: string = null;
  public correctionFormatter = (x: InciRouteCorrection) => x.label;

  private editpage: BaseEditpage<Inci> = null;

  constructor(
      spinner: SpinnerService,
      notify: NotifyService,
      router: Router,
      data: DataService,
      private inciService: InciService,
      private functionService: FunctionService,
      @Optional() public activeModal: NgbActiveModal) {
    super(spinner, notify, router, data);
    console.log('InciEditComponent.create()');
  }

  ngOnInit() {
    console.log('InciEditComponent.init()');
    this.defaultRoute = (this.inci.routes.find(n => n.default) || { route: null }).route
    this.editpage = new BaseEditpage(this, this.form, this.confirm, this.inciService, this.inci);
    this.editpage._preSave = () => {
      // refresh default flags
      this.inci.routes.forEach(r => {
        r.default = this.defaultRoute == r.route;
      })
    };
    this.editpage._postCreate = (i: Inci) => {
      if (this.router.url.indexOf('/incis') === 0) {
        this.router.navigate(['/incis', i.key]);
      } else {
        this.notify_ok();
      }
    };
    this.editpage._postSave = () => {
      if (this.activeModal) {
        this.activeModal.close('save');
      }
    };
  }

  canDeactivate() {
    return this.editpage.canDeactivate();
  }

  changed() {
    this.form.form.markAsDirty();
    this.form.form.markAsTouched();
  }

  save(): Promise<void> {
    return this.editpage.save();
  }

  addEukvAppendix() {
    if (this.eukvAppendix && this.eukvNumber) {
      this.inci.eukv.list.push(
        { appendix: this.eukvAppendix, number: this.eukvNumber }
      );
      this.eukvNumber = '';
      this.changed();
    }
  }

  removeEukvAppendix(a) {
    this.inci.eukv.list = this.inci.eukv.list.filter(item => item !== a);
    this.changed();
  }
  
  routeInfos(): { label: string, value: string}[] {
    return this.POD.ROUTES.all();
  }
  routeSelectionChanged(route: InciRoute) {
    route.absorption = this.POD.ROUTES.resolve(route.route).defaultAbsorption;
    this.changed();
  }

  addRoute() {
    this.inci.routes.push(new InciRoute())
    this.changed();
  }

  removeRoute(index: number) {
    this.inci.routes.splice(index, 1);
    this.changed();
  }

  cloneRoute(index: number) {
    const orig = this.inci.routes.length >= index ? this.inci.routes[index] : null;
    if (orig) {
      const r = new InciRoute(JSON.parse(JSON.stringify(orig))) // force deep copy
      r.route = null;
      r.default = false;
      this.inci.routes.push(r);
    }
  }

  routeValueTypes: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.POD.TYPES
          .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10))
    );
  }

  searchCorrections: OperatorFunction<string, readonly object[]> = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.data.values.inciRouteCorrections()
          .filter(c => c.label.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10))
    );
  }

  addCorrection(route: InciRoute) {
    console.log('addCorrection()', route);
    if (route._correctionText && route._correctionText.trim()) {
      const c = new ValueSkeleton();
      c.label = route._correctionText.trim();
      c.properties.text = c.label;
      c.properties.factor = 1.0;
      route.corrections.list.push(c);
      route._correctionText = '';
      this.changed();
    }
  }

  selectCorrection(route: InciRoute, event) {
    console.log('selectCorrection()', route, event);
    setTimeout(() => {
      const c: InciRouteCorrection = event.item;
      route.corrections.list.push(c.stripEntityProps());
      route._correctionText = '';

      route.factor = route.factor * c.properties.factor;
      this.changed();
    }, 5);
  }

  removeCorrection(n: InciRoute, correction: InciRouteCorrection) {
    n.corrections.list = n.corrections.list.filter(c => c != correction);
    n.factor = n.factor / correction.properties.factor;
    this.changed();
  }

  searchFunction: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const names = () => this.data.functions.names.all || [];
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : names()
          .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .sort((a, b) => a.length - b.length)
          .slice(0, 10))
    );
  }

  addFunction() {
    if (this.functionName) {
      this.functionService.getByName(this.functionName)
        .then(fn => {
          if (fn) {
            const inciFn = new InciFunction();
            inciFn.ref = fn;
            inciFn.functionKey = fn.key;
            if (this.functionDefault) {
              inciFn.default = true;
              this.inci.functions.forEach(fn => {
                fn.default = false;
              });
            }
            this.inci.functions.push(inciFn);
            this.functionName = null;
            this.functionDefault = false;
            this.changed();
          }
        });
    }
  }

  removeFunction(fn: InciFunction) {
    console.log('InciEditComponent.removeFunction()', fn);
    this.inci.functions = this.inci.functions.filter(
      f => f !== fn
    );
    this.changed();
  }

}
