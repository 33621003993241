import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../model';
import { DataService } from '../data.service';
import { EntityStore } from './entity_store';

@Injectable()
export class UserService extends EntityStore<User> {

    constructor(http: HttpClient, dataService: DataService) {
        super(http, dataService.users, '/api/users', User);
    }

}
