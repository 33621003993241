import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseMainpage, DataService, AlertService, SpinnerService, NotifyService } from '../common';
import { AuthenticationService } from '../common/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends BaseMainpage implements OnInit {

  model: {
    username: string;
    password: string
  };
  returnUrl: string;

  constructor(
    spinner: SpinnerService,
    notify: NotifyService,
    router: Router,
    data: DataService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) {
      super(spinner, notify, router, data);
      console.log('LoginComponent.create()');
      this.model = {
        username: '',
        password: ''
      };
    }

  ngOnInit() {
    console.log('LoginComponent.init()');
    // reset login status
    this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.stopSpinner();
  }

   login() {
    console.log('LoginComponent.login()', 'Try login for user: ' + this.model.username);
    this.startSpinner();
    this.authenticationService.login(this.model.username, this.model.password)
      .then(
        user => {
          this.router.navigate([this.returnUrl]);
        })
      .catch(err => {
        console.log('LoginComponent.login()', 'Login Failed: ', err);
      })
      .finally(() => {
        this.stopSpinner();
      });
    }
}
