import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appToUpperCase]'
})
export class ToUpperCaseDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input') onInput() {
    // console.log('ToUpperCaseDirective.onInput()');
    this.toUpperCase();
  }

  private toUpperCase() {
    const text = this.el.nativeElement.value;
    if (text && typeof text === 'string') {
      const textUpdate = text.toUpperCase();
      if (text !== textUpdate) {
        this.el.nativeElement.value = textUpdate;
      }
    }
  }
}
